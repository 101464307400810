//-------------------------------
// Menu Principal
//-------------------------------
.pa-menu {
  background-color: $c-white;
  box-shadow: 4px 0 10px 0 rgba($c-black, 0.2);
  display: none;
  height: 100vh;
  left: -330px;
  max-width: 320px;
  overflow: hidden;
  padding: (4 * $grid-gutter) 0 0;
  position: fixed;
  top: 0;
  transition: .3s cubic-bezier(0.77, 0.2, 0.05, 1), display 0s 3s;
  // visibility: hidden;
  width: 90vw;
  z-index: z("modal");

  &__wrap {
    height: calc(100% - 58px);
    position: relative;
  }

  @include media(">=tablet") {
    left: -350px;
    max-width: 340px;
  }

  @include media(">=wide") {
    left: -27vw;
    max-width: 26vw;
  }

  &::before {
    background-color: $c-primary;
    content: "";
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transform: translateX(-5px);
    transition: .2s linear;
    width: 5px;
    z-index: z("overlay");
  }

  &__back {
    align-items: center;
    background: $c-gray-lighter;
    color: $c-gray-darker;
    display: flex;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("medium");
    padding: 20px 28px;
    transition: background $transition-time;
    width: 100%;

    &:hover,
    &:focus {
      background: $c-gray-light;
      transition: background $transition-time;
    }

    i {
      margin-right: $grid-gutter;
    }
  }

  &__close {
    height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;

    span {
      display: block;
      height: 100%;
      position: relative;

      &::before,
      &::after {
        background: $c-gray;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        top: 18px;
        transition: background-color .15s;
        width: 100%;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    @include media(">=desktop") {
      &:hover span::before,
      &:hover span::after,
      &:focus span::before,
      &:focus span::after {
        background: $c-primary;
      }
    }
  }

  &__item {
    border-top: 1px solid $c-gray-light;
    display: block;
    width: 100%;

    &:last-child {
      border-bottom: 1px solid $c-gray-light;
    }

    @include media(">=desktop") {
      &:hover > .pa-menu__link,
      & > .pa-menu__link:focus {
        background-color: $c-gray-lighter;
      }
    }

    &--open {
      height: auto;
    }

    &--has-child {
      > :not(.pa-menu__link) > .pa-menu__link,
      > .pa-menu__link {
        &::after {
          color: $c-gray-darker;
          // icon-seta-direita-b
          content: "\e921";
          display: inline-block;
          font-family: "bradesco_v2";
          font-size: rem(12);
          right: 20px;
          line-height: 1.2;
          position: absolute;
        }
      }
    }

    &--gray {
      > .pa-menu__link {
        background-color: $c-gray-lighter;
      }

      @include media(">=desktop") {
        &:hover > .pa-menu__link,
        & > .pa-menu__link:focus {
          background-color: $c-gray-light;
        }
      }
    }

    &--button {
      > .pa-menu__link {
        background: linear-gradient(
          to bottom,
          lighten($c-primary, 5%) 0%,
          $c-primary 100%
        );
        color: $c-white;
        font-weight: fw("medium");

        i {
          color: $c-white;
        }
      }

      @include media(">=desktop") {
        &:hover > .pa-menu__link,
        & > .pa-menu__link:focus {
          background: linear-gradient(
            to bottom,
            darken($c-primary, 8%) 0%,
            darken($c-primary, 8%) 100%
          );
        }
      }
    }

    &--mobile {
      @include media(">=desktop") {
        display: none;
      }
    }
  }

  &__link {
    align-items: center;
    color: $c-gray-darker;
    display: flex;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("regular");
    line-height: 1.3;
    padding: 18px 25px;
    position: relative;
    width: 100%;

    i {
      color: $c-primary;
      font-size: rem(24);
      margin-right: $grid-gutter;
    }
  }

  &__list {
    height: 100%;
    left: 0;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

  }

  &__sublist {
    background-color: $c-white;
    @include scrollbar;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;

    .pa-menu__link {
      color: $c-primary;
    }

    &--open {
      @include scrollbar;
      overflow-y: auto;
      visibility: visible;
      z-index: 200;

      > li {
        animation: inFromRight 0.5s both cubic-bezier(0.7, 0, 0.3, 1);
        will-change: transform, opacity;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            animation-delay: .06s * $i;
          }
        }
      }
    }

    &--gray {
      background: $c-gray-lighter;
    }
  }

  &__title {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(20);
    padding: $grid-gutter $grid-gutter 20px 25px;
  }

  &__group {}

  &__group-title {
    align-items: center;
    background-color: $c-gray;
    color: $c-gray-darker;
    display: flex;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("medium");
    line-height: 1.3;
    padding: 18px 25px;
    position: relative;
    width: 100%;
  }

  &__accessibility {
    flex-wrap: wrap;
    float: right;
    justify-content: center;
    margin: (5 * $grid-gutter) 0 0;
    position: relative;
    width: calc(100% - 5px);

    i {
      font-size: rem(32);
      margin: 0;
    }

    &-list {
      display: flex;
      justify-content: space-around;
      margin: 0;
    }

    &-title {
      color: $c-gray-darker;
      display: block;
      font-family: $font-title;
      font-size: rem(16);
      font-weight: fw("regular");
      text-align: center;
      width: 100%;
    }

    .pa-menu__link {
      padding: $grid-gutter;
    }

    .pa-accessibility-menu__item {
      border-right: 1px solid $c-gray-light;
      flex: 1;
      width: 25%;

      &:last-child {
        border: 0;
      }
    }
  }

  &__search {
    padding: 15px;
  }

  // menu open
  &--open {
    // display: block;
    left: 0;
    transition: .3s cubic-bezier(0.77, 0.2, 0.05, 1) .2s, display 0s .3s;
    visibility: visible;
    z-index: z("modal");

    &::before {
      transform: translateX(0);
    }
  }

  &--right {
    left: auto;
    right: -330px;

    @include media(">=tablet") {
      right: -350px;
    }

    @include media(">=wide") {
      right: -27vw;
    }

    &::before {
      position: absolute;
    }

    &.pa-menu--open {
      right: 0;
    }

    .pa-menu__close {
      left: 20px;
      right: auto;
    }
  }
}

@keyframes inFromRight {
  from {
    display: none;
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    display: block;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
