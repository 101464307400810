//-------------------------------
// Tabela
// Componente de tabela
//-------------------------------
.bs-table {
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba($c-black, 0.2),
  inset 0 59px 0 0 rgba($c-black, 0.004),
  inset 0 60px 0 0 rgba($c-black, 0.004);
  display: block;
  margin-top: 30px;
  margin-bottom: (2 * $grid-gutter);
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar-track {
    background-color: $c-gray-light;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c-gray-dark;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  table {
    background-color: $c-gray-lightest;
    border-radius: 5px;
    border-spacing: 0;
    max-width: 100%;
    min-width: 400px;
    table-layout: auto;
    text-align: left;
    width: 100%;
  }

  td,
  &__data {
    background-color: $c-white;
    border-bottom: 1px solid $c-gray-light;
    border-right: 1px solid $c-gray-light;
    font-size: rem(14);
    letter-spacing: ls(-10, 16);
    // max-width: (16 * $grid-gutter);
    // min-width: (8 * $grid-gutter);
    padding: $grid-gutter;

    @include media(">=tablet") {
      font-size: rem(16);
      // max-width: (20 * $grid-gutter);
      // min-width: (10 * $grid-gutter);
      padding: $grid-gutter 20px;
    }

    &:first-child {
      border-left: 1px solid $c-gray-light;
      // max-width: (18 * $grid-gutter);
      // min-width: (10 * $grid-gutter);

      @include media(">=tablet") {
        // max-width: (24 * $grid-gutter);
        // min-width: (18 * $grid-gutter);
      }
    }

    &--large {
      // max-width: (22 * $grid-gutter);
      // min-width: (14 * $grid-gutter);

      @include media(">=tablet") {
        // max-width: (30 * $grid-gutter);
        // min-width: (26 * $grid-gutter);
      }
    }

    // Colors
    &--green {
      color: $c-green;
    }

    &--primary {
      color: $c-primary;
    }

    // Font Weight
    &--bold {
      font-weight: fw("bold");
    }

    &--light {
      font-weight: fw("light");
    }

    // Font Family
    &--new-june {
      font-family: $font-title;
    }
  }

  // tr
  tr,
  &__row {
    &:first-child {
      td {
        border-top: 1px solid $c-gray-light;
      }

      th {
        border-bottom: 0;
      }
    }

    &:last-child {
      td {
        border-bottom: 0;

        &:first-child {
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  &__row-selectable {
    cursor: pointer;

    &:hover {
      td {
        background-color: $c-gray-lighter;
      }
    }
  }

  // th
  th,
  &__header {
    background-color: $c-gray-lightest;
    border-right: 1px solid $c-gray-light;
    border-top: 1px solid $c-gray-light;
    color: $c-gray-darker;
    font-size: rem(16);
    padding: $grid-gutter;
    position: relative;

    &:first-child {
      border-left: 1px solid $c-gray-light;
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  // Ícones exibidos no conteúdo da tabela
  &__icon {
    display: block;
    font-size: rem(32);
    margin: 5px 0;
  }

  // Primeira coluna fixa ao dar scroll horizontal
  &--column-fixed {
    .bs-table__row {
      th:first-child,
      td:first-child {
        left: 0;
        position: sticky;
        text-align: left;
        z-index: z("default");

        &::after {
          background: linear-gradient(to right, rgba($c-black, .08) 0%, rgba($c-black, 0) 100%);
          content: "";
          display: block;
          height: 100%;
          position: absolute;
          right: -10px;
          top: 0;
          width: 9px;
        }
      }
    }
  }

  // Texto alinhado ao centro
  &--text-center {
    th,
    td {
      text-align: center;
    }
  }

  // Tabela de resultado de sorteio
  &--collapsible {
    .bs-table__row {
      vertical-align: top;

      // tr recolhida
      &--collapsed {
        .bs-table__data:not(:first-child) {
          .bs-table__data-item:not(:first-child) {
            display: none;
          }
        }

        .bs-table__data {
          &:first-child {
            .bs-table__data-item {
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .bs-table__data-item {
          margin-bottom: 0;
        }

        .bs-table__trigger-row .icon {
          display: block;
          transform: rotate(180deg);
        }
      }
    }

    .bs-table__header {
      &:not([data-sort-method="none"]) {
        cursor: pointer;

        &::after {
          content: "\e91b";
          display: block;
          font-family: $font-icon;
          font-size: rem(14);
          margin-left: 5px;
          transform: rotate(180deg);
        }
      }

      &[aria-sort="ascending"] {
        &::after {
          transform: rotate(0);
        }
      }

      &:last-child,
      &:nth-child(2) {
        text-align: center;
      }
    }

    .bs-table__trigger-row {
      color: $c-gray-dark;
      text-align: center;
      width: 100%;
    }

    .bs-table__data {
      &:nth-child(2) {
        text-align: center;
        width: 140px;
      }

      &:last-child {
        color: $c-gray-dark;
        font-weight: bold;
        text-align: center;
        width: 140px;
      }
    }

    .bs-table__data-item {
      display: block;
      margin-bottom: $grid-gutter;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.bs-has-filter {
      tbody .bs-table__row:not(.bs-table__row--active) {
        display: none;
      }
    }
  }
}
