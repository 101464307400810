//-------------------------------
// Mural
//-------------------------------
.bs-mural {

  // Seção do componente
  &__section {
    padding: 60px 0;
  }

  // Lista que agrupa os itens
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$grid-gutter / 3);
  }

  // Grupo de itens (default: 5 itens)
  &__group {
    @include flex-basis(100%);
    display: flex;
    flex-wrap: wrap;

    @include media(">=tablet") {

      // Grupo 1 (com dois itens)
      &:nth-child(1) {
        @include flex-basis(100%);

        // Item 1
        .bs-mural__item:nth-child(1) {
          @include flex-basis(66.666%);
        }

        // Item 2
        .bs-mural__item:nth-child(2) {
          @include flex-basis(33.333%);
        }
      }

      // Grupo 2 (com um itens)
      &:nth-child(2) {

        // Item 3
        @include flex-basis(33.333%);
      }

      // Grupo 3 (com dois itens)
      &:nth-child(3) {
        @include flex-basis(66.666%);

        // Itens 4 e 5
        .bs-mural__item:nth-child(1),
        .bs-mural__item:nth-child(2) {
          @include flex-basis(50%);
        }
      }
    }

    @include media(">=desktop") {
      // Grupo 1
      &:nth-child(1) {
        @include flex-basis(50%);

        // Itens 1 e 2
        .bs-mural__item:nth-child(1),
        .bs-mural__item:nth-child(2) {
          @include flex-basis(100%);
        }

        // Item 1
        .bs-mural__item:nth-child(1) {

          .bs-mural__image {
            height: 320px;
          }
        }

        // Item 2
        .bs-mural__item:nth-child(2) {

          .bs-mural__image {
            height: 320px;
          }
        }
      }

      // Grupos 2 e 3
      &:nth-child(2),
      &:nth-child(3) {
        @include flex-basis(25%);

        // Itens 3, 4 e 5
        .bs-mural__item:nth-child(1),
        .bs-mural__item:nth-child(2) {
          @include flex-basis(100%);
        }
      }

      // Grupos 2
      &:nth-child(2) {

        .bs-mural__image {
          height: 650px;
        }
      }
    }
  }

  // Cada item do mural (default: tag <a>)
  &__item {
    @include flex-basis(100%);
    display: block;
    padding: $grid-gutter / 3;
    position: relative;
  }

  &__hover-description {

    .bs-mural__description {
      overflow: hidden;
      transition: 0.5s;
      max-height: 0;
      opacity: 0;
    }

    &:hover {

      .bs-mural__description {
        max-height: 72px;
        opacity: 1;
      }
    }
  }

  // Box interna do item
  &__box {
    display: block;
    height: 100%;
    width: 100%;

    &:focus,
    &:hover {
      outline-offset: 5px;

      img {
        transform: scale(1.1);
        transition: transform $transition-long-time;
      }
    }

    // &:focus {
    //   display: block;
    //   height: 100%;
    //   outline-color: $c-gray-darker;
    //   outline-offset: 3px;
    //   outline-style: dotted;
    //   outline-width: 2px;
    // }
  }

  // A imagem de fundo de cada item
  &__image {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include media(">=tablet") {
      // height: 320px;
    }

    &::before {
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 0.4) 40%,
        rgba(0, 0, 0, 0.2) 60%,
        transparent);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    img {
      display: block;
      font-family: 'object-fit: cover; object-position: 50% 50%;';
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: 50% 50%;
      transform: scale(1);
      transition: transform $transition-long-time;
      width: 100%;
    }
  }

  // O texto de cada item
  &__text {
    bottom: 0;
    color: $c-white;
    display: block;
    left: 0;
    max-height: 100%;
    padding: ($grid-gutter * 2) 20px;
    position: absolute;
    width: 100%;
    z-index: z("absolute");
  }

  // O título de cada item
  &__title {
    font-family: $font-title;
    font-size: 20px;
    font-weight: fw("bold");
    line-height: 1.3;
    margin-bottom: 10px;
    word-break: break-word;
  }

  // A descrição de cada item
  &__description {
    font-size: 16px;
    font-weight: fw("light");
    line-height: 1.5;
    word-break: break-word;
  }


  // Options de filtros
  &__options {
    .bs-switch__label {
      padding-bottom: $grid-gutter * 2;

      @include media(">=tablet") {
        justify-content: flex-end;
        padding: 0;
      }
    }
  }

  // -------------
  // Variações
  // -------------

  // Com filtro (masonry)
  &--filter-5 {

    .bs-mural__list {
      display: block;
      overflow: hidden;
      z-index: 1;
    }

    .bs-filter__sizer,
    .bs-mural__item {
      display: block;
      width: 100%;

      @include media(">=tablet") {
        height: 320px;
        width: 33.33%;
      }

      @include media(">=desktop") {
        width: 25%;
      }
    }

    .bs-mural__item {

      // ignora o primeiro item (div vazia)
      &:nth-child(2),
      &:nth-of-type(5n+1) {
        @include media(">=tablet", "<desktop") {
          width: 66.666%;
        }
      }

      &--1-2 {
        @include media(">=desktop") {
          height: 640px;
        }
      }

      &--2-1 {
        @include media(">=tablet") {
        }

        @include media(">=desktop") {
          width: 50%;
        }
      }

      &--2-2 {
        @include media(">=tablet") {
          width: 66.666%;
        }

        @include media(">=desktop") {
          height: 640px;
          width: 50%;
        }
      }
    }

    .bs-mural__image {
      height: 100%;
      width: 100%;
    }
  }

  // Columns
  &--col {
    @for $i from 2 through 5 {
      &#{$i} {
        .bs-mural__item {
          @include flex-basis(100%);
          height: 320px;

          @include media(">=tablet") {
            @include flex-basis(calc(100% / 2));
          }

          @include media(">=desktop") {
            width: 50%;
            @include flex-basis(calc(100% / #{$i}));
          }
        }
      }
    }
  }

  // Pra funcionar bem, precisaria ser como um grid de layout mesmo: Ter 12
  // colunas e opções para alterar os tamanhos em cada quebra de viewport.
  // Pra isso seria melhor criar um mixin como o flexgrid, mas é um trabalho
  // para reavaliar em outro momento.
  // Referências:
  // - https://css-tricks.com/snippets/css/complete-guide-grid/
  // - https://css-tricks.com/css-grid-in-ie-css-grid-and-the-new-autoprefixer/
  // - https://medium.com/@elad/supporting-css-grid-in-internet-explorer-b38669e75d66
  // &--grid {

  //   .bs-mural__list {
  //     display: grid;
  //     grid-auto-rows: 320px;
  //   }

  //   &-2 .bs-mural__list {
  //     grid-template-columns: repeat(2, 1fr);
  //   }

  //   &-3 .bs-mural__list {
  //     grid-template-columns: repeat(3, 1fr);
  //   }

  //   &-4 .bs-mural__list {
  //     grid-template-columns: repeat(4, 1fr);
  //   }

  //   &-5 .bs-mural__list {
  //     grid-template-columns: repeat(5, 1fr);
  //   }

  //   .bs-mural__item {
  //     &--1-1 {}

  //     &--1-2 {
  //       @include media(">=desktop") {
  //         grid-column-end: auto;
  //         grid-row-end: span 2;
  //       }
  //     }

  //     &--2-1 {
  //       @include media(">=desktop") {
  //         grid-column-end: span 2;
  //       }
  //     }

  //     &--2-2 {
  //       @include media(">=desktop") {
  //         grid-column-end: span 2;
  //         grid-row-end: span 2;
  //       }
  //     }
  //   }
  // }
}
