//-------------------------------
// Menu do Topo
// Lista links na faixa do topo do header
//-------------------------------
.bs-top-menu {

  &__list {
    display: flex;
  }

  &__item {
    position: relative;

    &:hover,
    &.bs-js-has-focus {

      .bs-top-menu__dropdown {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__link {
    display: block;
    font-size: rem(14);
    line-height: 20px;
    min-height: 33px;
    padding: 5px 10px;
    margin-right: 3px;

    &:hover,
    &:focus {
      background-color: $c-gray-lighter;
      // color: $c-primary;

      & + .bs-top-menu__dropdown {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }

    &:not(:only-child) {

      &::after {
        // icon-seta-baixo-b
        content: "\e91b";
        display: inline-block;
        font-family: "bradesco_v2";
        font-size: rem(10);
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }

  &__dropdown {
    background: $c-white;
    // border: 1px solid $c-gray;
    box-shadow: b-shadow("2");
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: auto;
    z-index: z("absolute");

    .bs-top-menu__item:hover {
      background-color: $c-gray-lighter;
    }

    .bs-top-menu__link {
      padding: 8px 10px;
      white-space: nowrap;
    }
  }

  &__group {
    padding-bottom: $grid-gutter / 2;
  }

  &__group-title {
    background-color: $c-gray-lighter;
    border-bottom: 1px solid $c-gray;
    color: $c-primary;
    display: block;
    font-size: rem(12);
    font-weight: fw("semibold");
    line-height: 18px;
    min-height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  &__subitem {}
}
