//-------------------------------
// Texto Legal
//-------------------------------
.bs-legal-text {
  color: $c-gray-darker !important;
  display: block;
  font-family: $font-text !important;
  font-size: rem(12) !important;
  font-style: normal !important;
  font-weight: fw('regular') !important;
  line-height: 1.5;
  padding: (1 * $grid-gutter) 0 (2 * $grid-gutter);

  p,
  ul,
  ol,
  li,
  span {
    margin: 0;
    font-size: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    font-size: inherit;
    margin: 0;
  }

  small {
    font-size: inherit;
  }

  table {
    display: none;
  }

  a {
    color: $c-primary !important;
    font-weight: fw("bold");
  }
}
