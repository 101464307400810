//-------------------------------
// Banner Apps [deprecated]
// Banner com links para apple store e play store.
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-banner-apps {
  position: relative;

  &--apps {
    display: none;
    max-width: 90%;
    overflow: hidden;

    @include media(">=desktop") {
      display: block;
    }
  }

  &__bg {
    display: block;
    height: auto;
    width: 100%;
  }

  &__btn {
    display: inline-block;
    margin-right: 15px;

    img {
      width: 100px;

      @include media(">=wide") {
        width: auto;
      }
    }
  }

  &__btn-group {
    bottom: 17px;
    display: block;
    left: 25px;
    position: absolute;

    @include media(">=desktop") {
      bottom: 20px;
      left: 30px;
    }
  }
}
