//---------------------
// Typography variables
//
// Lint Options:
// sass-lint:disable no-warn
//---------------------

// Font Families default
$font-primary:    "Bradesco" !default; // 300, 400, 500, 700
$font-secondary:  "Bradesco" !default; // 300, 400, 600, 700
$font-default:    "Bradesco", Arial, sans-serif;
$font-icon:       "bradesco_v2";

// Font Weights (default)
$font-weight: (
  "thin":       100,
  "fine":       200,
  "light":      300,
  "regular":    400,
  "medium":     500,
  "semibold":   600,
  "bold":       700,
  "heavy":      800,
  "extrabold":  900,
) !default;

// Fonts
$font-title:     $font-primary;
$font-text:      $font-secondary;
$font-button:    $font-primary;


// Font Weight map function
// Use:
// font-weight: fw("regular");
//
// @param  {String}  $weight  A valid weight key
// @return {Number}           The font weight's value
@function fw($weight) {

  // Sets to default if no layer passed
  @if ($z-depth, $weight) {
    @return map-get($font-weight, $weight);
  }

  // Return warning message if weight passed not exists
  @if not map-has-key($font-weight, $weight) {
    @warn "No weight found for `#{$weight}` in $font-weight map. Property omitted.";
  }

  // return the weight's depth value
  @return map-get($font-weight, $weight);
}
