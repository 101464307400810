.bs-map-info {
  // margin: 0 (-$grid-gutter);
  width: 100%;

  @include media(">=tablet") {
    display: flex;
  }

  &__pi-maps {
    width: 100%;
  }

  &__address {
    color: $c-red;
    font-size: 19px;
    font-weight: bold;
    margin-top: 10px;

    a {
      color: inherit;
    }
  }


  &__item-phone {
    @include flex-basis(100%);
    padding:  0 $grid-gutter $grid-gutter;

    @include media(">=tablet") {
      @include flex-basis(33.333%);
    }
  }

  &__item-map {
    @include flex-basis(100%);
    padding:  0 $grid-gutter $grid-gutter;

    @include media(">=tablet") {
      @include flex-basis(77.777%);
    }
  }

  .bs-card-text {
    height: 100%;

    &__title {
      word-break: break-word;
    }

    &__list {
      height: 100%;
    }

    &__item {
      padding-bottom: 0;
      flex-basis: 100%;
      max-width: 100%;
    }

  }
}
