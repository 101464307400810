.bs-diapers {
	display: flex;
	transition: .3s;
  flex-wrap: wrap;

  @include media(">=desktop") {
    box-shadow: 0 5px 15px 0 rgba($c-black, 0.1);
    border: {
      color: $c-gray-light;
      style: solid;
      radius: 5px;
      width: 1px;
    }
    background-color: $c-white;
  }

	.bs-diapers__pointer {
		position: absolute;
		left: 0;
		top: -8px;
		transition: left 0.4s;
		color: $c-red;
		font-size: 55px;
		margin-left: -28px;
		pointer-events: none;
	}

	progress {
    background-color: $c-gray;
		bottom: 54px;
    height: 1px;
		position: absolute;
		pointer-events: none;
		width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;


		&::-webkit-progress-value {
      background-color: $c-red;
			bottom: -1px;
			height: 3px;
			position: absolute;
		}

		&::-webkit-progress-value {
      transition: width 0.4s;
    }

    &::-ms-fill {
      background-color: $c-red;
    }
    // &[value] {
    //   bottom: -1px;
		// 	height: 3px;
		// 	position: absolute;
    //   -webkit-appearance: none;
    //   -moz-appearance: none;
    //   appearance: none;
    //   border: none;
    // }

    // &[value] {
    //   box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.5);
    //   background-color: $c-red;
		// 	bottom: -1px;
		// 	height: 3px;
    //   position: absolute;


    //       background-color: $c-gray;
    //   -webkit-appearance: none;
    //   -moz-appearance: none;
    //        appearance: none;
    //        border: none;
    //   color: $c-red;
    // }
    &[value]::-moz-progress-bar {
      box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.5);
      background-color: $c-red;
			bottom: -1px;
			height: 3px;
			position: absolute;
    }
  }



	&__input {
    background: transparent;
		display: block;
		appearance: none;
		width: 100%;
		margin: 0;
		height: 115px;
		cursor: pointer;

		&:focus {
			outline: none;
    }

    &::-ms-tooltip {
      display: none;
    }

    &::-ms-thumb {
      background: transparent;
      border: none;
    }

    &::-ms-track {
      appearance: none;
      background: transparent;
      color: transparent;
      border-color: transparent;
      height: 0;
    }

    &::-ms-fill-lower {
      appearance: none;
      background: transparent;
      color: transparent;
    }

    &::-ms-fill-upper {
      appearance: none;
      background: transparent;
      color: transparent;
    }

		// Webkit
		&::-webkit-slider-runnable-track {
			width: 100%;
			height: 80px;
			background: transparent;
    }

    &::-webkit-slider-thumb {
			height: 50px;
			position: relative;
			appearance: none;
			width: 50px;
      z-index: 9999;

      -webkit-appearance: none;
      -moz-appearance: none;
    }

    &::-moz-range-progress {
			width: 100%;
			height: 80px;
			background: transparent;
    }

    &::-moz-range-thumb {
      border: 0;
      background: transparent;
      height: 50px;
			position: relative;
			appearance: none;
			width: 50px;
      z-index: 9999;
      -moz-appearance: none;
    }
	}




	// // Firefox
	// &__input::-moz-range-track,
	// &__input::-moz-range-progress {
	// 	width: 100%;
	// 	height: $height;
	// 	background: $upper-background;
	// }

	// &__input::-moz-range-progress {
	// 	background: $lower-background;
	// }

	// &__input::-moz-range-thumb {
	// 	appearance: none;
	// 	margin: 0;
	// 	height: $thumb-height;
	// 	width: $thumb-height;
	// 	background: $thumb-color;
	// 	border-radius: 100%;
	// 	border: 0;
	// 	transition: background-color 150ms;
	// }

	// // Internet Exploder
	// &__input::-ms-track {
	// 	width: 100%;
	// 	height: $height;
	// 	border: 0;
	// 	// color needed to hide track marks
	// 	color: transparent;
	// 	background: transparent;
	// }

	// &__input::-ms-fill-lower {
	// 	background: $lower-background;
	// }

	// &__input::-ms-fill-upper {
	// 	background: $upper-background;
	// }

	// &__input::-ms-thumb {
	// 	appearance: none;
	// 	height: $thumb-height;
	// 	width: $thumb-height;
	// 	background: $thumb-color;
	// 	border-radius: 100%;
	// 	border: 0;
	// 	transition: background-color 150ms;
	// 	// IE Edge thinks it can support -webkit prefixes
	// 	top: 0;
	// 	margin: 0;
	// 	box-shadow: none;
	// }

	&__time-line {
    width: 100%;

    @include media(">=desktop") {
      width: 70%;
    }
	}

	&__item {

    @include media(">=desktop") {
      display: flex;
      padding: ($grid-gutter * 2) ($grid-gutter * 2) 0;

      &:last-child {
        padding: 0 ($grid-gutter * 2) ($grid-gutter * 2);
      }
    }
	}

	&__item-title {
    align-items: center;
    display: flex;
    font-weight: bold;
		text-align: center;
    text-transform: uppercase;
    margin-bottom: $grid-gutter;


    @include media(">=desktop") {
      margin-bottom: 0;
      width: 80px;
    }
	}

	&__item-pointer{
		flex: 1;
    margin: 0 0 30px;
    // margin: 0 20px 30px;

    @include media(">=phone") {
      margin: 0 20px 30px;
    }

    @include media(">=desktop") {
      margin-bottom: 0 40px 30px;
      // margin-bottom: 0 40px 0px;
    }
	}

	&__item-line {
    display: none;
		position: relative;
    width: 100%;

    @include media(">=phone") {
      display: block;
    }
  }

  &__item-line-select {
    @include media(">=phone") {
      display: none;
    }
  }

	&__item-line-box {
    top: -40px;
    flex-wrap: wrap;
    display: flex;
		font-size: 14px;
		pointer-events: none;
		position: absolute;
    text-align: center;
    margin-left: -50px;
    padding: 0 20px;
    width: 100px;

    span {
      width: 100%;
    }

		&::before {
			content: '';
			background-color: $c-white;
			border: 1px solid $c-gray-dark;
			border-radius: 100%;
			height: 12px;
			margin: 0 auto;
			top: -22px;
			left: 0;
			right: 0;
			transition: 0.4s;
			position: absolute;
			width: 12px;
			// z-index: 9;
		}

		&:hover {
			.icon {
				opacity: 1;
			}
		}

		&.bs-diapers--active::before {
			background-color: $c-red;
			border-color: $c-red;
		}

		&.bs-diapers--active-pointer .icon {
			color: $c-red;
			opacity: 1;
		}

		&:hover {
			.bs-diapers__dot,
			.bs-diapers__dot-line {
				background-color: $c-red;
			}
		}

		.icon {
			color: $c-gray-light;
			font-size: 50px;
			position: absolute;
			transition: 0.3s;
			top: -75px;
			opacity: 0;

			display: none;
		}

	}

	&__dot {
		background-color: $c-gray-dark;
		border-radius: 20px;
		height: 20px;
		width: 20px;
    position: relative;
		margin: 0 auto;
		z-index: 1;
	}

	&__dot-line {
		background: $c-gray-dark;
		width: 100%;
		height: 3px;
		position: absolute;
		left: -50%;
		bottom: 9px;
	}


	// Resultado e Input
	&__result {
    align-items: center;
    background: $c-white;
    box-shadow: 0 5px 15px 0 rgba($c-black, 0.1);
    border: {
      color: $c-gray-light;
      style: solid;
      radius: 5px;
      width: 1px;
    }
    display: flex;
		border-left: 1px solid $c-gray;
    flex-wrap: wrap;
    padding: ($grid-gutter * 2);
    width: 100%;

    @include media(">=desktop") {
      border: 0;
      border-left: 1px solid $c-gray;
      box-shadow: none;
      width: 30%;
    }
  }

  &__result-item {
    width: 100%;

    &:first-child {
      margin-bottom: $grid-gutter;
    }
  }

	&__result-title {
		font-weight: bold;
		margin-bottom: $grid-gutter;
	}

	&__result-content {
		color: $c-black;
		font-size: 14px;
    transition: color 0.4s;

		&.bs-diapers--active {
			color: $c-red;
			font-size: 40px;
			font-weight: bold;
		}
	}

	&__result-select {
		visibility: hidden;
		height: 0;
		transition: 0.4s;
		opacity: 0;
		overflow: hidden;

		&.bs-diapers--active {
			visibility: visible;
			height: 55px;
			opacity: 1;
		}
	}
}
