.bs-card-text {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -#{$grid-gutter};
    margin-right: -#{$grid-gutter};
  }

  &__item {
    @include flex-basis(100%);
    display: flex;
    padding:  0 $grid-gutter $grid-gutter;

    @include media(">=phone") {
      @include flex-basis(50%);
    }

    @include media(">=desktop") {
      @include flex-basis(33.333%);
    }
  }

  &__box {
    border: 1px solid $c-gray;
    border-radius: 5px;
    display: block;
    padding: $grid-gutter;
    width: 100%;
  }

  &__title {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(22);
    line-height: 26px;
    margin: 0;
  }

  &__subtitle {
    color: $c-gray-darker;
    font-size: rem(16);
    margin-bottom: 10px;
  }

  &__content {
    p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      display: block;
      font-family: $font-title;
      font-size: rem(22);
      font-weight: fw("light");
    }
  }
}
