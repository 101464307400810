wlauto {
  align-items: inherit;
  display: inherit;
  flex-direction: inherit;
  flex-wrap: inherit;
  height: inherit;
  width: auto;

  // Corrige cores de fundo e texto onHover
  &.wlautoHover:hover,
  &.wlautoHover *:hover {
    background-color: $c-secondary !important;
    color: $c-white !important;

    a {
      color: $c-white !important;
      text-decoration: underline;
    }

    @include placeholder {
      color: $c-white;
    }
  }

  // Corrige cores de fundo e texto onPlaying
  &.wlautoPlaying,
  &.wlautoPlaying * {
    background-color: $c-yellow-light !important;
    color: $c-gray-darker !important;

    a {
      color: $c-gray-darker !important;
      text-decoration: underline;
    }

    @include placeholder {
      color: $c-gray-darker;
    }
  }

  // Corrige a largura do elemento
  .bs-form__field-group & {
    width: 100%;
  }

  // Corrige a largura e altura do elemento
  .bs-gallery__figure & {
    height: 100%;
    width: 100%;
  }

  // Fix display of wlauto when it's child of bs-media__meta
  .bs-media__meta & {
    display: inline-block;
  }

  // Desativa o clique no elemento wlauto. Funciona apenas se o evento
  // estiver em um elemento acima do wlauto. Caso contrário, o pointer-events
  // deve ser restaurado no filho imediato do wlauto
  // obs: pode não funcionar no IE
  .bs-card-slider__nav &,
  .bs-media__arrows &,
  .bs-tabs__arrows & {
    pointer-events: none;
  }

  // Fix button height
  .bs-quick-menu__buttons & {
    height: auto;
  }

  // Corrige a altura do elemento de vídeo para ocupar 100% da tela
  .bs-banner__video .bs-modal__body > & {
    height: 100%;
  }

  // Remove altura do elemento para evitar quebra do botão
  .bs-form__field-button & {
    height: 0;
  }

  // Deve ter o mesmo valor para flex que o .bs-modal__footer
  .bs-modal__body + & {
    flex: 0;
  }
}
