//-------------------------------
// Alert
// Modal de diálogo para alertas
//-------------------------------
.bs-alert {
  $alert-icon-size: 100px;

  &__icon {
    align-items: center;
    display: flex;
    height: $alert-icon-size;
    justify-content: center;

    &::before {
      // border: 3px solid $c-gray;
      // border-radius: 100%;
      color: $c-gray;
      font-family: "bradesco_v2";
      font-size: $alert-icon-size;
      height: $alert-icon-size;
      line-height: $alert-icon-size;
      text-align: center;
      width: $alert-icon-size;
    }
  }

  &__title {
    display: block;
    font-family: $font-title;
    font-size: 22px;
    font-weight: fw("semibold");
    margin-bottom: ($grid-gutter / 2);

    &:empty {
      display: none;
    }
  }

  &__content {
    margin-bottom: ($grid-gutter * 2);

    &:empty {
      display: none;
    }
  }

  &__button {
    &:empty {
      display: none;
    }
  }

  &[data-alert-type=""] &__icon {
    display: none;
  }

  // &[data-alert-type="error"] .bs-modal__header,
  &[data-alert-type="error"] &__icon::before {
    border-top-color: $c-error;
    color: $c-error;
    content: "\e9f0";
  }

  // &[data-alert-type="success"] .bs-modal__header,
  &[data-alert-type="success"] &__icon::before {
    border-top-color: $c-success;
    color: $c-success;
    content: "\e9ea";
  }

  // &[data-alert-type="warning"] .bs-modal__header,
  &[data-alert-type="warning"] &__icon::before {
    border-top-color: $c-warning;
    color: $c-warning;
    content: "\e9e7";
  }

  // &[data-alert-type="info"] .bs-modal__header,
  &[data-alert-type="info"] &__icon::before {
    border-top-color: $c-blue-light;
    color: $c-info;
    content: "\e968";
  }

  &[data-alert-type="question"] &__icon::before {
    content: "\e9e8";
  }

  &[data-alert-type="loading"] &__icon {
    @include loader;
    display: block;
    height: 100px;
    margin: 0 auto;
    position: relative;
    width: 100px;
  }

  &[data-alert-type="loading"] .bs-modal__header {
    padding: $grid-gutter 0;
  }
}
