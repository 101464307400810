//-------------------------------
// Busca Vazia
// Componente para mensagem de busca vazia, derivado do media
//-------------------------------
.bs-empty-search {

  .bs-media__figure {
    align-items: center;
    background-color: $c-gray-light;
    border-radius: 90px;
    color: $c-primary;
    display: flex;
    height: 90px;
    justify-content: center;
    margin: 0 auto 20px;
    text-align:center;
    width: 90px;
    @include media(">=tablet") {
      height: 124px;
      width: 124px;
    }

    @include media(">=desktop") {
      height: 148px;
      width: 148px;
    }
    &.bs-icon-box {
      font-size: rem(50);

      @include media(">=tablet") {
        font-size: rem(75);
      }

      @include media(">=desktop") {
        font-size: rem(90);
      }
    }
  }

  .bs-media__text {
    padding-top: 0;
  }

  .bs-media__title {
    color: $c-primary;
    font-size: rem(22);
    line-height: 1.3;
    margin-bottom: 0;
    word-spacing: -1px;
  }

  .bs-media__description {
    color: $c-gray-darker;
    font-size: rem(16);
    word-spacing: 1px;
  }

  .bs-media__link {
    color: $c-gray-darker;
    font-weight: bold;
  }

  .bs-media__link--search {
    color: $c-red;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

}
