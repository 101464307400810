//-------------------------------
// Accordion
// Faz o efeito de acordeão/sanfona, com um título-botão e um texto descritivo
//
// TODO: Rever se não é melhor ser feito com dl/dt/dd (https://app.asana.com/0/545646996702866/702059187640158/f)
// TODO: Comentar cada elemento e modifier
//-------------------------------
.bs-accordion {

  &__content {
    height: 0;
    transition: $transition-time;
    visibility: hidden;
  }

  &__icon {
    align-items: center;
    background-color: $c-gray-lightest;
    border-left: 1px solid $c-gray-light;
    bottom: 0;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    transition: background .3s;
    width: 70px;

    &::after,
    &::before {
      background-color: $c-gray;
      bottom: 0;
      content: "";
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
    }

    &::after {
      height: 4px;
      width: 30px;
    }

    &::before {
      height: 30px;
      width: 4px;
    }
  }

  &__item {
    border: {
      left: 1px solid $c-gray-light;
      right: 1px solid $c-gray-light;
      top: 1px solid $c-gray-light;
    }
    overflow: hidden;
    transition: $transition-time;

    &:first-child {
      border: {
        top-left-radius: 5px;
        top-right-radius: 5px;
      }
    }

    &:last-child {
      border: {
        bottom-left-radius: 5px;
        bottom-right-radius: 5px;
        bottom: 1px solid $c-gray-light;
      }
    }

    &--open {
      .bs-accordion__title {
        border-color: $c-gray-light;
      }

      .bs-accordion__content {
        visibility: visible;
      }

      .bs-accordion__box {
        opacity: 1;
      }

      .bs-accordion__icon {
        &::before {
          opacity: 0;
        }
      }
    }
  }

  // Usado para ter transição no momento em que o conteúdo é revelado
  &__box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    opacity: 0;
    padding: $grid-gutter (2 * $grid-gutter) (2 * $grid-gutter);
    transition: $transition-time;

    p {
      margin-bottom: $grid-gutter;
    }

    a {
      color: $c-primary;
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__trigger {
    background-color: $c-gray-lighter;
    border-bottom: 1px solid transparent;
    padding: $grid-gutter 85px $grid-gutter (2 * $grid-gutter);
    position: relative;
    text-align: left;
    transition: background .3s;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $c-gray-light;
      transition: background .3s;

      .bs-accordion__icon {
        background-color: $c-gray-light;
        transition: background .3s;
      }
    }

    &:focus {
      outline: none;

      .bs-accordion__title {
        @include focus($c-gray-dark, 5);
      }
    }
  }

  &__title {
    color: $c-primary;
    display: inline-block;
    font-family: $font-title;
    font-size: 18px;
    font-weight: fw("medium");
  }

  &--transparent {
    .bs-accordion__item {
      border: {
        bottom: 2px solid $c-gray-light;
        left: 0;
        right: 0;
        top: 0;
      }

      &:first-child {
        border-top: 2px solid $c-gray-light;
      }

      &--open {
        .bs-accordion__icon {
          transform: rotate(180deg);
        }
      }
    }

    .bs-accordion__trigger {
      align-items: center;
      background-color: transparent;
      border: 0;
      display: flex;
      flex-direction: row;
      padding: (2 * $grid-gutter) 0;

      .icon {
        color: $c-primary;
        display: block;
        font-size: rem(75);
        margin-right: $grid-gutter;
        @include media(">=tablet") {
          font-size: rem(80);
          margin-right: (2 * $grid-gutter);
        }
      }
    }

    .bs-accordion__title {
      align-items: center;
      color: $c-gray-darker;
      display: flex;
      flex-direction: row;
      font-size: rem(22);
      font-weight: fw("bold");
      line-height: 1.27;
      padding-right: $grid-gutter;
    }

    .bs-accordion__icon {
      background: transparent;
      border: 0;
      margin-left: auto;
      position: relative;
      transition: $transition-time;
      width: auto;

      &::after,
      &::before {
        display: none;
      }

      .icon {
        color: $c-gray-darker;
        font-size: rem(24);
        margin-right: 0;
      }
    }

    .bs-accordion__box {
      padding: 0;
    }
  }

  &[class*="bs-media--col"] {
    .bs-media__item {
      margin-bottom: 0;
      @include media(">=phone") {
        @include flex-basis(100%);
      }
      @include media(">=tablet") {
        @include flex-basis(33.333%);
        margin-bottom: (2 * $grid-gutter);
      }
      @include media(">=wide") {
        padding: {
          bottom: 0;
          top: 0;
        }
      }
    }

    .bs-accordion__box {
      margin: 0 -#{$grid-gutter};
      padding-bottom: $grid-gutter;
      @include media(">=wide") {
        opacity: 1;
        padding-bottom: 0;
      }
    }

    .bs-media__text {
      padding: 0;
    }

    .bs-accordion__item {
      @include media(">=tablet") {
        //padding-bottom: (3 * $grid-gutter);
      }
      @include media(">=wide") {
        display: flex;
        padding: {
          bottom: $grid-gutter;
          top: (3 * $grid-gutter);
        }
      }
    }

    .bs-accordion__content {
      @include media(">=wide") {
        height: auto;
        width: 75%;
      }
    }

    .bs-accordion__title {
      h4 {
        padding-right: 0;
      }
      @include media(">=wide") {
        align-self: flex-start;
        cursor: default;
        padding: 0 (2 * $grid-gutter) 0 0;
        width: 25%;
      }

      .icon {
        @include media(">=wide") {
          font-size: rem(70);
        }
      }
    }

    .bs-accordion__icon {
      @include media(">=wide") {
        display: none;
      }
    }
  }
}
