.bs-contrast--high {

    //header
    .pa-header {
        background-color: $c-black;

        &__logo {
            .pa-logo {
                // display: none;
                // filter: opacity(1) !important;

                &--white {
                    // display: block;
                }
            }
        }

        &__nav-toggle {
            .pa-hamburger {
                span,
                span:before,
                span:after {
                    background-color: $c-yellow-light !important;
                }
            }
        }
    }

    .pa-header-nav {

        &__link {
            border-color: $c-black !important;

            &:hover{
                border-top-color: $c-black !important;
                border-bottom-color: $c-yellow-light !important;
            }

            &--active {
                border-bottom-color: $c-yellow-light !important;
            }
        }
    }

    .pa-menu {
        background-color: $c-black !important;

        &__header {
            .pa-menu {
                &__close {
                    span:before,
                    span:after {
                        background-color: $c-yellow-light !important;
                    }
                }
            }
        }

        &:before {
            background-color: $c-yellow-light !important;
        }
    }

    .bs-table {
      border-bottom: 2px solid #ff0 ;
    }

    .bs-diapers, .bs-diapers__result {
      background-color: rgb(0, 0, 0) !important;
    }

    .bs-data-probably__data {
      background-color: rgb(0, 0, 0) !important;
      border: 2px solid #ff0 ;
    }

}
