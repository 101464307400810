//
// Placeholder @Mixin
// Style placeholder attribute in form fields
//
// Reference:
// @link https://css-tricks.com/almanac/selectors/p/placeholder/
// @link https://stackoverflow.com/a/17181946
//
// Example usage:
// input {
//   @include placeholder {
//     // styles for placeholder here
//   }
// }
//
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
