//-------------------------------
// Reset
//-------------------------------

* {
  appearance: none;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}

b,
strong {
  font-weight: fw("bold");
}

i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
}

address {
  font-style: normal;
}

input,
button,
select,
textarea {
  font: inherit;
}
