//-------------------------------
// Menu de Contexto DEPRECATED
// Componente de menu no estilo de um tooltip
//-------------------------------
.bs-context-menu {
  background-color: $c-white;
  border: {
    color: $c-gray-light;
    radius: 4px;
    style: solid;
    width: 1px;
  }
  box-shadow: 0 2px 5px 0 rgba($c-black, 0.18);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  // right: 0;
  // top: 100%;
  transition: opacity $transition-time, transform $transition-time;
  visibility: hidden;
  width: 180px;
  z-index: z("absolute");

  &::after,
  &::before {
    content: "";
    display: block;
    height: 0;
    position: absolute;
    // right: 15px;
    width: 0;
  }

  &::after {
    // border: {
    //   bottom: 7px solid $c-white;
    //   left: 5px solid transparent;
    //   right: 5px solid transparent;
    // }
    // top: -5px;
  }

  &::before {
    // border: {
    //   bottom: 7px solid $c-gray-light;
    //   left: 5px solid transparent;
    //   right: 5px solid transparent;
    // }
    // top: -7px;
  }

  &__item {
    border-bottom: 1px solid $c-gray-light;

    @for $i from 0 through 5 {
      &:nth-child(#{$i}) {
        .bs-context-menu__link {
          transition-delay: $i * 0.1s;
        }
      }
    }

    &:last-child {
      border: 0;
    }

    &:hover {
      background-color: $c-gray-lightest;
    }

    &--borderless {
      border: 0;
    }
  }

  &__link {
    color: $c-gray-darker;
    display: block;
    font: {
      family: $font-title;
      size: rem(14);
      weight: fw("bold");
    }
    opacity: 0;
    padding: 10px $grid-gutter;
    transform: translateX(25px);
    transition: opacity $transition-long-time, transform $transition-time;

    &:focus {
      outline-style: dotted;
      outline-width: 1px;
    }
  }

  &--open {
    opacity: 1;
    visibility: visible;


    .bs-context-menu__link {
      opacity: 1;
      transform: translateX(0);
    }
  }

  // Posicionamento do menu
  // Ex: --down e --right
  // Menu será exibido abaixo e para a direita
  &--down {
    top: 100%;

    &::after {
      border: {
        bottom: 7px solid $c-white;
        left: 5px solid transparent;
        right: 5px solid transparent;
      }
      top: -5px;
    }

    &::before {
      border: {
        bottom: 7px solid $c-gray-light;
        left: 5px solid transparent;
        right: 5px solid transparent;
      }
      top: -7px;
    }
  }

  &--up {
    bottom: 100%;

    &::after {
      border: {
        left: 5px solid transparent;
        right: 5px solid transparent;
        top: 7px solid $c-white;
      }
      bottom: -5px;
    }

    &::before {
      border: {
        left: 5px solid transparent;
        right: 5px solid transparent;
        top: 7px solid $c-gray-light;
      }
      bottom: -7px;
    }
  }

  &--left {
    right: 0;

    &::after,
    &::before {
      right: 15px;
    }
  }

  &--right {
    left: 0;

    &::after,
    &::before {
      left: 15px;
    }
  }
}
