//-------------------------------
// Produtos (old) [deprecated]
// Lista de produtos
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.comp-produtos {
  background: {
    color: $c-primary-dark;
  }

  .bs-section-header__title {
    color: $c-white;

    &::after {
      background-color: $c-white;
    }
  }

  .bs-icon-grid__item {
    color: $c-white;
  }

  &--icon-grid {
    color: $c-white;
  }

  &--bg-blue {
    background-color: $c-secondary-dark;
  }

  &--bg-white {
    background-color: $c-white;

    .bs-section-header__title {
      color: $c-gray-darker;

      &::after {
        background-color: $c-primary;
      }
    }

    .bs-icon-grid__item {
      color: $c-primary;
    }

    .bs-icon-grid__item:hover .bs-icon-grid__title::after {
      background-color: $c-primary;
    }

    .bs-icon-grid__title {
      color: $c-gray-darker;
    }
  }
}
