.bs-data-probably {
  .bs-modal  {

    &__body {
      text-align: center;
    }

    &__size {
      margin-bottom: $grid-gutter * 2;
      p {
        margin: 0;
      }
    }

    &__header {
      text-align: center;
      .icon {
        font-size: 110px;
      }
    }
  }


  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: $grid-gutter;
  }

  .bs-media__box {
    display: flex;
  }

  &__data {
    background-color: $c-white;
    flex: 1;
    margin-bottom: $grid-gutter;
    padding: $grid-gutter * 2;

    @include media(">=tablet") {
      margin-bottom: 0;
    }

    .bs-form__field {
      cursor: pointer;
    }
  }

  .lightpick__previous-action,
  .lightpick__next-action {
    font-size: 24px;
  }
}

.bs-time-line__nav-item {
  display: flex;
  flex-wrap: wrap;

  .bs-time-line--month {
    order: 1;
  }

  .bs-time-line--year {
    order: 0;
  }
}

.bs-time-line {
  max-width: 870px;
  margin: 0 auto;
  padding-top: 50px;

  .flickity-page-dots {
    display: none;
  }

  .bs-content__text {
    p > span,
    p > div {
      display: block;
      margin: 10px 0;
    }
  }
}
