//-------------------------------
// Fromulário (old) [deprecated]
// Usado em alguns componentes de card antigos
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.form-group {
  margin: $grid-gutter 0 (2 * $grid-gutter);

  input[type="text"],
  input[type="email"],
  textarea,
  select {
    @include placeholder();
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgba($c-black, .8);
    color: $c-black;
    font-family: $font-text;
    font-size: rem(14);
    font-weight: fw("regular");
    height: 36px;
    line-height: 36px;
    padding: 0 5px;
    transition: $ease-in-out;
    width: 100%;

    &:focus {
      border-color: rgba($c-black, 1);
    }
  }

  select {
    background-position: 100% 8px;
    background-repeat: no-repeat;
    background-size: 24px;

    &::-ms-expand {
      display: none;
    }
  }

  + span {
    color: $c-white;
    display: block;
    font-size: rem(20);
    font-weight: bold;
    text-align: center;

    @include media(">=tablet") {
      font-size: rem(26);
    }
  }
}
