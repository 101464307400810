//-------------------------------
// Header
//-------------------------------
.bs-header {
  $i: &;
  align-items: flex-start;
  background: $c-white;
  box-shadow: $box-shadow-fixed;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  transition: none;
  width: 100%;
  z-index: z("fixed");

  // Faixa do topo do header
  &__top {
    border-bottom: 1px solid $c-gray;
    display: none;
    min-height: ($grid-gutter * 2);
    order: 1;
    width: 100%;

    @include media(">=desktop") {
      display: block;
      width: 100%;
    }

    .bs-container,
    .container {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__social {
    padding-right: $grid-gutter * 2;

    & + * {
      margin-left: auto;
    }
  }

  &__top-menu {
    padding: 0 $grid-gutter;
  }

  &__accessibility-menu {}

  // Faixa pricipal do Header
  &__main {
    background-color: $c-white;
    // border-bottom: 1px solid $c-gray;
    order: 2;
    width: 100%;

    .bs-container,
    .container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;

      @include media("<desktop") {
        max-width: 100%;
        padding: 0;
      }

      @include media(">=desktop") {
        justify-content: flex-start;
      }
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    padding: 0 $grid-gutter;

    a {
      align-items: center;
      display: flex;
    }

    @include media(">=desktop") {
      padding-right: $grid-gutter * 2;
    }

    // Logo as SVG has an extra padding
    .bs-logo {
      display: block;
      height: 60px;
      max-width: 100%;
      transition: max-height $transition-time;
      width: 220px;

      path {
        fill: $c-red;
      }

      &--white {
        display: none;
      }
    }
  }

  &__search {
    align-items: center;
    display: flex;
    // position: relative;

    @include media(">=phone") {
      margin-left: auto;
    }

    @include media(">=desktop") {
      flex: 1;
      padding: 0 ($grid-gutter * 2);
    }

    // Corrige a largura do campo busca no header
    > wlauto {
      width: 100%;
    }

  }

  &__nav {
    // display: flex;
    // order: -1;

    @include media(">=desktop") {
      margin-left: -25px;
    }

    &-toggle {
      display: block;
      height: 60px;
      transition: height $transition-time;
      width: 60px;

      @include media(">=tablet") {
        height: 80px;
        width: 90px;
      }

      // Botão (hamburger) para abrir o menu
      .bs-hamburger {
        @include hamburger();
        display: block;
        height: 20px;
        margin: 0 auto;
        width: 26px;

        span {
          height: 3px;
          margin-top: 0;
        }

        span::before {
          top: -8px;
        }

        span::after {
          top: -16px;
        }
      }

      &:hover,
      &:focus {

        .bs-hamburger span,
        .bs-hamburger span::before,
        .bs-hamburger span::after {
          background-color: $c-primary;
        }
      }
    }
  }

  &__login,
  &__accessibility {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: $grid-gutter * 2;

    @include media(">=wide") {
      // min-width: 285px;
    }
  }

  &__login {
    display: none;

    @include media(">=desktop") {
      display: flex;
      margin-left: auto;
    }
  }

  &__user {
    display: flex;
    margin-left: auto;
  }

  .bs-user-menu {
    position: absolute;
    right: $grid-gutter;
    top: 0;
  }

  .bs-social__link,
  .bs-accessibility-menu__button {
    height: 30px;
    overflow: hidden;

    &:hover,
    &:focus {
      background-color: $c-gray-lighter;
    }
  }


  // ---------------
  // Variações
  // ---------------

  // floating
  &--fixed,
  &--floating {
    left: 0;
    position: fixed;
    top: 0;
    transition: none;
  }

  // pinned
  &--pinned {
    opacity: 1;
    transform: translateY(0);
    transition: transform $transition-time;

    // .bs-logo {
    //   max-height: 60px;
    //   transition: max-height $transition-time;
    // }
    //
    // .bs-header__nav-toggle {
    //   height: 60px;
    //   transition: height $transition-time;
    // }
  }

  &--unpinned {
    transform: translateY(-100%);
    transition: transform $transition-time;
  }

  // header com fundo transparente
  &--transparent {
    background-color: transparent;

    #{$i}__logo {

      .bs-logo path {
        fill: $c-white;
      }
    }

    &#{$i} {
      box-shadow: none;
    }

    #{$i}__main {
      background-color: transparent;
    }

    #{$i}__top {
      background-color: rgba(0, 0, 0, 0.25);
      border: 0;
    }

    #{$i}__nav-toggle .bs-hamburger {

      span,
      span::before,
      span::after {
        background-color: $c-white;
      }
    }


    .bs-accessibility-menu__button,
    .bs-top-menu__link,
    .bs-dropdown-user__info-text {
      color: $c-white;
    }

    .bs-accessibility-menu__button {
      &:hover {
        background: $c-red;
      }
    }

    .bs-dropdown-menu {

      &__trigger  {

        &:hover {
          background: transparent;
        }

        .icon {
          color: $c-white;
        }
      }
    }

    .bs-top-menu {
      &__link {
        &:hover {
          background: $c-red;
        }
      }

      &__dropdown .bs-top-menu__link {
        color: $c-black;

        &:hover {
          background: $c-gray-lighter;
        }
      }
    }

    .bs-social {
      &__link {
        &:hover {
          background: $c-red;
        }
      }

      &__icon {
        color: $c-white;
      }
    }
  }

  // header com fundo transparente
  &--transparent {
    background: transparent;
    box-shadow: none;
    color: $c-white;
    left: 0;
    position: fixed;
    top: 0;
    transition: $transition-time;

    .bs-header__main {
      background-color: transparent;
      border-color: transparent;
    }

    .bs-header__top {
      background-color: rgba($c-black, .1);
    }

    .bs-top-menu__list > .bs-top-menu__item > .bs-top-menu__link,
    .bs-top-menu__list > .bs-top-menu__item > .bs-top-menu__link {
      background-color: transparent;
      color: $c-white;

      &:hover,
      &:focus {
        background-color: rgba($c-white, .1);
        color: $c-white;
      }
    }

    .bs-top-menu__list > .bs-top-menu__item:hover > .bs-top-menu__link {
      background-color: rgba($c-white, .1);
    }

    .bs-social__link,
    .bs-social__icon,
    .bs-accessibility-menu__button {
      color: $c-white;
      transition: color $transition-time;

      &:hover,
      &:focus {
        background-color: rgba($c-white, .1);
      }
    }

    .bs-hamburger span,
    .bs-hamburger span::after,
    .bs-hamburger span::before {
      background: $c-white;
      transition: background $transition-time;
    }

    img.bs-logo {
      display: none;

      &--white {
        display: block;
      }
    }

    .bs-logo__text {
      fill: $c-white;
      transition: fill $transition-time;
    }

    .bs-logo {
      path {
        fill: $c-white;
      }
    }

    .bs-header__top,
    .bs-header__nav-toggle,
    .bs-search__form {
      border-color: transparent;
      transition: border $transition-time;
    }

    .bs-search__form {
      background-color: rgba($c-black, .2);
      transition: $transition-time;

      @include media(">=desktop") {
        background-color: transparent;
      }
    }

    .bs-search__field,
    .bs-search__button {
      box-shadow: none;
      transition: $transition-time;

      @include media(">=desktop") {
        background-color: rgba($c-white, .2);
        color: $c-white;

        &:hover,
        &:focus {
          background-color: rgba($c-white, .4);
        }

        @include placeholder() {
          color: $c-white;
        };
      }

      &:hover,
      &:focus {

      }

    }

    .bs-search__button {
      border-color: transparent;
      margin-left: 1px;

      @include media("<desktop") {
        color: $c-white;
      }
    }

    .bs-search--open .bs-search__button {
      @include media("<desktop") {
        background-color: rgba($c-black, .2);
      }
    }

    .bs-search:not(.bs-search--open) .bs-search__button {
    }

    .bs-header__login .bs-button {
      @include buttonDefaults();
      @include button(ghost, whites);
      transition: $transition-time;
    }
  }

  &--search-is-open {
    @include media("<desktop") {
      background: rgba($c-black, .5);
      height: calc(100vh);
      overflow: auto;
      top: 0;

      .bs-header__nav-toggle .bs-hamburger span,
      .bs-header__nav-toggle .bs-hamburger span::before,
      .bs-header__nav-toggle .bs-hamburger span::after {
        background-color: $c-black;
      }

      .bs-header__logo .bs-logo path {
        fill: $c-red;
      }

      img.bs-logo {
        display: block;
      }

      img.bs-logo--white {
        display: none;
      }

      img.bs-logo--white {
        display: none;
      }

      .bs-header__main {
        background: transparent;

        .bs-container {
          background: $c-white;
        }
      }

      .bs-search__dropdown {
        margin-bottom: 30vh;
      }
    }
  }

  // header sem campo de busca, menu hamburger
  &--logado {
    .bs-header__main .bs-container {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .bs-header__nav {
      // margin-right: auto;
    }

    .bs-header__logo {
      border-bottom: 1px solid $c-gray-light;
      justify-content: center;
      order: -1;
      width: 100%;

      @include media(">=tablet") {
        border: 0;
        order: 0;
        width: auto;
      }
    }

    &.bs-search--open .bs-search__form {
      top: 120px;
    }
  }

  &--static {
    position: relative;
  }
}
