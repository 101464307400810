//-------------------------------
// Media
//-------------------------------

.is-ie-11 {
  .bs-media {
    &__list--custom {
      .flickity-slider {
        height: auto;
      }
    }
  }
}

.bs-media {
  $i: &;

  position: relative;

  &__list--custom {

    .flickity-slider {
      display: flex;
      align-items: center;
    }

    .bs-media__text,
    .bs-media__figure {
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }

    .bs-is-hidden + .bs-media__text {
      -webkit-flex-basis: 100%!important;
      -ms-flex-preferred-size: 100%!important;
      flex-basis: 100%!important;
      max-width: 100%!important;
    }

    .bs-media__arrow-next,
    .bs-media__arrow-prev {
      display: flex !important;
      font-size: 45px !important;
      top: -20px !important;
    }

    .bs-media__arrow-next {
      right: -20px !important;
    }

    .bs-media__arrow-prev {
      left: -20px !important;
    }

    .bs-media__list--linear-prev.is-draggable .flickity-viewport::before {
      background: rgb(244,244,246) !important;
      background: linear-gradient(270deg, rgba(244,244,246,0) 0%, rgba(244,244,246,0.7934524151457458) 40%, rgba(244,244,246,1) 70%) !important;
      content: "";
      // height: 120% !important;
      // top: -10px;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 80px;
      z-index: 1;
    }

    .bs-media__list--linear-next.is-draggable .flickity-viewport::after {
      background: rgb(244,244,246) !important;
      background: linear-gradient(90deg, rgba(244,244,246,0) 0%, rgba(244,244,246,0.7934524151457458) 40%, rgba(244,244,246,1) 70%) !important;
      content: "";
      // height: 120% !important;
      // top: -10px;
      right: 0;
      pointer-events: none;
      position: absolute;
      width: 80px;
      z-index: 1;
    }

    .bs-media__item {
      .bs-media__box {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include media(">=desktop") {
      .bs-media__text,
      .bs-media__figure {
        border: 1px solid transparent;
      }

      .bs-media__item {
        .bs-media__box {
          //min-height: 220px;
          height: 315px;
        }
      }
    }

    @include media(">=tablet") {
      .bs-media__text,
      .bs-media__figure {
        -webkit-flex-basis: 50% !important;
        -ms-flex-preferred-size: 50% !important;
        flex-basis: 50% !important;
        max-width: 50% !important;
      }
      .bs-media__text,
      .bs-media__figure {
        border: 1px solid transparent;
      }

      .bs-media__item {
        .bs-media__box {
          min-height: 265px;
        }
      }

    }

    @include media("<tablet") {
      .bs-media__item {
        height: 400px;
        .bs-media__box {
          min-height: 330px;
        }
      }
    }

  }


  // Lista que agrupa os itens
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;

    // ?
    &--vertical {
      margin-left: 0;
      margin-right: 0;

      .bs-media__item {
        margin-bottom: (2 * $grid-gutter);
      }

      .bs-media__text {
        padding-bottom: 0;
      }
    }
  }

  // Cada media item
  &__item {
    @include flex-basis(100%);
    display: flex;
    padding: $grid-gutter;
    transition: background-color $transition-time;

    @include media(">=tablet") {
      padding: ($grid-gutter * 2) $grid-gutter;
    }

    &:only-child {
      padding: 0 $grid-gutter;
    }
  }

  // Wrapper interno do item
  &__box {
    display: block;
    width: 100%;
    // Se o item for clicável, o media__box
    // deve ser marcado como âncora

    @at-root {
      a#{&} {
        cursor: pointer;
      }
    }

    &:focus {
      @include focus($c-gray-dark, -5)
    }
  }

  // Wrapper de figure (pode conter imagem, ícone ou vídeo)
  // Obs: Não necessariamente deve ter a tag figure, foi apenas
  // o nome escolhido pra classe, para identificar a mídia em si,
  // que pode ser uma imagem ou um ícone (ou até mesmo vídeos,
  // futuramente)
  &__figure {
    position: relative;

    // Agrupa os sources de imagens responsivas
    picture {
      display: block;
    }

    // classificação indicativa
    .bs-media__class-indi {
      position: absolute;
      bottom: $grid-gutter;
      left: $grid-gutter;
    }

    // A imagem
    img {
      display: block;
      width: 100%;

      &.bs-not-loaded {
        display: none;
      }
    }

    // ?
    &--cover {

      picture {
        height: 100%;
      }

      img {
        @include object-fit(cover);
        display: block;
        height: 100%;
        max-width: auto;
        width: 100%;
      }
    }
  }

  // Media Wrapper - wrap images, videos and other media
  &-wrapper {
    //&--image {} // default
    // Icons (fonticon, svg)

    &--image-play {

      position: relative;

      .icon {
        position: absolute;
        color: $c-white;
        font-size: 75px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &--icon {
      text-align: center;

      .icon {
        align-items: center;
        color: $c-primary;
        display: inline-flex;
        font-size: rem(60);
        justify-content: center;
      }
    }


    // Video
    // Ex: https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
    &--video {
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      width: 100%;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  // Wrapper de texto
  &__text {
    // display: flex;
    // flex-direction: column;
    padding: {
      bottom: 0;
      top: $grid-gutter;
    }
    // height: 100%;

    // ?
    &--center {
      text-align: center;
    }
  }

  // Título do item (hierarquia default: 3)
  &__title {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(20);
    font-weight: fw("bold");
    line-height: 1.3;
    margin-bottom: $grid-gutter;
    word-break: break-word;
  }

  &__title--link {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(20);
    font-weight: fw("bold");
    line-height: 1.3;
    margin-bottom: $grid-gutter;
    word-break: break-word;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
  }

  // Subtítulo
  // Deprecated: usar título ou descrição
  &__subtitle {
    color: purple;
  }

  // Texto descritivo
  &__description {
    color: $c-gray-darker;
    // flex: 1 1 auto;
    font-family: $font-secondary;
    font-size: rem(16);
    font-weight: fw("light");
    line-height: 1.5;
    margin-bottom: $grid-gutter;
    word-break: break-word;

    p:not(:last-child) {
      margin-bottom: $grid-gutter;
    }


    // Texto pequeno
    // Deprecated: usar metadados
    @at-root {
      small#{&} {
        color: purple;
      }
    }

    // Texto destacado
    &--highlight {
      color: $c-primary;

      font: {
        family: $font-title;
        weight: fw("bold");
      }
    }

    b,
    strong {
      color: $c-gray-darker;
    }
  }

  // Metadados
  // Texto pequeno entre título e descrição com
  // informações (metadados) sobre o item
  &__meta {
    color: $c-gray-dark;
    display: flex;
    flex-wrap: wrap;
    font-size: rem(12);
    line-height: 1.6;
    margin-bottom: $grid-gutter;
    margin-top: -5px;

    // filhos de meta devem ser inline-block para não quebrarem independentes
    & > * {
      display: inline-block;

      // Insere um pipe entre os filhos de meta
      &::after {
        color: $c-gray-dark;
        content: "|";
        display: inline-block;
        margin: 0 4px;
      }

      // remove o pipe do último item
      &:last-child::after {
        content: "";
      }
    }
  }

  &__category {
    color: $c-primary;
    margin-right: 4px;

    @at-root {
      a#{&} {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  // Texto pequeno usado para datas ou texto com menos destaque
  // Deprecated: usar metadados
  &__detail {
    color: purple;
  }

  // Wrapper de botão
  &__button {
    // margin-top: auto;
  }

  //-------------------------------
  // Modifiers de componente
  //-------------------------------

  // Duas colunas
  &--col2 {
    .bs-media__item {
      @include media(">=tablet") {
        @include flex-basis(50%);
      }
    }
  }

  // Três colunas
  &--col3 {
    .bs-media__item {
      @include media(">=tablet") {
        @include flex-basis(33.333%);
      }
    }
  }

  // Quatro colunas
  &--col4 {
    .bs-media__item {
      @include media(">=tablet") {
        @include flex-basis(50%);
      }
      @include media(">=desktop") {
        @include flex-basis(25%);
      }
    }
  }

  // Seis colunas
  &--col6 {

    .bs-media__item {
      @include media(">=tablet") {
        @include flex-basis(33.333%);
      }

      @include media(">=wide") {
        @include flex-basis(16.666%);
      }
    }
  }

  // Se o item possui ícones
  &--icons {
    // Centraliza o texto
    .bs-media__text {
      text-align: center;
    }

    &:not(.bs-media--horizontal) {
      .bs-media__box {
        display: flex;
        flex-direction: column;
      }
    }
  }

  // Versão horizontal, com texto ao lado da figura
  &--horizontal {

    // Deixa a box flex com margem negativa,
    // tratando figura e texto como duas colunas
    .bs-media__box {
      align-items: flex-start;
      display: flex;
      // flex-wrap: wrap;
      margin: 0 (-$grid-gutter);
    }

    // Ajusta o espaçamento das colunas
    .bs-media__figure,
    .bs-media__text {
      padding: {
        bottom: 0;
        left: $grid-gutter;
        right: $grid-gutter;
        top: 0;
      };
    }

    // Aproxima o texto da figura e alinha
    // o texto à esquerda
    .bs-media__text {
      padding-left: 0;
      padding-right: $grid-gutter / 2;
      text-align: left;
    }
  }

  // Ajuste específico para o Media Image horizontal invertido
  &--horizontal#{&}--reverse {

    // Reseta os espaçamentos do texto
    .bs-media__text {
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
    }

    // Aproxima o texto da figura e alinha
    // o texto à esquerda
    .bs-media__figure {
      padding-left: 0;
      padding-right: $grid-gutter / 2;
    }
  }

  // Ajuste específico para o Media Image horizontal em 1 coluna
  &--horizontal:not(.bs-media--icons):not([class*="bs-media--col"]) {

    // Ajusta para que não fique horizontal no mobile
    @include media("<tablet") {

      // Reseta o box como um bloco, não flex
      .bs-media__box {
        display: block;
        margin: 0;
      }

      // Ajusta largura e padding dos elementos filhos
      // de box para que fiquem em 100%
      .bs-media__text,
      .bs-media__figure {
        @include flex-basis(100%);
        padding: 0;
      }

      // Ajusta distância entre figura e texto
      .bs-media__figure {
        margin-bottom: $grid-gutter;
      }
    }

    // Ajusta o espaçamento entre imagem e texto, que
    // nos demais medias horizontais é menor
    @include media(">=tablet") {

      // Reseta o box como um bloco, não flex
      .bs-media__item {
        padding: ($grid-gutter * 4) $grid-gutter;

        &:only-child {
          padding: 0 $grid-gutter;
        }
      }

      .bs-media__text,
      .bs-media__figure {
        padding-left: $grid-gutter;
        padding-right: $grid-gutter;
      }

    }
  }

  // Ajuste específico para o Media Image horizontal estilo card
  &--horizontal#{&}--card:not(.bs-media--icons) {
    .bs-media__box {
      @include media(">=tablet") {
        align-items: stretch;
        margin: 0;
        //height: 380px;
      }
    }

    .bs-media__figure {
      @include media(">=tablet") {
        margin: {
          bottom: -$grid-gutter;
          left: -$grid-gutter;
          right: $grid-gutter;
          top: -$grid-gutter;
        };
        padding: 0;

        .bs-media__class-indi {
          padding-left: $grid-gutter;
          padding-bottom: $grid-gutter;
        }
      }

      picture {
        @include media(">=tablet") {
          margin: 0;
        }
      }
    }

    .bs-media__text {
      @include media(">=tablet") {
        align-self: center;
      }
    }
  }

  // Ajuste específico para o Media Image horizontal estilo card em 1 coluna
  &--horizontal#{&}--card:not(.bs-media--icons):not([class*="bs-media--col"]) {
    .bs-media__box {
      @include media(">=tablet") {
        // height: 380px;
      }
    }
  }

  // Ajuste específico para o Media Image horizontal em 1 coluna estilo card invertido
  &--horizontal#{&}--reverse#{&}--card:not(.bs-media--icons):not([class*="bs-media--col"]) {
    .bs-media__figure {
      @include media(">=tablet") {
        margin: {
          left: $grid-gutter;
          right: -$grid-gutter;
        };
      }
    }
  }

  // Ajuste específico para o Media Image horizontal em 2 colunas
  &--horizontal:not(.bs-media--icons)[class*="bs-media--col"][class*="bs-media--image-prop"] {

    // Ajusta a proporção da largura de figura
    // e texto em 50%/50% por padrão
    .bs-media__figure,
    .bs-media__text {
      @include flex-basis(50%);
    }
  }

  // ?
  &:not(#{&}--horizontal):not([class*="bs-media--col"]) {
    .bs-media__text {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin: 0 auto;
      max-width: 700px;
      width: 100%;

    }
  }

  // Inverte texto e figura, quando horizontal, de forma alternada
  &--alternate {
    .bs-media__item:nth-child(even) {
      .bs-media__box {
        flex-direction: row-reverse;
      }
    }
  }

  &--alternate#{&}--reverse {
    .bs-media__item:nth-child(even) {
      .bs-media__box {
        flex-direction: row;
      }
    }
  }

  // Inverte texto e figura, quando horizontal
  &--reverse {
    .bs-media__box {
      flex-direction: row-reverse;
    }
  }

  // Ajuste específico para reverse em medias 1 coluna
  &--reverse:not([class*="bs-media--col"]) {

    // Remove o reverse no mobile
    .bs-media__box {
      flex-direction: row;

      // Adiciona o reverse novamente, a partir de tablet
      @include media(">=tablet") {
        flex-direction: row-reverse;
      }
    }
  }

  // Transforma o item em um card
  &--card {

    // Estiliza o item como um card
    .bs-media__box {
      background-color: $c-white;
      border: {
        color: $c-gray-light;
        style: solid;
        radius: 5px;
        width: 1px;
      };
      box-shadow: 0 5px 15px 0 rgba($c-black, 0.1);
      // display: flex;
      // flex-direction: column;
      overflow: hidden;
      padding: $grid-gutter;
      transition: .3s;

      // Esconde o botão no desktop
      .bs-media__button {
        @include media(">desktop") {
          display: block;
          opacity: 0;
          transition: opacity .6s;
          width: 100%;
        }
      }
    }

    // Faz o efeito hover do card,
    // se este tiver link
    a.bs-media__box:focus,
    a.bs-media__box:hover {
      box-shadow: 0 5px 30px 0 rgba($c-black, 0.3);
      transition: .3s;

      // Exibe o botão no hover do item
      .bs-media__button {
        @include media(">desktop") {
          opacity: 1;
          transition: opacity .6s;
        }
      }
    }

    // Ajusta a imagem para "grudar" nas bordas
    .bs-media-wrapper--image {
      margin: (-$grid-gutter - 1) (-$grid-gutter - 1) 0;

      img {
        width: 100%;
      }
    }

    .bs-media__button {
      margin-top: auto;
    }
  }

  &--card:not(.bs-media--horizontal) {

    .bs-media__box {
      display: flex;
      flex-direction: column;
    }

    .bs-media__figure {
      flex: 0 1 auto;
    }

    .bs-media__text {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      // height: 100%;
    }
  }


  // Alinha verticalmente figura e texto ao centro
  &--text-middle {

    .bs-media__box {
      align-items: center;
    }

    .bs-media__text {
      padding: {
        bottom: 0;
        top: 0;
      };
    }
  }


  // linha horizontal entre "linhas" de media
  &--hr {

    .bs-media__list {
      border-bottom: 1px solid $c-gray-light;
    }

    .bs-media__item {
      border-top: 1px solid $c-gray-light;
      padding: {
        bottom: $grid-gutter * 3;
        top: $grid-gutter * 3;
      };
    }
  }

  // Uma versão menor do media
  // Ex: menu de notificações do header
  &--compact {}

  // Proporção da imagem, quando horizontal sem colunas, em relação ao texto
  &--image-prop {

    &-1-2 {
      .bs-media__figure,
      .bs-media__text {
        @include media(">=tablet") {
          @include flex-basis(50%);
        }
      }
    }

    &-1-3 {
      .bs-media__figure {
        @include media(">=tablet") {
          @include flex-basis(33.333%);
        }
      }

      .bs-media__text {
        @include media(">=tablet") {
          @include flex-basis(66.666%);
        }
      }
    }

    &-1-4 {
      .bs-media__figure {
        @include media(">=tablet") {
          @include flex-basis(25%);
        }
      }

      .bs-media__text {
        @include media(">=tablet") {
          @include flex-basis(75%);
        }
      }
    }

    &-3-1 {
      .bs-media__figure {
        @include media(">=tablet") {
          @include flex-basis(50%);
        }

        @include media(">=desktop") {
          @include flex-basis(66.666%);
        }
      }

      .bs-media__text {
        @include media(">=tablet") {
          @include flex-basis(50%);
        }

        @include media(">=desktop") {
          @include flex-basis(33.333%);
        }
      }
    }
  }

  // Variação para links com card
  &--list-button {
    &.bs-media__item {
      padding: $grid-gutter ($grid-gutter / 2);
    }

    .bs-media__box {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0;
      &:hover {
        border-color:  $c-primary;
      }

      .bs-media__button {
        opacity: 1;
        width: 100%;
      }
    }

    .bs-media__text {
      display: flex;
      padding: 0;
      width: 100%;
    }

    .bs-button-link {
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 50px;
      line-height: 1.2;
      padding: 4px $grid-gutter;
      transition: background .3s;
      white-space: normal;

      &:hover {
        background-color: $c-primary;
        color: $c-white;
      }

      .icon-seta-direita-b {
        display: none;
      }

      > span {
        pointer-events: none;
      }
    }

    .icon {
      font-size: 25px;
      pointer-events: none;
    }
  }

  &__date-period {
    color: $c-red;
    font-size: 14px;
    font-weight: 800;
    padding-bottom: 5px;
    text-transform: uppercase;

    @include media(">=tablet") {
      font-size: 16px;
    }
  }

  &__class-indi {
    display: flex;
    margin-right: 10px;

    &::after {
      display: none;
    }
  }

  &__slider {

    @for $item from 1 through 6 {

      &#{$i}--col#{$item} #{$i}__item {

        flex-basis: 80%;
        width: 80%;

        @include media(">=tablet") {
          flex-basis: 400px;
          width: 400px;
          max-width: 400px;
        }

        @include media(">=desktop") {
          flex-basis: 480px;
          width: 480px;
          max-width: 480px;
        }
      }
   }

   .flickity-viewport {
     overflow: initial;
   }

    .bs-loading {
      padding: 80px 0;
    }

    &--nav {
      padding-bottom: 15px;
      z-index: 1;

      #{$i}__list {
        .flickity-page-dots {
          display: none;
        }
      }

      .is-nav-selected::before {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        background-color: $c-white;
        z-index: 99999;
        bottom: -36px;
        transform: rotate(45deg);
        margin: 0 auto;
        display: block;
        left: 0;
        right: 0;
      }
    }

   &#{$i}--horizontal {
     color: #ccc;
    #{$i}__text {
      padding: 15px;
    }
   }

   #{$i}__list {
      display: block;
      overflow: hidden;
      position: relative;
      padding-top: 10px;
      padding-bottom: 40px;

      &:not(.flickity-enabled) {
        display: none;
      }


      &--linear-prev.is-draggable .flickity-viewport {
        position: relative;

        @include media(">=tablet") {
          &::before {
            background: linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 25%, rgba(255,255,255,0.78) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
            content: "";
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 60px;
            z-index: 1;

            @include media(">=tablet") {
              width: 120px;
            }
          }
        }
      }

      &--linear-next.is-draggable .flickity-viewport {
        position: relative;

        @include media(">=tablet") {
          &::after {
            background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 25%, rgba(255,255,255,0.78) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
            content: "";
            height: 100%;
            right: 0;
            pointer-events: none;
            position: absolute;
            width: 60px;
            z-index: 1;

            @include media(">=tablet") {
              width: 120px;
            }
          }
        }
      }
    }

    #{$i}__item {
      cursor: pointer;
      padding-bottom: 0px!important;
      padding-top: 0px!important;
    }

    // bs-media heritage ($i)
    #{$i} {

      &__arrow-prev,
      &__arrow-next {
        align-items: center;
        cursor: pointer;
        display: none;
        font-size: 26px;
        height: 100%;
        position: absolute;
        opacity: 1;
        top: -20px;
        padding: 20px 5px;

        .icon {
          color: $c-gray-dark;
        }

        @include media(">=tablet") {
          display: flex;
          font-size: 45px;
          top: -20px;
        }

        &:hover {
          opacity: 1;
        }

        &[disabled^="disabled"] {
          opacity: 0;
        }
      }

      &__arrow-prev {
        left: -10px;
        z-index: 1;

        @include media(">=tablet") {
          left: -30px;
        }

        @include media(">=larger") {
          left: -55px;
        }
      }

      &__arrow-next {
        right: -10px;
        z-index: 1;

        @include media(">=tablet") {
          right: -30px;
        }

        @include media(">=larger") {
          right: -55px;
        }
      }
    }

    #{$i}__box {
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }

    &#{$i}--horizontal:not(.bs-media--icons)[class*="bs-media--col"][class*="bs-media--image-prop"] {

      #{$i}__figure,
      #{$i}__text {

        flex-basis: 100%;
        max-width: 100%;

        @include media(">=desktop") {
          flex-basis: 50%;
          max-width: 50%;
        }
      }

      #{$i}__figure {
        margin: 0;
      }
    }

    #{$i}__description {
      height: 72px;
      overflow: hidden;
    }
  }

  // Ajuste específico para o Media Image horizontal estilo card
  &--card:not(.bs-media--icons):not(.bs-media__slider) {

    .bs-media__figure:not(.bs-media__figure--cover) {
      // flex: 1 0 auto;
      margin: -16px -16px 0;
      overflow: hidden;
      padding: 0;

      .bs-media-wrapper--image {
        margin: 0;
      }
    }
  }

  .flickity-page-dots {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    .dot {
      background: $c-gray;
      border-radius: 10px;
      cursor: pointer;
      height: 10px;
      margin: 5px;
      width: 10px;

      &.is-selected {
        background-color: $c-red;
      }
    }
  }

  &__nav {
    padding: 0;
    overflow: hidden;
    transition: .2s;
    opacity: 0;
    max-height: 0;

    &#{$i}--horizontal:not(.bs-media--icons):not([class*="bs-media--col"]) #{$i}__text {
      @include media(">=tablet") {
        padding: {
          bottom: 3 * $grid-gutter;
          top: 3 * $grid-gutter;
        }
      }
    }

    @include media(">=tablet") {
      display: block;
      // transition: 0.2s;
      // max-height: 0;
    }

    &--active {
      display: block;
      margin-top: -30px;
      padding: 30px 0;
      opacity: 1;
      max-height: 100%;

      @include media(">=tablet") {
        padding: 0;
        // max-height: 420px;
      }
    }

    &--close {
      color: $c-white;
      cursor: pointer;
      font-size: 30px;
      position: absolute;
      right: 15px;
      top: 40px;

      @include media(">=tablet") {
        display: block;
      }
    }

    #{$i}__title,
    #{$i}__description,
    #{$i}__category,
    #{$i}__date,
    #{$i}__meta,
    #{$i}__date-period {
      color: $c-white;

      & > * {
        &::after {
          color: $c-white;
        }
      }
    }

    .bs-button-link {
      color: $c-white;
    }

    #{$i}::before {
      background-color: $c-gray-lighter;
      content: "";
      height: 25px;
      left: 70px;
      transform: rotate(45deg);
      top: -12px;
      position: absolute;
      width: 25px;
      z-index: 1;
    }

    #{$i}__box {
      align-items: center;
    }

    #{$i}__list {
      display: block;
      overflow: hidden;
      margin: 0;
    }

    #{$i}__item {
      padding: 0 2px!important;
      flex-basis: 100%;
      width: 100%;
    }

    #{$i}__text {
      // padding: 60px 0;
    }
  }
}

.bs-container-fluid--slider {
  background-color: $c-red;
  margin-top: -17px;
}


.bs-card-slider {

  .bs-media {

    &__slider {

      margin-right: 15px;

      @include media(">=tablet") {
        margin-right: 0;
      }

      @for $item from 1 through 6 {

        &.bs-media--col#{$item} .bs-media__item {

          $width: 100%;

          @if $item >= 3 {
            padding: {
              left: $grid-gutter;
              right: 0;
            }
            $width: 27.5%;
          } @else {
            $width: 100%;
          }

          flex-basis: $width;
          width: $width;
          max-width: $width;


          @include media(">=tablet") {
            $width: 80% / $item;
            flex-basis: $width;
            width: $width;
            max-width: $width;
            padding: 0 $grid-gutter;
          }
        }
     }
    }
  }
}
