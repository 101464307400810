//-------------------------------
// Menu de Contexto
// Componente de menu no estilo de um tooltip
//-------------------------------
.bs-overflow-menu {
    background-color: $c-white;
    border: {
      color: $c-gray-light;
      radius: 4px;
      style: solid;
      width: 1px;
    }
    box-shadow: 0 2px 5px 0 rgba($c-black, 0.18);
    opacity: 0;
    overflow: hidden;
    position: absolute;
    // right: 0;
    // top: 100%;
    transition: opacity $transition-time, transform $transition-time;
    visibility: hidden;
    width: 180px;
    z-index: z("absolute");
  
    &::after,
    &::before {
      content: "";
      display: block;
      height: 0;
      position: absolute;
      // right: 15px;
      width: 0;
    }
  
    &::after {
      // border: {
      //   bottom: 7px solid $c-white;
      //   left: 5px solid transparent;
      //   right: 5px solid transparent;
      // }
      // top: -5px;
    }
  
    &::before {
      // border: {
      //   bottom: 7px solid $c-gray-light;
      //   left: 5px solid transparent;
      //   right: 5px solid transparent;
      // }
      // top: -7px;
    }

    &__options {
      // bottom: 15px;
      // position: absolute;
      // right: 3px;
      position: relative;
      width: 40px;
      margin-left: auto;
    }

    &__options-button {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 0;
      height: 40px;
      position: relative;
      width: 100%;
      z-index: z("absolute");
  
      span {
        background-color: $c-gray-dark;
        border-radius: 50%;
        display: block;
        height: 3px;
        margin: 0 auto;
        position: relative;
        transition: background-color $transition-time;
        width: 3px;
        transition-delay: .05s;
  
        &::after,
        &::before {
          background-color: $c-gray-dark;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          position: absolute;
          transition: background-color $transition-time;
          width: 3px;
        }
  
        &::after {
          bottom: -8px;
          transition-delay: .1s;
        }
  
        &::before {
          top: -8px;
        }
      }
  
      &:hover {
        span,
        span::after,
        span::before {
          background-color: $c-gray-darker;
        }
      }
  
      &:focus {
        outline-style: dotted;
        outline-width: 1px;
      }
  
    }
        
    &__item {
      border-bottom: 1px solid $c-gray-light;
  
      @for $i from 0 through 5 {
        &:nth-child(#{$i}) {
          .bs-overflow-menu__link {
            transition-delay: $i * 0.1s;
          }
        }
      }
  
      &:last-child {
        border: 0;
      }
  
      &:hover {
        background-color: $c-gray-lightest;
      }
  
      &--borderless {
        border: 0;
      }
    }
  
    &__link {
      color: $c-gray-darker;
      display: flex;
      font: {
        family: $font-title;
        size: rem(14);
        weight: fw("bold");
      }
      opacity: 0;
      padding: 10px $grid-gutter;
      transform: translateX(25px);
      transition: opacity $transition-long-time, transform $transition-time;

      .icon {
        font-size: 20px;
        margin-right: 5px;
      }
  
      &:focus {
        outline-style: dotted;
        outline-width: 1px;
        outline-offset: -4px;
      }
    }
  
    &--open {
      opacity: 1;
      visibility: visible;
  
  
      .bs-overflow-menu__link {
        opacity: 1;
        transform: translateX(0);
      }
    }
  
    // Posicionamento do menu
    // Ex: --down e --right
    // Menu será exibido abaixo e para a direita
    &--down {
      top: 40px;
  
      &::after {
        border: {
          bottom: 7px solid $c-white;
          left: 5px solid transparent;
          right: 5px solid transparent;
        }
        top: -5px;
      }
  
      &::before {
        border: {
          bottom: 7px solid $c-gray-light;
          left: 5px solid transparent;
          right: 5px solid transparent;
        }
        top: -7px;
      }
    }
  
    &--up {
      bottom: 100%;
  
      &::after {
        border: {
          left: 5px solid transparent;
          right: 5px solid transparent;
          top: 7px solid $c-white;
        }
        bottom: -5px;
      }
  
      &::before {
        border: {
          left: 5px solid transparent;
          right: 5px solid transparent;
          top: 7px solid $c-gray-light;
        }
        bottom: -7px;
      }
    }
  
    &--left {
      right: 0;
  
      &::after,
      &::before {
        right: 15px;
      }
    }
  
    &--right {
      left: 0;
  
      &::after,
      &::before {
        left: 15px;
      }
    }
  }
  