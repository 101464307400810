.bs-picker {

  &__list {
    display: none;
  }

  &__item {
    background: transparent;
    color: $c-gray-darker;
    font-size: 14px;
    font-weight: fw("bold");
    padding-bottom: 15px;

    &[disabled] {
      color: $c-gray;

      &:hover {
        color: $c-gray;
        cursor: default;
      }
    }

    a {
      font-weight: fw("bold");
    }

    &:hover,
    &:hover a {
      color: $c-primary;
    }
  }

  &__datepicker-button {
    pointer-events: none;
  }

  &__datepicker-input {
    font-size: 12px;
    cursor: pointer;
  }

  &--small {
    .lightpick__month {
      width: 250px;

      @include media(">=desktop") {
        width: 288px;
      }
    }
  }
}

.lightpick {
  box-shadow: 2px 4px 10px 1px rgba(202, 163, 163, 0.25);
  color: $c-gray-darker;
  font-family: $font-title;

  &__days-of-the-week {
    display: flex;
  }

  &__day-of-the-week {
    width: 40px;
  }

  &__days {
    display: flex;
    flex-wrap: wrap;
  }

  &__day {
    width: 34px;

    @include media(">=desktop") {
      width: 40px;
    }

    &.is-today {
      background-size: 100%;
      color: $c-primary-light;
    }

    &.is-in-range {
      background-color: $c-gray-lighter;

      &:hover {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(210, 212, 218, 1)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      pointer-events: initial;
    }

    &.is-start-date,
    &.is-end-date,
    &.is-start-date.is-in-range,
    &.is-end-date.is-in-range,
    &.is-start-date.is-in-range.is-flipped,
    &.is-end-date.is-in-range.is-flipped {
      background: $c-primary-dark;
      color: $c-white;

      &:hover {
        background: $c-primary;
      }
    }

  }

  &__inner {

    .bs-picker__list {
      display: block;
      width: 100%;
    }

    @include media(">=desktop") {
      display: flex;
    }
  }

  &__previous-action {
      left: 0;
  }
  &__next-action {
      right: 0;
  }
  &__previous-action,
  &__next-action {
      position: absolute;
      top: 0px;
  }
  &__month-title {
      width: 100%;
      text-align: center;
  }
  &__month {
      position: relative;
  }

  &__footer {
    display: block;
    border-top: 1px solid $c-gray-light;
    padding: 20px;
    width: 100%;

    @include media(">=desktop") {
      border: {
        left: 1px solid $c-gray-light;
        top: 0;
      }
      padding: 30px;
      width: 200px;
    }
  }

  &__apply-action {
    display: none;
  }
}

input[data-type="datepicker"]::-ms-clear {
  display: none;
}
