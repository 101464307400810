//-------------------------------
// Mosaico (cardápio)
// Componente para listar os produtos do Bradesco Seguros
//-------------------------------
.bs-mosaico {
  // Seção do componente
  background: linear-gradient(
    165deg,
    rgba(33,50,76,1) 0%,
    rgba(132,154,181,1) 32%,
    rgba(141,163,186,1) 36%,
    rgba(190,205,220,1) 45%,
    rgba(190,205,220,1) 55%,
    rgba(255,255,255,1) 70%
  );
  padding: (5 * $grid-gutter) 0;

  @include media(">=desktop") {
    background: url("../img/mosaico/fundo.jpg") top center/cover no-repeat;
  }

  // Lista que agrupa os itens
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 0 20px;
    position: relative;
  }

  // Cada item do mosaico
  &__item {
    @include flex-basis(calc(50% - 2px));
    height: 160px;
    margin: 1px;
    max-width: 50%;
    position: relative;
    transition: $ease-in-out;

    &:focus {
      outline-style: dotted;
      outline-width: 2px;
    }

    @include media (">=phone") {
      height: 200px;
    }

    @include media (">=tablet") {
      @include flex-basis(calc(25% - 2px));
      max-width: calc(25% - 2px);
    }

    @include media(">=desktop") {
      &:hover {

        .bs-mosaico__image {

          filter: brightness(102%);

          div {
            // filter: grayscale(0);
          }

          &::after {
            opacity: 0;
          }
        }

        &::before {
          opacity: 1;
          transform: scale3d(1, 1, 1);
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        }

        .bs-mosaico__title {
          i {
            transform: translateX(5px);
          }
        }
      }
    }

    @include media (">=wide") {
      height: 240px;
    }

    &::before {
      background: $c-primary;
      bottom: 0;
      content: "";
      display: block;
      height: 5px;
      left: 0;
      opacity: 0;
      position: absolute;
      transform: scale3d(0, 1, 1);
      transition: 0.2s;
      width: 100%;
      will-change: opacity;
      z-index: z("default");
    }
  }

  // Contém a imagem de fundo
  &__image {
    height: 100%;
    position: absolute;
    width: 100%;

    &::before {
      background: linear-gradient(0,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, .4) 40%,
        rgba(0, 0, 0, .1) 70%,
        rgba(0, 0, 0, 0) 90%
      );
      bottom: 0;
      content: "";
      display: block;
      height: 60%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    &::after {
      background-color: $c-blue;
      content: "";
      height: 100%;
      left: 0;
      opacity: .2;
      position: absolute;
      top: 0;
      transition: opacity $ease-in-out;
      width: 100%;
      will-change: opacity;
    }

    > div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      // filter: grayscale(100%);
      height: 100%;
      transition: filter $ease-in-out;
    }
  }

  // Conteúdo do item
  &__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
  }

  &__title {
    bottom: $grid-gutter;
    left: $grid-gutter;
    position: absolute;
    z-index: z("default");

    @include media(">=wide") {
      bottom: 20px;
      left: 20px;
    }

    h3 {
      color: $c-white;
      font-family: $font-title;
      font-size: rem(14);
      font-weight: fw("medium");
      line-height: 1.75;
      margin-bottom: 0;
      text-shadow: 0 1px 2px rgba($c-black, .6);

      @include media(">=tablet") {
        font-size: rem(16);
      }

      @include media(">=wide") {
        font-size: rem(22);
        line-height: 1.2;
      }
    }

    i {
      color: $c-white;
      display: none;
      font-size: rem(14);
      margin-left: 10px;
      transition: transform $ease-in-out;
      vertical-align: middle;

      @include media(">=tablet") {
        display: inline-block;
      }

      @include media(">=wide") {
        font-size: rem(16);
      }
    }
  }

  // Texto descritivo
  &__description {
    color: $c-gray-darker;
    font-family: $font-secondary;
    font-size: rem(14);
    font-weight: fw("light");
    line-height: 1.28;
    margin-bottom: $grid-gutter;
  }

  // Link para abrir o modal com mais serviços
  &__link {
    color: $c-gray-darker;
    display: block;
    font-size: rem(16);
    margin: 0 auto;
    //opacity: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    transform: translateY(15px);
    transition: opacity .4s ease-in-out .15s, transform .5s ease-in-out .15s;

    button {
      display: inline-block;
      font-weight: fw("bold");
      padding: 0;
      position: relative;
      vertical-align: baseline;
      zoom: 1;

      &:focus {
        outline-offset: -1px;
        outline-style: dotted;
        outline-width: 1px;
      }


      @include media(">=tablet") {
        padding: 0 20px;

        &::after,
        &::before {
          border-top: 1px solid $c-gray;
          content: "";
          display: block;
          position: absolute;
          top: 0.73em;
          width: 1000px;
        }

        &::before {
          right: 100%;
        }

        &::after {
          left: 100%;
        }
      }
    }

    span {
      color: $c-primary;
    }

    &--visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
