//-------------------------------
// Bs Box List
// Componente de box para listas e links, como menus laterais
// ou lista de telefones e outras informações de contato
//-------------------------------
.bs-box {
  $i: &;

  background-color: $c-white;
  border: {
    bottom: 1px solid $c-gray-light;
    left: 1px solid $c-gray-light;
    right: 1px solid $c-gray-light;
    top: 5px solid $c-primary;
    bottom-left: {
      radius: 5px;
    }
    bottom-right: {
      radius: 5px;
    }
  }
  // Comentado por conta do SHAME abaixo
  // margin-bottom: $grid-gutter;
  width: 100%;
  
  &:only-child { 
    margin-bottom: 0;
  }
  // SHAME - evita quebra do componente sticky navegação
  // SHAME - causada por divs vazias que influenciam na altura do mesmo
  + * > * {
    margin-top: $grid-gutter;
  }

  // Seção do componente
  &__header {
    border-bottom: 1px solid $c-gray-light;
    padding: $grid-gutter;
  }

  // Seção do componente
  &__title {
    font-family: $font-title;
    font-size: 1.38rem;
    font-weight: fw("regular");
    line-height: 1.3;
    margin-bottom: 10px;
    min-height: 30px;

    &:empty {
      display: none;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  // Seção do componente
  &__subtitle {}

  // Seção do componente
  &__content {

    &#{&}--active {
      border-top-color: $c-gray-light;
      opacity: 1;
    }
  }

  // Seção do componente Menu mobile
  &__menu {
    border-bottom: 1px solid $c-gray-light;
    cursor: pointer;
    display: flex;
    font: {
      size: 18px;
      weight: 900;
    }
    justify-content: space-between;
    padding: 20px $grid-gutter;
    width: 100%;

    &:focus {
      outline: 1px dashed $c-gray-darker;
      outline-offset: -5px;
    }

    &::after {
      content: "\e91b";
      display: block;
      font-family: "bradesco_v2";
      font-size: 25px;
      transform: rotate(0);
      transition: 0.3s ease-in-out;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // seção content com menu existente
  &__menu + &__header {
    display: none;
  }

  // Lista de itens
  &__list {}

  // Cada item
  &__item {
    border-bottom: 1px solid $c-gray-light;
    color: $c-primary;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("medium");
    line-height: 1.5;
    white-space: normal;
    width: 100%;

    &:last-child {
      .bs-box__link {
        border-bottom: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  // Texto
  // Usado para uma lista de textos
  &__text {
    padding: $grid-gutter;

    &-title {
      display: block;

      a {
        color: $c-primary;
        text-decoration: underline;

        &:hover,
        &:active {
          text-decoration: none;
          color: $c-primary-dark;
        }
      }
    }
  }

  // Links
  // Usado para uma lista de links
  &__link {
    align-items: center;
    background-color: $c-white;
    color: $c-primary;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.3;
    min-height: 60px;
    padding: {
      bottom: 18px;
      left: $grid-gutter;
      right: (3 * $grid-gutter);
      top: 18px;
    };
    position: relative;
    transition: background $transition-time;
    white-space: normal;
    width: 100%;

    &:focus {
      outline: 1px dashed $c-gray-darker;
      outline-offset: -5px;
    }

    .icon {
      color: $c-primary;
      font-size: rem(12);
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      &-nav-exportar {
        font-size: rem(14);
        font-weight: bold;
      }
    }

    &--active,
    &:hover {
      background-color: $c-gray-lighter;
      transition: background $transition-time;
    }

    &--back {
      color: $c-text;
      overflow: hidden;

      &:hover {
        .bs-box__link-label {
          padding-left: 25px;
          transition: .3s;
        }
      }

      .icon {
        right: auto;
      }

      .bs-box__link-label {
        padding-left: 20px;
        transition: .3s;
      }
    }
  }

  &__icon {
    color: $c-primary;
    font-size: 24px;
    margin-right: $grid-gutter;

    & + .bs-box__link-label {
      color: $c-gray-darker;
    }
  }

  &--dropdown {
    position: relative;

    @include media(">=tablet") {
      max-width: 340px;
    }

    .bs-box__content {
      background-color: $c-white;
      box-shadow: 0 8px 10px 3px rgba($c-black, 0);
      height: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
      transition: 0.3s ease-in-out;
      visibility: hidden;
      width: 100%;
      z-index: z("absolute");
    }

    &[data-dropdown-bp="xs"] {
      @include media(">=tablet") {
        max-width: 100%;

        .bs-box__menu {
          display: none;

          & + .bs-box__header {
            display: block;
          }
        }

        .bs-box__content {
          height: auto;
          overflow: auto;
          position: static;
          visibility: visible;
        }
      }
    }

    &[data-dropdown-bp="sm"] {
      @include media(">=desktop") {
        max-width: 100%;

        .bs-box__menu {
          display: none;

          & + .bs-box__header {
            display: block;
          }
        }

        .bs-box__content {
          height: auto;
          overflow: auto;
          position: static;
          visibility: visible;
        }
      }
    }

    &[data-dropdown-bp="md"] {
      @include media(">=wide") {
        max-width: 100%;

        .bs-box__menu {
          display: none;

          & + .bs-box__header {
            display: block;
          }
        }

        .bs-box__content {
          height: auto;
          overflow: auto;
          position: static;
          visibility: visible;
        }
      }
    }

    // nothing to do
    &[data-dropdown-bp="lg"] {}
  }

  &--is-expanded {

    .bs-box__menu::after {
      transform: rotate(-180deg);
    }

    .bs-box__content {
      box-shadow: 0 8px 10px 3px rgba($c-black, .3s);
      visibility: visible;
    }
  }
}
