//-------------------------------
// Banner Form [deprecated]
// Área para exibir um banner e um formulário horizontalmente
//
// Depreciado por ser um componente desnessário, que pode ser susbtituído pela
// grid do flexgrid, criando apenas um template diferente ao invés de um componente.
//-------------------------------
.bs-banner-form {
  // padding: 0 0 ($grid-gutter * 2);
  //
  // @include media(">=tablet") {
  //   padding: ($grid-gutter * 4) 0;
  // }

  @include media(">=tablet") {
    // align-items: center;
    display: flex;
    height: 600px;
  }

  &__title {
    margin-bottom: ($grid-gutter * 2);
  }

  &__image {
    height: 280px;
    margin-bottom: $grid-gutter;

    @include media(">=tablet") {
      @include flex-basis(50%);
      height: 600px;
      margin-bottom: 0;
    }

    @include media(">=desktop") {
      @include flex-basis(66.666%);
    }

    .bs-banner__figure {
      height: 100%;
    }
  }

  &__container {
    width: 100%;

    @include media(">=tablet") {
      @include grid-container(2);
      height: 100%;
      overflow: auto;
      padding-left: 45px;
    }

    @include media(">=desktop") {
      @include grid-container(3); 
    }
  }

  &__form {
    align-self: center;
    padding: {
      bottom: 100px;
      left: ($grid-gutter * 2);
      right: ($grid-gutter * 2);
      top: ($grid-gutter * 2);
    }
    width: 100%;

    @include media(">=tablet") {
      padding: ($grid-gutter * 4) $grid-gutter;
    }

    &:first-child:target {
      display: block;
    }

    &:first-child,
    &:first-child:target ~ .bs-banner-form__form {
      display: none;
    }
  }
}
