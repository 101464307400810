.bs-contact-list {
  &__item {
    margin-bottom: 15px;
  }

  &__link {

    &:hover {
      color: $c-red;

      span {
        text-decoration: underline;
      }
    }

    &::before {
      color: $c-red;
      content: "\ea7c";
      font-family: "bradesco_v2";
      font-size: 20px;
      margin-right: 5px;
      vertical-align: middle;
    }

    &[href^="mailto"]::before {
      content: "\e9e2";
    }

    &[href^="tel"]::before {
      content: "\e9e6";
    }

    &[href*="api.whatsapp.com"]::before,
    &[href*="wa.me"]::before {
      content: "\e956";
    }

  }
}
