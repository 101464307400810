//-------------------------------
// Estilos base
//-------------------------------

// Document
html,
body {
  background-color: $c-white;
  color: $c-gray-darker;
  font: {
    family: $font-text, $font-default;
    size: 16px;
    weight: fw("regular");
  }
  height: 100%;
  position: relative;
}
 
// Main
main {}
section {}

// Heading
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-title;
  font-weight: fw("bold");
}
h3 {
  font-size: 1.17rem;
}
h4 {
  font-size: 0.89rem;
}

// Anchors
a {
  color: $c-gray-darker;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    @include focus($c-gray-dark, 2);
    color: inherit;
    text-decoration: none;
  }
}

// Buttons
button {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  text-align: left;

  &:focus {
    @include focus($c-gray-dark, -5);
    text-decoration: none;
  }
}

// Text
p {
  font-size: 1.068rem;
  margin: 0;
}

// Media
img {
  height: auto;
  max-width: 100%;
}
iframe {
  max-width: 100%;
}

// Horizontal Rule
hr {
  border-bottom: 1px solid $c-gray;
  margin: ($grid-gutter * 2) 0;

  &.bs-m-0 {
    margin: 0;
  }
}

// Forms
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
  height: 0;
  width : 0;
}

.wl-element-right {
  @include sr-only();
}

.bs-container-old {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  padding-bottom: (4 * $grid-gutter);
  padding-top: (4 * $grid-gutter);
  position: relative;
  text-align: center;

  @include media(">=tablet") {
    padding-bottom: (8 * $grid-gutter);
    padding-top: (8 * $grid-gutter);
  }

  @include grid-container();
}

.bs-overlay {
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity $ease-in-out, visibility $ease-in-out;
  visibility: hidden;
  width: 100vw;
  z-index: z("overlay");

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &--visible,
  &--nav {
    background: rgba($c-black, .6);
  }
}

.bs-flex {
  display: flex;
  margin: 0 (-$grid-gutter);

  .bs-col,
  &__col {
    flex: 1 1 auto;
    padding: 0 $grid-gutter;
  }
}

// Altura maxima quando houver sticky
.bs-js-aside {
  height: 100%;
}
