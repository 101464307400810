//-------------------------------
// TextType
// Estiliza e faz o efeito da barra vertical "piscando", que
// simula o efeito de um campo ativo para digitação
//-------------------------------
.bs-texttype {
  border-color: inherit;
  word-wrap: break-word;

  &::after {
    animation: blink 0.7s infinite;
    border-right: 4px solid;
    content: "";
    margin-left: 4px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
