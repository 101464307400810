//
// Hamburger @mixin
// Add style and hover/active effect for an hamburger menu
//
// Reference:
// #
//
// @example
// .hamburger-menu {
//   @include hamburger();
// }
// .hamburger-menu--open {
//   @include hamburger(open);
// }
//
@mixin hamburger( $state: closed ) {
  background-color: transparent;
  display: inline-block;
  height: 24px;
  position: relative;
  width: 32px;
  // z-index: z("overlay");

  span {
    background-color: $c-gray-darker;
    border-radius: 4px;
    bottom: 0;
    display: block;
    height: 4px;
    margin-top: -2px;
    position: absolute;
    top: auto;
    transition: .13s cubic-bezier(.55, .055, .675, .19), background-color .3s ease;
    width: 100%;

    &::before,
    &::after {
      background-color: $c-gray-darker;
      border-radius: 4px;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::before {
      top: -10px;
      transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19), background-color .3s ease;
    }

    &::after {
      top: -20px;
      transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear, background-color .3s ease;
    }
  }

  span {
    @if ( $state == open ) {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1), background-color .3s ease;

      &::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s, background-color .3s ease;
      }

      &::after {
        opacity: 0;
        top: 0;
        transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s, background-color .3s ease;
      }
    }
  }
}
