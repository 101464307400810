.pa-hospitals-table {

  a {
    color: $c-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  &__address {
    color: $c-gray-dark;

    a {
      font-size: 16px;

      .icon {
        font-size: 18px;
      }
    }
  }

  .ans-icon {
    display: block;
    height: 20px;
    margin-bottom: 10px;
    margin-right: 15px;
    margin-top: 10px;
    width: 20px;
  }

  .icon {
    color: $c-primary;
    font-size: 25px;
    margin-right: 10px;
  }
}
