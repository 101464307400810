//-------------------------------
// Divisor vertical [deprecated]
// Gera uma linha/borda vertical para dividir colunas com uma
// seta apontando para direita ou esquerda
//
// Depreciado pois não há previsão de uso no formato atual.
//-------------------------------
.bs-divider {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;

  &::after {
    background-color: $c-gray-lighter;
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    transform: rotate(-45deg);
    width: 20px;
  }

  &--left {
    border: {
      top: 1px solid $c-gray;
    }
    padding-top: 70px;

    &::after {
      border: {
        top: 1px solid $c-gray;
        right: 1px solid $c-gray;
      }
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -11px;
    }

    @include media(">=tablet") {
      border: {
        left: 1px solid $c-gray;
        top: 0;
      }
      padding: {
        left: 70px;
        top: 0;
      }

      &::after {
        border: {
          left: 1px solid $c-gray;
          top: 1px solid $c-gray;
          right: 0;
        }
        left: -11px;
        margin: 0;
        right: inherit;
        top: 10%;
      }
    }
  }
}
