/////////////////////////////////////
// Familia de Fonte: Bradesco Sans
//

// Bold
@font-face {
  font-family:  "Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Bold-web.ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Bold.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Bold.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

// Bold Italic
@font-face {
  font-family: "Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-BoldItalic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-BoldItalic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-BoldItalic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

// Condensed
@font-face {
  font-family: "Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Condensed-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Condensed.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Condensed.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Condensed.woff2") format("woff2");
  font-weight: 400;
  font-style: condensed;
}

// Light
@font-face {
  font-family: "Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Light-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Light.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Light.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

// Light Italic
@font-face {
  font-family: "Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-LightItalic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-LightItalic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-LightItalic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

// Medium
@font-face { 
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Medium-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Medium.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Medium.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Medium.woff2") format("woff2");
  font-weight:500;
  font-style:normal
}

// Medium Italic
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-MediumItalic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-MediumItalic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-MediumItalic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-MediumItalic.woff2") format("woff2");
  font-weight:500;
  font-style:italic
}

// Regular
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Regular-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Regular.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Regular.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Regular.woff2") format("woff2");
  font-weight:400;
  font-style:normal
}

// Regular Italic
@font-face {
  font-family: "Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Italic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Italic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Italic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

// Semi Bold
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-SemiBold-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-SemiBold.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-SemiBold.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-SemiBold.woff2") format("woff2");
  font-weight:600;
  font-style:normal
}

// Semi Bold Italic
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-SemiBoldItalic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-SemiBoldItalic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-SemiBoldItalic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-SemiBoldItalic.woff2") format("woff2");
  font-weight:600;
  font-style:italic
}

// Thin
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-Thin-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-Thin.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-Thin.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-Thin.woff2") format("woff2");
  font-weight:100;
  font-style:normal
}

// Thin Italic
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-ThinItalic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-ThinItalic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-ThinItalic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-ThinItalic.woff2") format("woff2");
  font-weight:100;
  font-style:italic
}

// XBold
@font-face {
  font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-XBold-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-XBold.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-XBold.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-XBold.woff2") format("woff2");
  font-weight:800;
  font-style:normal
}

// XBold Italic
@font-face {font-family:"Bradesco";
  src:  url("../fonts/bradescoSans/TTF-WEB/BradescoSans-XBoldItalic-web.ttf") format("ttf"),
        url("../fonts/bradescoSans/EOT/BradescoSans-XBoldItalic.eot") format("eot"),
        url("../fonts/bradescoSans/WOFF/BradescoSans-XBoldItalic.woff") format("woff"),
        url("../fonts/bradescoSans/WOFF2/BradescoSans-XBoldItalic.woff2") format("woff2");
  font-weight:800;
  font-style:italic
}