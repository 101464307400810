.bs-phones {
  &__list {}

  &__item {
    margin-bottom: $grid-gutter;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__number {
    display: block;
    font-family: $font-title;
    font-size: rem(22);
    font-weight: fw("light");
  }

  &__description {}
}
