//-------------------------------
// Serviços (old) [deprecated]
// Lista de serviços
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.comp-servicos {
  background-color: $c-gray-lighter;

  .bs-card {

    &__figure {
      background-color: $c-white;
    }
  }
}
