.lightpick {
    background-color: $c-white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba($c-black, 0.25);
    color: $c-black;
    line-height: 1.125em;
    position: absolute;
    z-index: 99999;
}

.lightpick--inlined {
    position: static;
}

.lightpick,
.lightpick *,
.lightpick::after,
.lightpick::before {
    box-sizing: border-box;
}

.lightpick.is-hidden {
    display: none;
}

.lightpick__months {
    display: grid;
    background-color: $c-gray-lightest;
    grid-template-columns: auto;
    grid-gap: 1px;
}

.lightpick--2-columns .lightpick__months {
    grid-template-columns: auto auto;
}

.lightpick--3-columns .lightpick__months {
    grid-template-columns: auto auto auto;
}

.lightpick--4-columns .lightpick__months {
    grid-template-columns: auto auto auto auto;
}

.lightpick--5-columns .lightpick__months {
    grid-template-columns: auto auto auto auto auto;
}

.lightpick__month {
    background-color: $c-white;
    padding: 4px;
    width: 288px;
}

.lightpick__month-title-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.lightpick__month-title {
    border-radius: 4px;
    cursor: default;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-top: 4px;
    padding: 0 4px;
}
.lightpick__month .lightpick__month-title:hover {
    background-color: $c-gray-lightest;
}

.lightpick__month-title-accent {
    font-weight: bold;
    pointer-events: none;
}

.lightpick__toolbar {
    display: flex;
    text-align: right;
    justify-content: flex-end;
}

.lightpick__previous-action,
.lightpick__next-action,
.lightpick__close-action {
    align-items: center;
    border-radius: 50%;
    border: none;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-left: 6px;
    outline: none;
    width: 32px;
}

.lightpick__previous-action,
.lightpick__next-action {
    font-size: 12px;
}

.lightpick__close-action {
    font-size: 18px;
}

.lightpick__previous-action:active,
.lightpick__next-action:active,
.lightpick__close-action:active {
    color: inherit;
}

.lightpick__days-of-the-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.lightpick__day-of-the-week {
    align-items: center;
    display: flex;
    font-size: 11px;
    font-weight: bold;
    justify-content: center;
}

.lightpick__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.lightpick__day {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: default;
    display: flex;
    font-size: 13px;
    height: 40px;
    justify-content: center;
}

.lightpick__day.is-today {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(220, 50, 47, 0.06)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
    background-size: 61.8% auto;
    color: $c-primary;
}

.lightpick__day:not(.is-disabled):hover {
    background-size: contain;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23E0E0E0' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-disabled {
    opacity: .38;
    pointer-events: none;
}

.lightpick__day.disabled-tooltip {
    pointer-events: auto;
}

.lightpick__day.is-disabled.is-forward-selected {
    opacity: 1;
}
.lightpick__day.is-disabled.is-forward-selected:not(.is-start-date) {
    background-color: rgba($c-blue-light, 0.1);
    background-image: none;
}

.lightpick__day.is-previous-month,
.lightpick__day.is-next-month {
    opacity: 0.38;
}

.lightpick__day.lightpick__day.is-in-range:not(.is-disabled) {
    opacity: 1;
}

.lightpick__day.is-in-range {
    border-radius: 0;
    background-color: rgba($c-blue-light, 0.1);
    background-image: none;
}

.lightpick__day.is-in-range:hover {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(38, 139, 210, 0.5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped {
    border-top-left-radius: 50%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 50%;
    background-color: $c-blue-light;
    background-image: none;
}

.lightpick__day.is-end-date.is-in-range,
.lightpick__day.is-start-date.is-in-range.is-flipped {
    border-top-left-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 0;
    background-color: $c-blue-light;
    background-image: none;
}

.lightpick__day.is-start-date.is-end-date {
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23268BD2' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23268BD2' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
    color: $c-white;
    font-weight: bold;
}

.lightpick__tooltip {
    background-color: $c-white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba($c-black, .25);
    font-size: 11px;
    margin-top: -4px;
    padding: 4px 8px;
    pointer-events: none;
    position: absolute;
    white-space: nowrap;
}

.lightpick__tooltip::before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba($c-black, .12);
    bottom: -5px;
    content: "";
    left: calc(50% - 5px);
    position: absolute;
}

.lightpick__tooltip::after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $c-white;
    bottom: -4px;
    content: "";
    left: calc(50% - 4px);
    position: absolute;
}

.lightpick__months-of-the-year {
    background-color: $c-white;
    padding: 4px;
}
.lightpick__months-of-the-year-list {
    width: 294px;
    display: block;
    grid-template-columns: auto auto auto;
    grid-gap: 8px;
    justify-content: center;
}
.lightpick__month-of-the-year {
    width: 90px;
    border-radius: 3px;
    box-shadow: inset -1px -1px 1px 1px $c-gray-light;
    cursor: default;
    display: inline-block;
    margin: 4px;
    padding: 0 10px;
    text-align: center;
    // border: 1px solid red!important;
}
.lightpick__month-of-the-year > div:first-child {
    font-size: .9em;
    padding: 10px 0 2px 0;
    pointer-events: none;
}
.lightpick__month-of-the-year > div:last-child {
    color: $c-gray-dark;
    font-size: .7em;
    padding: 2px 0 10px 0;
    pointer-events: none;
}
.lightpick__month-of-the-year:hover {
    background-color: $c-gray-lightest;
    background-image: none;
}
.lightpick__footer {
    display: flex;
    justify-content: space-between;
}
.lightpick__reset-action,
.lightpick__apply-action {
    border-radius: 5px;
    font-size: 12px;
    border: none;
}
.lightpick__reset-action {
    color: $c-white;
    background-color: $c-gray-dark;
}
.lightpick__apply-action {
    color: $c-white;
    background-color: $c-blue-light;
}


