//
// Contrast options
//
// Sass options
// sass-lint:disable no-important
//
.bs-contrast {

  &__image {
    display: none;
  }

  // Grayscale
  &--grayscale {
    filter: gray;
    filter: grayscale(100%);

    .bs-contrast__image {
      display: block;

      & + img {
        display: none;
      }
    }

    a,
    a > span,
    a h1,
    a h2,
    a h3,
    a h4,
    a h5,
    a h6,
    button {
      text-decoration: underline solid $c-gray-dark;
    }
  }

  // High Contrast
  &--high {

    * {
      border-color: $c-contrast-border !important;
      color: $c-contrast-text !important;
    }

    .menu-overlay {
      background-color: transparent;
    }

    .bs-portal-content,
    .bs-legal-text {
      color: $c-contrast-text !important;
    }

    .bs-contrast__image {
      display: block;

      & + img {
        display: none;
      }
    }

    &,
    section,
    .bs-section,
    .bs-header__top,
    .bs-header__main,
    .bs-quick-menu,
    .bs-breadcrumb,
    .bs-footer,
    .bs-footer > div,
    .bs-container,
    .bs-menu,
    .bs-dropdown-menu__header {
      background: $c-contrast-bg !important;
    }

    &,
    .bs-refactor {
      a:focus {
        outline-color: #ff0 !important;
      }
      .bs-header .bs-header__main .bs-container .bs-header__logo a {
        padding-bottom: 7px;
      }

      .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.is-header-option-selected:after {
        color: #ff0!important;
      }

      .bs-header__exp-content .bs-media__item a:not(.bs-option-title):hover {
        color: #ff0!important;
      }

      .bs-header__exp-content .bs-media__item a:not(.bs-option-title):after {
        background-color: #ff0!important;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg.open a:before {
        width: 20px!important;
      }

      .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title.open:before {
        width: 20px!important;
      }

      .bs-header__exp-content .bs-menu-section-title-custom__bg.open a:before, .bs-contrast--high .bs-refactor .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg.open a:before {
        width: 20px!important;
      }

      .bs-button--login {
        border: 0!important;
      }

      .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) {
        background-color: #000;
      }

      .bs-header__exp-content .menu_login .bs-media__list--login {
        background-color: #000;
      }

      .bs-header .bs-header__exp-content {
        background-color: #000;
      }
      .bs-header  .bs-main-menu-option {
        background-color: #000;
      }
      .bs-header .bs-container-acessibility__mobile {
        background-color: #000;
      }
      .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open:not(.bs-accessibility-menu__item) {
        background-color: #000;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option span {
        z-index: 0;
      }
      .bs-refactor .bs-header .bs-header__exp-content:not(.bs-header__exp-contentMobile) {
        background-color: #000;
      }
      .bs-refactor .bs-header .bs-header__main:after {
        background-color: #ff0;
      }

    }

    .bs-menu::before {
      background-color: $c-contrast-bg-secondary !important;
    }

    svg * {
      fill: $c-contrast-text !important;
    }

    // Logo
    .bs-logo__icon-border,
    .bs-logo__icon-bg,
    .bs-logo__text {
      fill: $c-white !important;
    }
    .bs-logo__icon {
      fill: $c-black;
    }
    svg.bs-logo * {
      fill: $c-white !important;
    }
    :not(svg).bs-logo {
      display: none !important;
      filter: grayscale(0%) opacity(100%) !important;

      &--white {
        display: block !important;
      }
    }

    a,
    button,
    .bs-portal-content a,
    .bs-portal-content p a:not(.bs-button) {
      background: $c-contrast-bg !important;
      border-color: $c-contrast-border !important;
      color: $c-contrast-link !important;
      //text-decoration: underline !important;

      &::before,
      &::after,
      &:hover {
        background: $c-contrast-bg !important;
        border-color: $c-contrast-border !important;
        color: $c-contrast-link !important;
        text-decoration: none !important;

      }
    }

    // Button Loading
    .bs-button--loading {
      color: transparent !important;
    }

    .bs-button--loading::after {
      background-image: url("../img/icons/loading-yellow.svg") !important;
    }

    // Button Switch
    .bs-switch__lever.bs-switch--primary label input[type="checkbox"]:checked + span {
      background-color: $c-contrast-bg;
      border: 1px solid $c-contrast-border;
    }

    .bs-switch__lever label span::after {
      background-color: $c-contrast-border;
      box-shadow: 0 0 10px 0 rgba($c-contrast-border, 0.4);
    }

    i,
    i::before,
    .icon,
    .bs-icon,
    [class^=".bs-icon--"] {
      color: $c-contrast-icon !important;
    }

    .bs-form__label-text,
    .bs-form__field,
    input,
    select,
    textarea {
      background-color: $c-contrast-bg !important;
      border-color: $c-contrast-border !important;
      color: $c-contrast-text !important;

      @include placeholder() {
        background: $c-contrast-bg !important;
        border-color: $c-contrast-border !important;
        color: $c-contrast-text !important;
      };
    }

    select {
      background-image: url("../img/icons/arrow-down-yellow.svg") !important;
    }

    input:disabled {
      background-color: #686a3d !important;

      @include placeholder() {
        background-color: #686a3d !important;
      };
    }

    input[type="radio"] {
      box-shadow: none !important;
    }

    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      background-color: $c-contrast-border !important;
      border-color: $c-contrast-bg !important;
      box-shadow: 0 0 0 1px $c-contrast-border !important;
    }

    .bs-form__field-button {
      background-color: transparent !important;
    }

    img,
    .bs-mosaico__image > div {
      filter: grayscale(100%) opacity(80%) !important;
    }

    [class^="gm"] * {
      filter: grayscale(0%) opacity(100%) !important;
    }

    [class^="gm"] button {
      background-color: transparent !important;
    }
    .bs-accessibility-menu__button:hover,
    .bs-accessibility-menu__button:focus {
      background: $c-contrast-bg !important;
      color: $c-contrast-link !important;
    }
    .bs-accessibility-menu__button.bs-is-active,
    .bs-accessibility-menu__button.bs-is-active:hover,
    .bs-accessibility-menu__button.bs-is-active:focus {
      background-color: $c-contrast-text !important;
      color: $c-black !important;
    }
    .bs-accessibility-menu__button.bs-is-active i::before {
      color: $c-contrast-bg !important;
    }

    // Reset Backgrounds
    .bs-banner__box,
    .bs-banner__box *:not(.bs-button),
    .bs-banner--cta .bs-banner__figure--overlay-primary::before,
    .bs-mosaico__text,
    .bs-mosaico__text * {
      background-color: transparent !important;
    }

    // Hide elements
    .bs-mosaico__image::after {
      display: none !important;
    }

    // Remove background images
    .bs-media__section,
    .bs-mosaico {
      background-image: none !important;
    }

    // overlay background
    .bs-modal,
    .bs-overlay {
      background-color: rgba(0, 0, 0, 0.6) !important;
    }

    //  ------------------------
    // Specific elements
    //  ------------------------

    // Logo
    .bs-logo--text {
      fill: $c-white !important;
    }

    // Top Header
    .bs-top-menu__dropdown,
    .bs-top-menu__group-title {
      background-color: $c-contrast-bg !important;
    }

    // Buttons
    .bs-button {
      border: 1px solid $c-contrast-border !important;
    }

    // Hamburger
    .bs-hamburger {
      span,
      span::after,
      span::before {
        background: $c-contrast-icon !important;
      }
    }

    // Tables
    .bs-table td,
    .bs-table tr,
    .bs-table th,
    .bs-table {
      background-color: $c-contrast-bg !important;
      border-color: $c-contrast-border !important;
    }

    // Modal
    .bs-modal__header,
    .bs-modal__body,
    .bs-modal__container {
      background-color: $c-contrast-bg !important;
    }

    // Title detail
    .bs-section-header__title::before {
      background-color: $c-contrast-icon !important;
    }

    // Menu icon
    .bs-menu__link::before {
      background-color: $c-contrast-border !important;
    }

    // Menu
    .bs-menu__item--has-child > .bs-menu__link::after {
      color: $c-contrast-icon !important;
    }

    .bs-menu__close {
      span::after,
      span::before {
        background: $c-contrast-icon !important;
      }
    }

    .bs-menu__sublist {
      background-color: $c-contrast-bg !important;
    }

    // Tabs Shadows
    .bs-tabs .bs-tabs__item.bs-is-active,
    .bs-tabs .bs-tabs__item.is-nav-selected {
      background-color: $c-contrast-text !important;
      color: $c-contrast-bg !important;
    }
    .bs-tabs__content,
    .bs-tabs__content.bs-is-active {
      background-color: $c-contrast-bg !important;
    }

    .bs-tabs--over-next::after {
      background: linear-gradient(to right, rgba($c-contrast-bg, 0) 0%, rgba($c-contrast-bg, 0) 1%, rgba($c-contrast-bg, 0.7) 50%, $c-contrast-bg 100%) !important;
    }

    .bs-tabs--over-prev::before {
      background: linear-gradient(to right, $c-contrast-bg 0%, rgba($c-contrast-bg, 0.7) 50%, rgba($c-contrast-bg, 0) 100%) !important;
    }

    // Timeline
    .bs-time-line__nav-item {
      background-color: $c-contrast-bg !important;
    }

    // Media
    .bs-media--card .bs-media__box {
      background-color: $c-contrast-bg !important;
    }

    // News
    .bs-news-list--filter {
      background-color: $c-contrast-bg !important;
    }
    .bs-news-tags__link {
      border: 1px solid $c-contrast-border;
    }

    // Card/Media Slider Shadows
    .bs-media__list--linear-prev.is-draggable .flickity-viewport::before {
      background: linear-gradient(to left, rgba($c-contrast-bg, 0) 0%, rgba($c-contrast-bg, 0.47) 25%, rgba($c-contrast-bg, 0.78) 47%, $c-contrast-bg 75%, $c-contrast-bg 100%) !important;
    }
    .bs-media__list--linear-next.is-draggable .flickity-viewport::after {
      background: linear-gradient(to right, rgba($c-contrast-bg, 0) 0%, rgba($c-contrast-bg, 0.47) 25%, rgba($c-contrast-bg, 0.78) 47%, $c-contrast-bg 75%, $c-contrast-bg 100%) !important;
    }
    .bs-media__slider--nav .is-nav-selected::before {
      background-color: transparent !important;
    }

    .bs-section--red,
    .bs-section--gray .is-nav-selected::before {
      background-color: $c-contrast-bg !important;
    }

    // Quick Access/Menu
    .bs-quick-menu--over-prev::before {
      background: linear-gradient(to right, $c-contrast-bg 0%, rgba($c-contrast-bg, 0.7) 50%, rgba($c-contrast-bg, 0) 100%) !important;
    }

    .bs-quick-menu--over-next::after {
      background: linear-gradient(to right, rgba($c-contrast-bg, 0) 0%, rgba($c-contrast-bg, 0) 1%, rgba($c-contrast-bg, 0.7) 50%, $c-contrast-bg 100%) !important;
    }

    // Empty Search Icon
    .bs-empty-search .bs-media__figure.bs-icon-box {
      background-color: $c-contrast-bg;
      border: 1px solid $c-contrast-border;
    }

    // Mural Borders
    .bs-mural__item {
      border: 1px solid $c-contrast-border;
    }

    // Tippy
    .tippy-tooltip {
      border: 1px solid $c-contrast-border !important;
    }

    .tippy-arrow {
      border-color: transparent !important;
      border-top-color: $c-contrast-border !important;
    }

    // Loader
    .bs-loader {
      &::before,
      &::after {
        border-color: $c-contrast-border;
      }
    }

    // Box
    .bs-box {
      background-color: $c-contrast-bg !important;
    }

    // Progress Bar
    .bs-progress {
      background-color: $c-contrast-bg !important;
    }

    // Accordion icons
    .bs-accordion__icon {
      background-color: $c-contrast-bg !important;
    }
    .bs-accordion__icon::after,
    .bs-accordion__icon::before {
      background-color: $c-contrast-border;
    }

    // Banner Slider
    .bs-banner__slider-button {
      background-color: transparent !important;
      border: 1px solid $c-contrast-border;

      .bs-banner__slider-preview {
        background-color: $c-contrast-bg  !important;
      }

      &::after {
        color: $c-contrast-icon;
      }
    }
  }
}
