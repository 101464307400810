//-------------------------------
// Modal
// Componente que estiliza a estrutura padrão de modais
//-------------------------------
.bs-modal {
  align-items: center;
  display: flex;
  height: 0;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .3s ease, visibility .3s ease, height 0s ease .5s;
  visibility: hidden;
  width: 100%;
  z-index: z("modal");

  &__container {
    //border-top: 5px solid $c-primary;
    display: flex;
    flex-flow: column;
    max-height: 90%;
    overflow: auto;
    position: relative;
    top: (-$grid-gutter);
    width: 90%;

    @include media(">=tablet") {
      // height: 80%;
      width: 80%;
    }
  }

  &__header {
    background: $c-white;
    border-bottom: 1px solid $c-gray-light;
    border-top: 5px solid $c-primary;
    padding: 20px $grid-gutter;
    position: relative;

    @include media(">=tablet") {
      padding: 20px ($grid-gutter * 3);
    }
  }

  &__title {
    display: block;
    font-family: $font-title;
    font-size: rem(30);
    font-weight: fw("regular");
    line-height: 38px;
    margin: 0;
    min-height: 40px;
    padding-right: $grid-gutter * 3;
    width: 100%;
    word-break: break-all;
  }

  &__close-btn {
    align-items: center;
    color: $c-gray-dark;
    display: flex;
    font-size: rem(30);
    height: 80px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transition: color $transition-time;
    width: 60px;
    z-index: 999;

    @include media(">=tablet") {
      justify-content: flex-start;
      width: 75px;
    }

    &:focus,
    &:hover {
      color: $c-primary;
      transition: color $transition-time;
    }

    span {
      @include sr-only();
    }
  }

  &__body {
    @include scrollbar($c-gray, $c-gray-dark);
    background: $c-gray-lighter;
    flex: 1 1 auto;
    overflow: auto;
    overflow-x: hidden;
    padding: $grid-gutter;

    @include media(">=tablet") {
      padding: ($grid-gutter * 2) ($grid-gutter * 3);
    }

    p {
      margin-bottom: 15px;
    }

    &--light {
      background: $c-white;
    }
  }

  &__footer {
    background-color: $c-gray-light;
    display: block;
    font-family: $font-title;
    font-size: rem(18);
    font-weight: fw("regular");
    line-height: 22px;
    min-height: 50px;
    padding: 24px ($grid-gutter * 3);

    &:empty {
      display: none;
    }
  }

  &__wrapper {
    overflow-x: hidden;
    overflow-y: auto;

    @include media(">=desktop") {
      overflow: hidden;
    }


    .flickity-prev-next-button {
      height: 33px;
      width: 33px;
      position: absolute;
      top: 48%;

      &[disabled] {
        opacity: 0.3;
      }

      &.previous {
        left: -50px;
      }

      &.next {
        right: -50px;
      }

      .flickity-button-icon {
        fill: $c-white;
      }
    }
  }

  &__list {

    &--slider {
      display: none;
    }
  }

  &__item {
    width: 100%;
    margin: 0 100px;
    // opacity: 0;
    // left: auto !important;
    // transition: .3s;

    // &.is-selected {
    //   opacity: 1;
    //   z-index: 0;
    // }
  }

  &__buttons {

    .button {
      a{
        background: #fff;
        padding: 15px;
      }
    }
    .bs-modal {

      &--previous,
      &--next {
        bottom: 0;
        color: #fff;
        display: none;
        font-size: 35px;
        top: 45%;
        position: absolute;

        @include media(">=tablet") {
          display: block;
          height: 60px;
        }

        &[disabled="disabled"] {
          opacity: 0.3;
        }
      }

      &--previous {
        left: -60px;
      }
      &--next {
        right: -60px;
      }
    }
  }

  //-------------------------------
  // Childs
  //-------------------------------

  .bs-section {
    padding: 0;
  }

  .bs-container {
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  //-------------------------------
  // Modifiers
  //-------------------------------

  &--overlay-dark {
    background-color: rgba($c-black, .6);
  }

  &--overlay-white {
    background-color: rgba($c-white, .6);
  }

  &--compact {
    .bs-modal__container {
      max-width: 480px;
      overflow: hidden;
    }
  }

  &--medium {
    .bs-modal__container {
      max-width: 670px;
      // overflow-y: auto;
      // overflow-x: hidden;

      // @include media(">=tablet") {
      //   overflow-y: inherit;
      //   overflow-x: inherit;
      // }
    }
  }

  &--full {
    .bs-modal__container {
      flex-direction: column;
      height: 100%;
      max-height: 100%;
      width: 100%;

      .bs-modal__header,
      .bs-modal__footer {
        flex: 0;
      }

      .bs-modal__body {
        flex: 1;
      }

    }
  }

  &--transparent {
    .bs-modal__header,
    .bs-modal__footer {
      background-color: rgba(0, 0, 0, .5);
      color: $c-white;
    }

    .bs-modal__body {
      background-color: rgba(0, 0, 0, .5);
      color: $c-white;
    }
  }

  &--align-top {
    align-items: flex-start;
    padding-top: 30px;
  }

  &--open {
    // display: flex;
    height: 100%;
    opacity: 1;
    transition: opacity ($transition-time * 2);
    visibility: visible;
    //overflow: auto;

    .bs-modal__container {
      top: 0;
      transition: top ($transition-time * 2);
    }
  }

  &--unclosable {
    .bs-modal__close-btn {
      display: none;
    }
  }

  &--alert {
    .bs-modal__container {
      width: 370px;
    }

    .bs-modal__header {
      padding: $grid-gutter;
    }

    .bs-modal__close-btn {
      right: 5px;
      top: 10px;
    }

    .bs-modal__body {
      text-align: center;
    }
  }
}
