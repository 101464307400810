//-------------------------------
// Quick Menu
// Menu para listar links para páginas importantes, como um atalho
//-------------------------------
.bs-quick-menu {
    $this: &;

    background: $c-white;
    box-shadow: $box-shadow-fixed;
    position: relative;

    // menu list
    &__box {
        position: relative;
        overflow: hidden;
        @include media(">=tablet") {
            margin: 0;
        }
        @include media(">=desktop") {
            margin-bottom: 0;
        }
        @include media(">=wide") {
            flex-wrap: nowrap;
        }
    }
    // menu list
    &__list {
        display: flex;

        .flickity-viewport {
            width: 100%;
        }

        &:after {
            content: 'flickity';
            display: none;
            /* hide :after */
        }

        &:not(.bs-quick-menu--remain-slider) {

          @include media(">=wide") {
            flex-wrap: nowrap;
            align-items: center;
            display: flex;
            flex: 1 1 auto;
            justify-content: initial;
            overflow: initial;
            // default not slider
            &:after {
                content: '';
            }
          }

          #{$this}__item {

            &:not(.bs-quick-menu--icons) {
              display: flex;

              #{$this}__link {
                @include media(">=wide") {
                  white-space: pre-wrap;
                }
              }
            }
        }
      }
    }

    // menu item
    &__item {
        align-items: center;
        border: {
            right: 1px solid $c-gray-light;
        }
        // display: flex;
        height: 60px;
        // width: 100%;
        // white-space: nowrap;

        @include media(">=desktop") {
            // flex: 0 1 auto;
            // white-space: normal;
        }
        &:last-child {
            border: 0;
        }
    }
    // menu button more items
    &__item-button {
        margin-left: auto;
    }
    // menu link
    &__link {
        background-color: $c-white;
        display: flex;
        align-items: center;
        font-family: $font-title;
        font-size: rem(14);
        font-weight: 600;
        height: 100%;
        line-height: 18px;
        padding: 12px 20px;
        text-align: left;
        transition: $transition-time;
        width: 100%;
        white-space: nowrap;

        &:focus {
            outline-offset: -5px;
            outline-style: dotted;
            outline-width: 1px;
        }
        @include media(">=tablet") {
            font-size: rem(16);
            line-height: 20px;
        }
        @include media(">=desktop") {
            padding: $grid-gutter (2 * $grid-gutter);
        }
        // @include media(">=wide") {
        //     white-space: pre-wrap;
        // }

        &::after {
          content: "\e921";
          font-family: $font-icon;
          font-size: 11px;
          padding: 5px 5px 3px 5px;
        }

        .icon {
          display: none;
        }
        // menu link highlight
        &-highlight {
            display: block;
            font-weight: fw("bold");
            white-space: nowrap;
            @include media(">=tablet") {
                display: block;
                font-size: rem(16);
                //line-height: $grid-gutter * 2;
            }
            .icon,
            &::after {
                display: inline-block;
                font-size: rem(11);
                left: 0;
                margin-left: 5px;
                position: relative;
                transition: left $transition-time;
                @include media(">=tablet") {
                    font-size: rem(13);
                }
            }
        }
        &:hover {
            color: #4d4e53 !important;
            background-color: $c-gray-lighter;
            .icon,
            .bs-quick-menu__link-highlight::after {
                left: 5px;
            }
        }
    }
    &__link-wrapper {
        display: inline-block;
        text-align: left;
    }
    // buttons area
    &__buttons {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0 15px;
        // height: 60px;
        min-width: 150px;
        @include media(">=desktop") {
            // height: 70px;
        }
    }

    // --------------
    // Variações
    // --------------

    &--over-prev,
    &--over-next {

      &::before,
      &::after {
        content: '';
        height: 100%;
        transition: 0.5s;
        top: 0;
        pointer-events: none;
        position: absolute;
        width: 60px;
        z-index: 2;

        @include media(">=tablet") {
            display: none;
        }
      }

      &:hover::before,
      &:hover::after {
        width: 30px;
      }
    }

    &--over-prev {
      &::before {
        background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 40%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */    content: '';
        left: -1px;
      }
    }

    &--over-next {
      &::after {
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 0%,rgba(255,255,255,0.7) 40%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
        right: -1px;
      }
    }

    .bs-dropdown--open {
        .bs-quick-menu__toggle::after {
            transform: rotate(-180deg);
            // transition: transform $transition-time;
        }
        .bs-quick-menu__list {
            display: block;
        }
    }

    &--icon {
        .bs-quick-menu__list {
            // &::before {
            //     display: none;
            // }

            .bs-viewport {
                visibility: hidden;
                z-index: -99999;
                display: flex;

                li {
                    position: initial!important;
                    height: 0!important;
                    width: initial!important;
                }
            }
        }
        .bs-quick-menu__item {
          height: 114px;
          // overflow: hidden;
          white-space: normal;

          // if bug use >
          // width: initial;

          &:last-child {
            border: {
                right: 1px solid $c-gray-light;
            }
          }
        }
        .bs-quick-menu__link {
          align-items: initial;
          border-right: 1px solid $c-gray-light;
          display: flex;
          font: {
            size: 14px;
            weight: 500;
          }
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;
          border-top: 5px solid transparent;
          transition: 0.3s;
          padding: 20px 25px;

          @include media(">=desktop") {
            // display: flex;
            // padding: 12px;
          }

          span {
            display: block;
            padding-top: 10px;
            width: 100%;

            @include media(">=desktop") {
                padding: 0;
              }
          }

          &:hover {
            border-color: $c-primary;
          }

          &::after {
            display: none;
          }

          &:last-child {
            border-right: 0;
          }

          .icon {
            align-items: center;
            color: $c-primary;
            display: flex;
            font-size: 35px;
            justify-content: center;
            text-align: center;
            width: 100%;
          }
        }
    }

  &--hover-fill {
    > a.bs-quick-menu__link {
      border-top: 5px solid transparent;
      padding-top: 10px;
    }
  }

  &--active,
  &--hover-fill:hover {

    > a.bs-quick-menu__link {
      background-color: $c-gray-lighter;
      border-top: 5px solid $c-red;
      padding-top: 10px;
    }
  }

  &--fluid {


    @include media(">=tablet") {
      width: 25%;
    }

    @include media(">=wide") {
      width: 100%;
    }

    .bs-quick-menu__link {
      justify-content: center;
    }
  }

  &--vertical {
    height: 110px;

    .bs-quick-menu {

      &__link {
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;

          // padding: 0;

          &::after {
            display: none;
          }
      }

      &__icon-item {
        font-size: 35px;
        width: 100%;
      }

      &__text {
        display: block;
        width: 100%;
        // white-space: normal;
      }

    }


  }

  &__icon-item {
    color: $c-red;
    font-size: 22px;
    padding: 0 5px;
  }

  &__dropdown {

    overflow: auto;

    > #{$this}__item #{$this}__link{
      height: 60px;
    }

    #{$this}__shadow {
      background: linear-gradient(to right, rgba($c-white, 0), $c-white 80%);
      height: 100%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity 0.1s;
      width: 4 * $grid-gutter;

      &.is-visible {
        opacity: 1;
      }

      &--start {
        left: 0;
        transform: rotate(180deg);
      }

      &--end {
        right: 0;
      }
    }

    #{$this}__wrapper {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      white-space: nowrap;
    }

    #{$this}__menu {
      background-color: $c-white;
      border-top: 3px solid $c-primary-light;
      box-shadow: 0px 0px 6px 0px rgba($c-black, 0.31);
      flex-direction: column;
      left: $grid-gutter;
      opacity: 0;
      position: fixed;
      transform: translateY(5px);
      // transition: opacity .15s;
      visibility: hidden;
      width: calc(100% - #{2 * $grid-gutter});
      z-index: z("absolute");

      @include media(">=phone") {
        left: 0;
        width: 220px;
      }

      .bs-quick-menu__item {
        border-bottom: 1px solid $c-gray-light;
        border-right: 0;

        &:last-child {
          border: 0;
        }
      }

      &__link,
      &__back {
        padding: 10px;
      }

      &--open {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0px);
        visibility: visible;

        > .bs-quick-menu__submenu {
          display: block;
        }
      }

      &--sub-open {
        > li {
          // AJUSTAR GERAL
          display: none;
        }

        #{&}__submenu {
          background-color: $c-white;
          display: block;
        }
      }

      &--sortable {
        > .bs-quick-menu__item {
          > .bs-quick-menu__link {
            padding-left: 3 * $grid-gutter;

            &::before {
              background: center / 10px 17px no-repeat url("../icons/drag.svg");
              content: "";
              cursor: grab;
              top: 0;
              display: inline-block;
              height: 100%;
              left: 0;
              position: absolute;
              width: 40px;

              &:active {
                cursor: grab;
              }
            }
          }

          &.gu-transit {
            cursor: grabbing;
          }
        }
      }

      &--favorite {
        border-top: 0;
        box-shadow: 0px 4px 6px 0px rgba($c-black, 0.31);

        @include media(">=tablet") {
          width: 370px;
        }
      }
    }

    // use for dropMenu
    // --------
    #{$this}__submenu {
      display: none;
      z-index: z("default");

      > #{&}__item {
        border-bottom: 1px solid $c-gray-light;
        border-right: 0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    #{$this}__back {
      align-items: center;
      background-color: $c-gray-light;
      display: flex;
      font-size: rem(14);
      font-weight: fw("semibold");
      padding: 10px $grid-gutter;

      &::before {
        content: "\ea6a";
        display: inline-block;
        font-family: $font-icon;
        font-size: rem(12);
        margin-right: 5px;
      }
    }

    #{$this}__item {
      border-right: 1px solid $c-gray-light;
      display: block;
      color: $c-gray-darker;
      font: {
        family: $font-title;
        size: rem(16);
        weight: fw("medium");
      }
      height: initial;
      line-height: 1.2;
      position: relative;

      &--sub-open {
        // ajustar para remover importante
        display: block!important;
        // display: block;

        > #{$this}__submenu {
          background-color: $c-white;
          display: block;
        }

        > a {
          display: none;
        }
      }

      &--active {
        background-color: $c-gray-lighter;
      }
    }

    #{$this}__link {
      align-items: center;
      display: flex;
      // padding: $grid-gutter (3 * $grid-gutter) $grid-gutter $grid-gutter;
      height: 100%;
      transition: .15s;
      border-top: 0;
      justify-content: flex-start;

      &:hover {
        background-color: $c-gray-light;
        border-top: 0;
      }

      &::after {
        display: none;
      }

      &--arrow {
        &::after {
          display: inline-block;
          font-family: $font-icon;
          font-size: 12px;
          position: absolute;
          right: $grid-gutter;
        }

        &-right,
        &-down {
          @extend .bs-quick-menu__link--arrow;
        }

        &-right {
          &::after {
            content: "\ea69";
          }
        }

        &-down {
          &::after {
            content: "\ea67";
          }
        }
      }
    }

    #{$this}__icon {
      &:first-child {
        color: $c-red;
        font-size: 22px;
        margin-right: 5px;
      }

      &:last-child {
        color: $c-gray-darker;
        font-size: 12px;
        margin: 0 6px;
      }
    }

    #{$this}__favorite {
      background-color: $c-gray-lighter;
      line-height: 1.4;
      position: relative;

      &-link {
        display: block;
        padding: 18px $grid-gutter;
      }

      .bs-quick-menu__icon {
        font-size: rem(24);
      }

      .bs-quick-menu__icon,
      a {
        color: $c-blue;
      }

      a {
        font-weight: fw("bold");
      }
    }

    #{$this}--large {
      .bs-quick-menu__link {
        font-weight: 600;
        padding-bottom: 20px;
        padding-top: 20px;
      }

      .bs-quick-menu__back {
        padding-bottom: $grid-gutter;
        padding-top: $grid-gutter;
      }
    }

    #{$this}--border-left {
      .bs-quick-menu__item {
        &:first-child {
          border-left: 1px solid $c-gray-light;
        }
      }
    }

    #{$this}--margin {
      margin-bottom: $grid-gutter;
      margin-top: $grid-gutter;
    }

    #{$this}__icon-sub {
      color: $c-red;
      font-size: 20px;
      padding-right: 5px;
    }

    #{$this}--active-list {

      > .bs-quick-menu__link {
        border-top: 5px solid $c-red;
        padding-top: 15px;
      }
    }
  }

  &--icons {
    #{$this}__link {
      white-space: nowrap;
    }
  }

  // Actions Prev / Nex
  &__actions {
    display: none;

    &--button-prev,
    &--button-next {
      align-items: center;
      background: rgba(255, 255, 255, 0.79);
      display: none;
      height: 100%;
      justify-content: center;
      left: 0;
      top: 0;
      position: absolute;
      width: 50px;
      z-index: 9;

      @include media(">=tablet") {
        display: flex;
      }

      &:disabled {
        display: none;
      }
    }

    &--button-next {
      left: initial;
      right: 0;
    }
  }
}


.flickity-enabled + .bs-quick-menu__actions {
  display: block;
}