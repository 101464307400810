.bs-form {

  // Seção para formulários longos
  &__section {
    background-color: $c-gray-lightest;
    padding: ($grid-gutter * 3) 0;
  }

  // Fieldset para dividir blocos de campos em formulários longos
  &__fieldset {
    border-bottom: 1px solid $c-gray;
    margin-bottom: (3 * $grid-gutter);
    padding: $grid-gutter 0 (3 * $grid-gutter);

    &:last-of-type {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }

  // Títulos de seções de formulários (geralmente fieldsets)
  &__title {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(22);
    font-weight: fw("medium");
    margin-bottom: (2 * $grid-gutter);
  }

  // Texto tooltip
  &__field-info {
    color: $c-blue;
    display: none;
    font-size: 30px;
    margin: 0 10px;
  }

  // Grupo com um ou mais campos lado a lado
  &__group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$grid-gutter);

    .bs-form__control {
      flex: 1 1 100%;
      max-width: 100%;
      padding: 0 ($grid-gutter);
      width: auto;

      @include media(">=tablet") {
        flex: 1 1 50%;
      }

      &--small {
        @include media(">=tablet") {
          flex: 1 0 25%;
        }
      }

      &--large {
        @include media(">=tablet") {
          flex: 1 0 75%;
        }
      }
    }
  }

  // Container do campo, englobando label, o campo, botões, etc..
  &__control {
    margin-bottom: (2 * $grid-gutter);
    position: relative;
    width: 100%;

    .lightpick {
      left: 0;
      position: absolute;
      top: 100%;

      &:not(.is-hidden) {
        display: block;
      }
    }

    @at-root {
      .bs-form > #{&}:only-child {
        margin-bottom: 0;
      }
    }

    &--confirmation {
      margin: 0;
      padding: 10px 0;

      .bs-form__label {
        align-items: center;
        display: inline-flex;
        flex-direction: row;
        font-size: 14px;
      }
    }

    &--switch {
      margin: 0;
      padding: 10px 0;

      .bs-form__label {
        align-items: center;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .bs-switch__lever {
        margin-left: 5px;
      }
    }

    &--check {
      .bs-form__label {
        margin-bottom: 10px;
      }
    }

    &--vertical {
      padding: $grid-gutter 0;

      .bs-form__field-group {
        align-items: center;
        display: flex;
        margin-bottom: $grid-gutter;
        width: 100%;
      }
    }

    &--icon {
      position: relative;

      .bs-form__field {
        padding-right: 4 * $grid-gutter;
      }

      .icon {
        bottom: 12px;
        color: $c-gray-dark;
        font-size: 28px;
        position: absolute;
        right: $grid-gutter;
      }
    }

    &--file {
      position: relative;

      input[type="file"] {
        display: none;
      }

      .bs-form__field {
        overflow: hidden;
        white-space: nowrap;

        &:not(.bs-has-file) {
          color: $c-gray-dark;
        }
        &:not(.bs-has-file)+.bs-remove-file {
          display: none;
        }

        &.bs-has-file+.bs-remove-file,
        &.bs-has-file+.bs-remove-file:hover {
          position: absolute;
          top: 14px;
          right: 144px;
          min-height: 24px;
          height: 24px;
          background-color: $c-white;
          color: $c-red;
          border: none;
          border-right: 1px solid $c-gray-dark;
          border-radius: 0;
          padding: 3px 13px;
          line-height: 0.5;
          display: block;

          i{
            font-size: 1rem;
          }
        }
      }

      .bs-form__field-button {
        background-color: $c-white;
        bottom: 2px;
        border-radius: 5px;
        color: $c-primary;
        font-weight: fw("medium");
        height: 46px;
        padding: 12px $grid-gutter;
        right: 1px;
        top: 1px;
        width: auto;
      }
    }

    &--flex {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .bs-form-error__message {
        order: 3;
      }
    }

    &--cap {
      display: flex;
    }

  }

  // Label do campo
  &__label {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("medium");
    padding: 5px 0 8px;
    position: relative;

    // Quando o label faz o placeholder
    &--adaptive {
      padding: 0;
      position: relative;
      width: 100%;

      .bs-form__label-text {
        background: $c-white;
        border-radius: 5px;
        color: $c-gray-dark;
        cursor: text;
        display: block;
        font-size: 16px;
        height: 30px;
        left: 1px;
        line-height: 20px;
        margin: auto;
        overflow: hidden;
        padding: 5px 0 5px 15px;
        pointer-events: none;
        position: absolute;
        top: 10px;
        transition: $transition-parallax;
        white-space: nowrap;
        width: calc(100% - 40px);
      }

      select.bs-form__field + .bs-form__label-text {
        width: calc(100% - 30px);
      }

      .bs-form__field {
        @include placeholder {
          color: $c-gray;
        }
      }

      .bs-form__field:disabled ~ .bs-form__label-text {
        opacity: 0;
        visibility: hidden;
      }

      .bs-form__field--not-empty ~ .bs-form__label-text,
      .bs-form__field:valid ~ .bs-form__label-text,
      .bs-form__field:focus ~ .bs-form__label-text {
        color: darken($c-gray-dark, 10%);
        cursor: default;
        font-size: 12px;
        height: 20px;
        left: 10px;
        line-height: 16px;
        max-width: 100%;
        padding: 2px 10px;
        top: -10px;
        transition: $transition-parallax;
        width: auto;
      }
    }
  }

  // label Icon
  &__label-icon {
    flex-direction: initial;
  }

  // Texto de observação relacioada a algum campo
  // Geralmente fica logo abaixo do label, mas pode ser usado em outros casos
  &__observation {
    color: $c-gray-dark;
    font-size: rem(14);
    font-weight: fw("regular");
    line-height: 1.2;
  }

  // Botão de ajuda que abre um tooltip com informações
  &__info {

    .bs-form__field-button {
      color: $c-gray-dark;

      &::after {
        // icon-alerta-duvida
        content: "\e9e8";
        display: inline-block;
        font-family: "Bradesco_v2";
        font-size: 28px;
      }

      .bs-info-value {
        background-color: $c-white;
        border-radius: 3px;
        box-shadow: $box-shadow-fixed;
        color: $c-black;
        font-size: 12px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0 10px;
        position: absolute;
        right: -8px;
        top: inherit;
        transition: .2s;
        visibility: hidden;
        width: 165px;
        z-index: z("overlay") + 1;

        &::after {
          border: {
            left: 7px solid transparent;
            right: 7px solid transparent;
            top: 7px solid $c-white;
          };
          bottom: -6px;
          content: "";
          display: block;
          height: 0;
          position: absolute;
          right: 26px;
          width: 0;
        }
      }

      .bs-active {
        max-height: 130px;
        opacity: 1;
        overflow: inherit;
        padding: 10px;
        top: -54px;
        visibility: visible;
      }
    }

    &--is-visible .bs-form__field-button::after {
      // icon-nav-ocultar
      content: "\e96a";
    }
  }

  // Um container para agrupar um campo e um botão
  &__field-group {
    display: flex;
    position: relative;

    .bs-button {
      margin-left: $grid-gutter;
    }

    &--check {
      align-items: center;
      display: inline-flex;
      margin-right: 20px;
    }
  }

  &__field-group {
    &[data-validation-state="invalid"] {

      input {
        border-color: $c-error;

        &[type="radio"] {
          border-width: 1px;

          &:focus,
          &:checked {
            border-color: $c-white;
            border-width: 5px;
          }
        }

        &:focus,
        &:hover {
          border-color: $c-error-dark;
        }
      }

      & ~ .bs-form-error__message {
        display: block;
      }
    }
  }

  // Campos padrão
  input,
  textarea,
  &__field {
    background-color: $c-white;
    border: 1px solid $c-gray;
    box-shadow: none;
    border-radius: 5px;
    color: $c-gray-darker;
    display: block;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    padding: 0 $grid-gutter;
    width: 100%;

    &:hover {
      border-color: darken($c-gray, 10%);
    }

    &:focus {
      border-color: darken($c-gray, 20%);
    }

    @include placeholder {
      color: $c-gray-dark;
    }

    &[data-validation-state="invalid"] {
      border-color: $c-error;

      &:focus,
      &:hover {
        border-color: $c-error-dark;
      }

      & ~ .bs-form__label-text .bs-form-error__message,
      & ~ .bs-form-error__message {
        display: block;
      }
    }
  }

  select,
  textarea {
    &[readonly] {
      background-color: $c-gray-lighter;
      border-color: $c-gray;
      color: $c-gray-dark;
      pointer-events: none;
    }
  }

  // Áreas de texto longo
  textarea {
    line-height: 1.5;
    max-width: 100%;
    min-height: 150px;
    padding: $grid-gutter;
  }

  // Radios e Checkboxes
  &__field[type="checkbox"],
  &__field[type="radio"] {
    background: $c-white;
    border: 1px solid $c-gray;
    border-radius: 5px;
    display: inline-block;
    height: 30px;
    margin-right: 15px;
    padding: 0;
    min-width: 30px;
    width: 30px;

    &:hover {
      border-color: darken($c-gray, 10%);
    }

    &:focus {
      border-color: darken($c-gray, 20%);
    }

    &:focus ~ .bs-form__label-text {
      outline: 1px dashed $c-gray-dark;
      outline-offset: 5px;
    }

    &:checked ~ .bs-form__field-info {
      display: block;
    }

    &:disabled,
    &:readonly {
      background-color: $c-gray-light;
      cursor: not-allowed;
    }
  }

  // Checkboxes
  &__field[type="checkbox"] {
    &:checked {
      background: {
        color: $c-white;
        image: url("../img/icons/check.svg");
        position: 50% center;
        repeat: no-repeat;
        size: 20px;
      }
    }
  }

  // Radios
  &__field[type="radio"] {
    border: 5px solid $c-white;
    border-radius: 100%!important;
    box-shadow: 0 0 0 1px $c-gray;
    flex-shrink: 0;
    padding: 0;
    transition: box-shadow $transition-time;

    &:hover,
    &:focus {
      border-color: $c-white;
      box-shadow: 0 0 0 1px $c-gray-dark;
      transition: box-shadow $transition-time;
      border-radius: 100%;
    }

    &:checked {
      background-color: $c-gray-darker;
    }
  }

  // select
  &__field {
    @at-root select#{&} {
      background: {
        color: $c-white;
        image: url("../img/icons/arrow-down.svg");
        position: calc(100% - 10px) center;
        repeat: no-repeat;
        size: 14px 14px;
      }
      padding-right: 20px;

      // Hide default select arrow in IE
      &::-ms-expand {
        display: none;
      }
    }
    // Backgound color blue form select problem IE11
    &::-ms-value {
      background: none;
      color: #42413D;
    }
  }

  &__field:disabled,
  &__field:disabled:hover {
    background-color: $c-gray-lighter;
    border-color: $c-gray;
    color: $c-gray-dark;
  }

  // Botão/Ícone que fica dentro do campo, à direita
  &__field-button {
    background-color: $c-white;
    cursor: pointer;
    height: 46px;
    line-height: 26px;
    padding: 10px;
    position: absolute;
    right: 2px;
    text-align: center;
    top: 2px;
    width: 46px;

    &:focus {
      outline: 1px dashed $c-gray-dark;
      outline-offset: -5px;
    }

    .icon {
      color: $c-gray-dark;
      font-size: 26px;
    }
  }

  // Área para o botões de submit do formulário
  &__submit {
    align-items: flex-start;
    display: flex;
    padding: 10px 0;

    .bs-button {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .bs-form__link {
      margin-left: auto;
    }
  }

  // Componente de foto de perfil
  &__photo {
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    }
    border-radius: 50%;
    height: 120px;
    position: relative;
    width: 120px;
  }

  // Campos de upload de arquivos
  &__file {
    overflow: hidden;
    position: relative;

    input[type="file"] {
      cursor: pointer;
      font-size: 20px;
      margin: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
    }
  }

  // Campos de telefone
  &__telephone {
    .bs-button {
      height: 50px;
      padding: 0;
      width: 100%;

      .icon {
        font-size: 28px;
        margin: 0;
      }
    }
  }

  // Links dentro de forumlários
  &__link {
    color: $c-primary;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  // parágrafos
  p {
    margin-bottom: $grid-gutter;
  }

  // Campo de validação de informação
  // Mostra um ícone de erro, um para loading e outro para o caso do campo valido
  &__validate {
    display: block;

    .bs-form__field-button {
      color: $c-gray-dark;

      &::after {
        // icon-nav-atualizar
        content: "\e95b";
        display: inline-block;
        font-family: "Bradesco_v2";
        font-size: 28px;
      }
    }

    .bs-form__field[data-validation-state="loading"] ~ .bs-form__field-button::after {
      background: transparent url("../img/icons/loading.svg") center / 40px no-repeat;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }

    @at-root .bs-is-valid #{&} .bs-form__field[data-validation-state="valid"] {
      border-color: $c-green;

      & ~ .bs-form__field-button {
        color: $c-green;

        &::after {
          // icon-alerta-sucesso
          content: "\e9ea";
        }
      }
    }

    @at-root .bs-is-valid #{&} .bs-form__field[data-validation-state="invalid"] {
      border-color: $c-primary;

      & ~ .bs-form__field-button {
        color: $c-primary;

        &::after {
          // icon-alerta-atencao
          content: "\e9e7";
        }
      }

      & ~ .bs-form-error__message {
        display: block;
      }
    }
  }

  // Campo de senha
  &__password {

    .bs-form__field-button {
      color: $c-gray-dark;

      &::after {
        // icon-nav-visualizar
        content: "\e973";
        display: inline-block;
        font-family: "Bradesco_v2";
        font-size: 28px;
      }
    }

    &--is-visible .bs-form__field-button::after {
      // icon-nav-ocultar
      content: "\e96a";
    }
  }

  &__datepicker {
    position: relative;

    .bs-form__field-button {
      color: $c-gray-dark;
      // pointer-events: none;

      &::after {
        // icon-doc-calendario
        content: "\e9bf";
        display: inline-block;
        font-family: "Bradesco_v2";
        font-size: 28px;
      }
    }

    .bs-form__field:valid + .bs-form__field-button::after {
      // icon-doc-carencia
      content: "\e9c0";
    }
  }

  // Componente de seleção de formulários
  &__selector,
  // deprecated, usar apenas o componente form-selector
  &-selector {
    background-color: $c-gray-lightest;

    .bs-form {
      border-bottom: 1px solid $c-gray;
      margin-bottom: 30px;
      position: relative;

      &::after {
        background-color: $c-gray-lightest;
        border-bottom: 1px solid $c-gray;
        border-left: 1px solid $c-gray;
        bottom: -16px;
        content: "";
        display: block;
        height: 30px;
        left: 15%;
        position: absolute;
        transform: rotate(-45deg);
        width: 30px;
      }
    }

    .bs-form__control {
      padding: ($grid-gutter * 3) 0;
    }
  }

  // Mensagem de erro
  &-error {

    &__message,
    &__checkbox {
      // deprecated
      color: $c-error;
      display: none;
      font-size: 12px;
      padding: 5px 0;
    }
  }

  // Errors
  .bs-has-error {
    .bs-form__field:not([type="radio"]) {
      border-color: $c-error;

      &:focus,
      &:hover {
        border-color: $c-error-dark;
      }
    }

    .bs-form__field[type="radio"] {
      box-shadow: 0 0 0 1px $c-error;
    }

    .bs-form__label-text {
      // color: $c-error;
    }

    .bs-form-error__message {
      display: block;
    }

    &.bs-form-error__message {
      display: block;

      & ~ .bs-form__field {
        border-color: $c-error;
      }
    }
  }

  // Formulário com passos
  &--steps {
    .bs-form__header {
      border-bottom: 1px solid $c-gray;
      margin-bottom: (2 * $grid-gutter);
      padding-bottom: (2 * $grid-gutter);
    }

    .bs-form__step {
      p {
        font-family: $font-title;
        margin-bottom: 0;
      }
    }

    .bs-form__fieldset {
      display: none;
      border-bottom: 0;

      &--active {
        display: block;
      }
    }
  }
}
