//-------------------------------
// Bs Banner
// Componente para banners diversos (de topo, de CTA, parallax)
//-------------------------------
.bs-banner {
  position: relative;

  &__list {
    overflow: hidden;
  }

  &,
  &__item {
    height: 480px;

    @include media(">=desktop") {
      height: 600px;
    }
  }

  &__item {
    overflow: hidden;
    position: relative;
    width: 100%;

    &--text {
      &-dark,
      &-primary,
      &-secondary {
        @include media("<tablet") {
          .bs-banner__figure::before {
            background: rgba($c-white, .9);
          }
        }
      }

      &-dark {
        .bs-banner__text {
          color: $c-gray-darker;
        }
      }

      &-primary {
        .bs-banner__text {
          color: $c-primary;
        }
      }

      &-secondary {
        .bs-banner__text {
          color: $c-secondary;
        }
      }
    }

    &:focus {
      // border: 10px solid purple;
      outline: 1px dotted $c-gray-light;
      outline-offset: -5px;
    }
  }

  // Wrapper de figure (pode conter imagem, ícone ou vídeo)
  &__figure {
    height: 100%;
    overflow: hidden;

    &::before {
      background-color: transparent;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    @include media("<=tablet") {
      &::before {
        background: rgba($c-black, .5);
      }
    }

    &--overlay {

      &::before {
        background: rgba($c-black, .5);
      }

      &-light::before {
        background: rgba($c-white, .7);
      }

      &-primary::before {
        background: rgba($c-primary-dark, .7);
      }

      &-secondary::before {
        background: rgba($c-secondary-dark, .7);
      }
    }
  }

  // picture wrapper (sources)
  &__image {

    img {
      @include object-fit(cover);
      display: block;
      height: calc(100% + 20px);
      margin: -10px;
      max-width: none;
      object-fit: cover;
      width: calc(100% + 20px);

      @include media(">=tablet") {
        height: 100%;
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  // Wrapper interno
  &__box {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .bs-banner__text {
      text-align: left;
    }

  }

  // Área de video do banner que contem o modal do vídeo
  &__video {

    // Ajusta a altura do iframe do vídeo
    .bs-video {
      height: 90%;
      width: 100%;
    }
  }

  // Botão para abrir o modal de vídeo
  // Fica sobre o banner, geralmente à direita do texto
  &__play-button {
    bottom: 0;
    display: block;
    font-size: 60px;
    height: 80px;
    left: 0;
    line-height: 80px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 80px;
    z-index: 1;

    @include media(">=desktop") {
      font-size: 80px;
    }

    // Ajusta o botão para que fique ao lado do texto, se houver texto
    // Por padrão, o botão fica centralizado no banner
    &:not(:only-child) {
      font-size: 40px;
      height: 60px;
      line-height: 60px;
      margin: 10px auto 0;
      position: static;
      width: 60px;

      @include media(">=tablet") {
        font-size: 60px;
        left: auto;
        margin: auto;
        position: absolute;
        right: 15%;
        top: 0;
      }
    }

  }

  // Wrapper de texto
  &__text {
    color: $c-white;
    padding: 60px 0 30px;
    text-align: center;

    @include media(">=tablet") {
      max-width: 400px;
      padding: 80px 0 40px;
      text-align: left;
    }

    @include media(">=desktop") {
      max-width: 600px;
      padding: 120px 0 60px;
    }
  }

  // Título do item (nível default: 1)
  &__pretitle {
    display: block;
    font-family: $font-title;
    font-size: rem(14);
    font-weight: fw("bold");
    margin-bottom: $grid-gutter;

    @include media(">=desktop") {
      font-size: rem(16);
    }
  }

  // Título do item (nível default: 1)
  &__title {
    font-family: $font-title;
    font-size: rem(28);
    font-weight: fw("regular");
    line-height: 1.2;
    text-shadow: 0 1px 2px rgba(0, 1, 2, 0.2);
    word-break: break-word;

    @include media(">=desktop") {
      font-size: rem(50);
      line-height: 55px;
    }

    &-highlight {
      display: block;

      &[data-highlight=""] {
        display: none;
      }

      .sr-only {
        @include sr-only();
      }
    }
  }

  // Subtítulo do item (paragraph)
  &__subtitle {
    font-family: $font-text;
    font-size: rem(16);
    font-weight: fw("regular");
    margin-top: $grid-gutter;
    text-shadow: 0 1px 2px rgba(0, 1, 2, 0.2);

    @include media(">=desktop") {
      font-size: rem(22);
      margin-top: ($grid-gutter * 2);
    }

    &-highlight {
      //display: block;

      .sr-only {
        @include sr-only();
      }
    }
  }

  // Metadados
  // Fica na mesma posição do subtítulo
  &__metadata {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin-top: $grid-gutter;

    @include media(">=tablet") {
      justify-content: flex-start;
      margin-top: ($grid-gutter * 2);
    }

    span {
      margin-right: 5px;
    }
  }

  // Wrapper de botão
  &__buttons {
    margin-top: $grid-gutter;

    @include media(">=tablet") {
      margin-top: ($grid-gutter * 2);
    }

    .bs-button {
      margin-bottom: 10px;
      margin-right: 20px;
    }
  }

  // Slider elements
  &__slider {
    overflow: hidden;

    // prev/next buttons
    &-button {
      display: none;
      height: 80px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      // transition: .3s;
      width: 80px;

      @include media(">=1025px") {
        display: block;
      }

      &::after {
        bottom: 0;
        color: $c-white;
        font-family: "bradesco_v2";
        font-size: 40px;
        height: 40px;
        line-height: 40px;
        margin: auto;
        opacity: .4;
        position: absolute;
        right: 10px;
        top: 0;
        transition: opacity .3s;
      }

      &.prev {
        left: 0;

        &::after {
          content: "\e925";
          left: 10px;
        }

        .bs-banner__slider-preview {
          left: auto;
          padding-left: 55px;
          padding-right: 0;
          right: calc(100% + 80px);
        }

        &:focus .bs-banner__slider-preview,
        &:hover .bs-banner__slider-preview {
          right: 0;
        }
      }

      &.next {
        right: 0;

        &::after {
          content: "\e922";
          right: 10px;
        }

        &:focus .bs-banner__slider-preview,
        &:hover .bs-banner__slider-preview {
          left: 0;
        }
      }

      &:focus,
      &:hover {
        @include media(">=desktop") {
          width: 327px;

          &::after {
            color: $c-primary;
            opacity: 1;
          }
        }
      }
    }

    // prev/next slide preview
    &-preview {
      align-items: center;
      background-color: $c-white;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      color: $c-primary;
      display: none;
      font-weight: fw("semibold");
      height: 100%;
      left: calc(100% + 80px);
      overflow: hidden;
      padding-right: 55px;
      position: absolute;
      top: 0;
      transition: .3s;
      width: 327px;

      @include media(">=desktop") {
        display: flex;
      }

      &-image {
        height: 80px;
        // width: 135px;
      }

      span {
        display: flex;
        height: 45px;
        width: 137px;
        padding: 0 10px;
        overflow: hidden;
        word-break: break-word;
        align-items: center;
      }
    }

    .bs-banner__item {
      transition: visibility 0 .3s;
      visibility: visible;

      &.is-hidden {
        transition: visibility 0 .3s;
        visibility: hidden;
      }
    }
  }

  //-------------------------------
  // Modifiers de componente (section)
  //-------------------------------

  // Banner deprecated old version
  &--old {

    .bs-banner__text {
      text-align: left;
    }

    .bs-banner__figure {
      &::before {
        // background: rgba($c-black, .5); // Requested to be removed on 7/2/2020
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  &--medio {

    &,
    .bs-banner__item {
      height: 360px;

      @include media(">=tablet") {
        height: 480px;
      }
    }

    .bs-banner__text {
      padding: 60px 0 30px;
    }

    .bs-banner__buttons {}

  }

  &--small {

    &,
    .bs-banner__item {
      height: 220px;

      @include media(">=tablet") {
        height: 280px;
      }
    }

    .bs-banner__text {
      padding: 30px 0;
    }

    .bs-banner__title {
      // font-size: rem(32);
      line-height: 1.2;

      @include media(">=tablet") {
        // font-size: rem(44);
      }
    }

    .bs-banner__subtitle {
      margin-top: 10px;

      @include media(">=tablet") {
        margin-top: $grid-gutter;
      }
    }

    .bs-banner__buttons {

      .bs-button {
        margin-bottom: 10px;
        margin-right: 20px;
      }
    }

  }

  //
  //  Banner CTA
  //
  // Parallax Reference:
  // https://codepen.io/RenanB/pen/GZeBNg
  // https://github.com/nk-o/jarallax/blob/master/src/jarallax.js#L206
  &--cta  {
    height: auto;
    position: relative;

    .bs-banner__figure {
      box-shadow: $box-shadow-inset;
      height: 300px;
      overflow: hidden;
      position: relative;

      @include media(">=tablet") {
        height: 380px;
      }

      &--parallax {

        .bs-banner__image {
          img {
            // @include object-fit(none);
            display: block;
            height: auto;
            margin: auto;
            max-width: none;
            min-height: 100%;
            position: relative;
            top: 0;
            width: 100%;
            z-index: 0;
          }
          // img {
          //   position: inherit!important;
          //   max-height: 100%;
          // }
        }
      }
    }

    .bs-banner__image {
      img {
        @include object-fit(cover);
        display: block;
        height: 100%;
        margin: auto;
        max-width: none;
        object-fit: cover;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 0;
      }
    }

    .bs-banner__box {
      align-items: center;
      z-index: z("absolute");
    }

    .bs-banner__text {
      margin: auto;
      max-width: 600px;
      padding: $grid-gutter 0;
      position: relative;
      text-align: center;
    }

    .bs-banner__icon {
      align-items: center;
      color: $c-white;
      display: inline-flex;
      font-size: 50px;
      height: 50px;

      @include media(">=tablet") {
        font-size: 50px;
        height: 50px;
      }
    }

    .bs-banner__title {
      font-size: rem(22);
      font-weight: 700;
      line-height: 1.2;

      @include media(">=tablet") {
        font-size: rem(34);
        line-height: 1.2;
        margin-bottom: $grid-gutter;
      }
    }

    .bs-banner__subtitle {
      @include media(">=tablet") {
        font-size: rem(16);
        line-height: 1.2;
      }
    }

    .bs-banner__buttons {
      .bs-button {
        margin: 0;
      }
    }
  }

  // Banner com vídeo (exibido em um modal)
  &--video {

    // Ajusta a largura do texto para que o botão
    // de play fique ao lado
    .bs-banner__text {

      @include media("<tablet") {
        max-width: 320px;
      }
    }

    // Ajusta o modal para exibir apenas o vídeo
    .bs-modal {
      &__header {
        border-bottom: 0;
        padding: 0;
      }

      &__header,
      &__footer {
        background-color: rgba(0, 0, 0, .5);
        color: $c-white;
      }

      &__body {
        background-color: rgba(0, 0, 0, .5);
        color: $c-white;
      }

      &__title {
        display: none;
      }
    }
  }

  // Slider Dots
  .flickity-page-dots {
    bottom: 10px;

    @include media(">=tablet") {
      bottom: $grid-gutter;
    }

    .dot {
      background-color: $c-white;
      box-shadow: 0 0 0 1px $c-gray-dark;
      opacity: .4;
      transition: .3s;

      &:hover,
      &.is-selected {
        opacity: 1;
      }
    }
  }

  // Flickity default Buttons
  .flickity-button {
    display: none;
  }
}
