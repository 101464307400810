// -----------------
// Grid
// Define padrões de listas de itens em grid
// -----------------
.bs-grid {

  &__filter {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 0 (-($grid-gutter / 2));
  }

  &__filter > * {
    padding: 0 ($grid-gutter / 2);
  }

  &__counter {
    b {
      font-weight: 600;
    }
  }

  &__formats {
    display: none;

    @include media(">=tablet") {
      display: flex;
    }
  }

  &__format {
    cursor: pointer;

    &-input {
      height: 0;
      position: absolute;
      width: 0;
    }

    &-input:checked + &-icon {
      background-color: $c-gray-light;
    }

    &-icon {
      border: 1px solid $c-gray;
      display: block;
      // height: 20px;
      line-height: 18px;
      padding: 1px;
      text-align: center;

      &::after {
        content: "\ea3f";
        display: block;
        font-family: "bradesco_v2";
        font-size: 18px;
      }

      &[data-format="grid"]::after {
        content: "\e96c";
      }

      &[data-format="list"]::after {
        content: "\ea3f";
      }
    }
  }

  &__dropdown {
    position: relative;

    &-toggle {
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      &::after {
        content: "\e91b";
        display: inline-block;
        font-family: "bradesco_v2";
        font-size: 14px;
        margin-left: 5px;
        transform: rotate(0);
        transition: .3s;
        vertical-align: middle;
      }
    }

    &-box {
      background-color: $c-white;
      display: none;
      position: absolute;
      right: 0;
      top: calc(100% + 10px);
      width: 280px;
      z-index: z("absolute");
    }

    &.bs-is-expanded &-toggle::after {
      transform: rotate(-180deg);
    }

    &.bs-is-expanded &-box {
      display: block;
    }
  }

  &__list {
    padding-top: 30px;
  }
}
