//-------------------------------
// Bs Info
// Conteúdo interno de alguns modais de informação
//-------------------------------
.bs-info {
  // Seção do componente
  &__section {
    padding: 60px 0;

    &--large {
      padding: 100px 0 80px;
    }
  }

  // Lista que agrupa os itens
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;
  }

  // Cada info item
  &__item {
    @include flex-basis(100%);
    max-width: 100%;
    padding: $grid-gutter;
    transition: background-color $transition-time;

    @at-root {
      a#{&}:hover {
        background: $c-gray-lighter;
        cursor: pointer;
      }
    }
  }

  // Wrapper interno
  &__box {}

  // Wrapper de figure (pode conter imagem, ícone ou vídeo)
  &__figure {

    picture {
      display: block;
    }

    img {
      display: block;
    }

    &--cover {

      img {
        @include object-fit(cover);
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  // Wrapper de texto
  &__text {}

  // Subtítulo (texto comum destacado)
  &__title {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(18);
    font-weight: fw("bold");
    line-height: 1.33;
    margin-bottom: $grid-gutter;
  }

  // Texto descritivo
  &__description {
    color: $c-gray-darker;
    font-family: $font-secondary;
    font-size: rem(16);
    font-weight: fw("light");
    line-height: 1.5;
    margin-bottom: $grid-gutter;

    p {
      margin-bottom: $grid-gutter;
    }

    h3 {
      font-size: 18px;
      margin-bottom: ($grid-gutter / 2);
    }
  }

  // Wrapper de botão
  &__button {
    padding: $grid-gutter 0;
    flex: 1;

    .bs-button {
      margin-bottom: $grid-gutter;
      margin-right: $grid-gutter;
    }
  }

  &__highlight {
    color: $c-primary;
    font-size: 24px;
    font-weight: fw("semibold");
  }

  &__footer {

    @include media(">=tablet") {
      align-items: center;
      display: flex;
      padding-top: $grid-gutter;
    }

    .bs-info__button {

      @include media(">=tablet") {
        flex: 1;
        padding: 0;
      }

      .bs-button {
        margin: 0;
      }
    }

    .bs-social {
      &__list{
        margin: 0;
        padding: 10px 0;
        width: initial;
        
        @include media(">=tablet") {
          flex: 1;
          //padding: 15px 0;
        }
      }
      &__item {
        margin-right: 10px;
      }
      &__link {
        color: $c-red;
        font-size: 22px;
        padding: 5px;
      }
    }
  }

  &__share {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    h4 {
      margin-right: 20px;
    }
  }

  //-------------------------------
  // Modifiers de componente (section)
  //-------------------------------

  // Horizontal
  &--horizontal {

    .bs-info__list {
      align-items: center;
    }

    .bs-info__box {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$grid-gutter);

      @include media(">=phone") {
        order: 0;
      }
    }

    .bs-info__figure,
    .bs-info__text {
      flex: 1 0 auto;
      @include flex-basis(100%);
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
    }

    .bs-info__text {
      @include media(">=phone") {
        @include flex-basis(50%);
      }
    }

    .bs-info__figure {
      @include media(">=phone") {
        @include flex-basis(50%);
      }
    }
  }
}
