//-------------------------------
// Modal de Produtos
// Estilo específico para componente de media dentro de modais
//-------------------------------
.bs-modal {

  .bs-media__item {
    // flex mode
    @include media(">=tablet") {
      @include flex-basis(50%);
    }

    @include media(">=desktop") {
      @include flex-basis(33.333%);
    }
  }
}
