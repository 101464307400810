//-------------------------------
// Lista Download
//-------------------------------
.bs-download {
  $d: &;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ($grid-gutter * 2);

  .bs-section-header__title {
    @include media(">=tablet") {
      margin-bottom: 20px;
    }
  }

  &__actions {
    align-items: center;
    justify-content: space-between;
    margin-bottom: $grid-gutter;

    @include media(">=phone") {
      display: flex;
    }

    .bs-form__field {
      width: 100%;
      @include media(">=tablet") {
        width: 260px;
      }
    }

    .bs-button {
      // temp
      // display: none;
      display: inline-block;

      @include media(">=desktop") {
        display: inline-block;
        margin-left: auto;
      }
    }
  }

  &__filter {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    order: 1px;

    .bs-form__field {
      margin-bottom: $grid-gutter;
      margin-right: $grid-gutter;
      padding-right: 40px;
      width: auto;
    }
  }

  &__multi {
    display: none;
    margin-bottom: $grid-gutter;
    margin-left: auto;
    order: 2;

    @include media(">=desktop") {
      display: block;
    }
  }

  &__list {
    // display: none;
    order: 3;
    overflow: hidden;
    width: 100%;

    &--active {
      display: block;
    }

    #{$d}__title {
      font-size: 18px;
      padding: $grid-gutter 0;
      position: relative;

      @include media(">=desktop") {
        display: flex;
        font-size: 22px;
      }
    }

    .bs-download-accordion__icon {
      position: absolute;
      right: 10px;
      top: 18px;
    }
  }

  &__group {}

  &__item {
    align-items: center;
    border-top: {
      color: $c-gray;
      style: solid;
      width: 1px;
    }
    display: flex;
    transition: background $transition-time;

    @include media(">=tablet") {
      padding: 0 ($grid-gutter / 2);
    }

    &--active,
    &:hover {
      background-color: $c-gray-lighter;
    }

    #{$d}__checkbox {
      align-items: center;
      // temp
      display: none;
      // display: flex;
      justify-content: center;
      padding: 0 ($grid-gutter / 2);

      @include media(">=desktop") {
        display: flex;
      }

      .bs-form {
        &__field {
          margin: 0;

          &:focus {
            @include focus($c-gray-dark, -3);
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    width: 100%;

    &:hover {
      .bs-icon {
        animation-duration: .5s;
        animation-name: bounceUpDown;
      }
    }

    &:focus {
      @include focus($c-gray-dark, -5)
    }
  }

  &__description {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__icon {
    height: 25px;
    width: 27px;
  }

  &__subtitle {
    $s: &;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: flex-start;
    padding: 0 ($grid-gutter / 2);
    width: 100%;

    @include media(">=tablet") {
      flex-wrap: nowrap;
      font-size: 16px;
      justify-content: space-between;
    }

    &-featured {
      font-weight: 700;
      padding: 0 ($grid-gutter / 2) ($grid-gutter / 2);

      @include media(">=tablet") {
        padding: 0 $grid-gutter 0 0;
      }
    }

    &-icon {
      display: block;
      font-size: 28px;
      padding: 0 ($grid-gutter / 2);
      // vertical-align: middle;
    }

    &-filetype {
      border-right: 1px solid $c-gray;
      min-width: 40px;
      padding: 0 5px;

      @include media(">=tablet") {
        margin-left: auto;
      }
    }

    &-size {
      min-width: 60px;
      padding: 0 5px;
    }
  }

  &__info {
    align-items: center;
    display: flex;

    .bs-icon {
      color: $c-primary;
      font-size: 20px;
      padding: 0 ($grid-gutter / 2);
    }

    #{$d}__button {

      .bs-icon {
        color: $c-primary;
        font-size: 24px;
        position: relative;
      }
    }
  }

  &__subgroup &__item {
    padding-left: $grid-gutter;

    @include media(">=desktop") {
      padding-left: 25px;
    }
  }

  &__subgroup &__subtitle-featured {
    font-weight: 400;
  }

  &__subgroup-title {
    font-weight: 700;

    &:hover {
      background-color: transparent;
    }

    .bs-download__link {
      @include media("<tablet") {
        padding: 15px 0;
      }
    }
  }

  &__subgroup-amount {
    font-size: 14px;
    white-space: nowrap;
  }

  &--accordion {
    .bs-download__title {
      padding: 0;
    }

    .bs-download__trigger {
      border-bottom: 1px solid $c-gray-dark;
      display: block;
      padding: $grid-gutter 10px;
      text-align: inherit;
      transition: background .3s;
      width: 100%;

      &:hover {
        background-color: $c-gray-lighter;
        transition: background .3s;
      }

      &:focus {
        outline-offset: -1px;
      }
    }

    .bs-download__content {
      height: 0;
      position: relative;
      transition: ease-in-out .3s;
      visibility: hidden;
    }

    &.bs-download__list,
    .bs-download__list {
      &--open {
        .bs-download__trigger {
          border-color: transparent;
        }

        .bs-download-accordion__icon {
          transform: rotate(180deg);
        }

        .bs-download__content {
          height: auto;
          visibility: visible;
        }
      }
    }
  }
}

@keyframes bounceUpDown {
  10% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}
