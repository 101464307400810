.bs-video {

  &__container {
    height: 0;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe,
    object,
    embed {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
