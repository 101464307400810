//-------------------------------
// Browser Update
// Mensagem de atualização de navegadores antigos
//-------------------------------
.buorg {
  bottom: 0;
  display: table;
  height: 100%;
  overflow: hidden;
  position: fixed;
  text-align: center;
}

.buorg-pad {
  display: table-cell;
  vertical-align: middle;
}
