/**
 * Typer
 * https://github.com/qodesmith/typer
 */
.typer {
  min-height: 2ex;
  position: relative;
}
.typer::after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 0.1em;
  height: 1.1em;
  background-color: currentColor;
  margin-left: 0.1em;
}
.cursor-block::after {
  width: 0.9ex;
}
.cursor-soft::after {
  animation: softblink 0.7s infinite;
}
.cursor-hard::after {
  animation: hardblink 0.7s infinite;
}
.no-cursor::after {
  content: none;
}
@keyframes softblink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hardblink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.white-space {
  white-space: pre-wrap;
}
