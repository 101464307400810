//-------------------------------
// Footer
// Componente do rodapé padrão
//-------------------------------

// Variables
$footer-title-size: rem(16);
$footer-text-size: rem(14);

.bs-footer {
  background-color: $c-white;
  display: block;
  width: 100%;

  // Área superior do rodapé
  &__top {
    background-color: $c-gray-lighter;
    font-size: $footer-text-size;
    width: 100%;

    @include media(">=desktop") {
      padding: ($grid-gutter * 2) 0;
    }

    &-col {

      &+& {

        @include media(">=tablet") {
          border-top: 1px solid $c-gray;
          margin-top: $grid-gutter;
          padding-top: $grid-gutter * 3;
        }

        @include media(">=desktop") {
          border: {
            left: 1px solid $c-gray;
            top: 0;
          }
          margin-top: 0;
          padding: {
            left: $grid-gutter * 2;
            top: 0;
          }
        }
      }
    }
  }

  // Blocos de informação
  &__info {}

  // Mapa do Site
  &__map {
    // Aplicado quando existe a parte Data ao lado direito do Mapa,
    // desta forma as colunas ficam com largura de 50%, invés dos 33% padrão.
    &--half {
      .bs-footer-nav__group,
      .bs-footer-nav__group-out {
        @include media(">=tablet") {
          @include flex-basis(50%);
        }
      }
    }
  }

  // Área inferior do rodapé
  &__bottom {
    border-top: 1px solid $c-gray;
  }

  // Área para link de âncora
  &__anchor {
    display: flex;
    justify-content: center;
    width: 100%;

    @include media(">=desktop") {
      display: none;
    }
  }

  // Grupo de informações da área de informação
  &-info {

    &__list {
      margin: 0 (-$grid-gutter);
      padding: ($grid-gutter * 2) 0;

      @include media(">=tablet") {
        display: flex;
        flex-wrap: wrap;
      }

      @include media(">=desktop") {
        padding: 0;
      }
    }

    &__item {
      margin-bottom: $grid-gutter;
      padding: 0 $grid-gutter;

      @include media(">=tablet") {
        flex-grow: 1;
        width: 50%;
      }

      @include media(">=desktop") {
        flex: 0 1 auto;
        width: auto;
      }
    }

    &__title {
      color: $c-gray-darker;
      display: block;
      font-family: $font-title;
      font-size: $footer-title-size;
      font-weight: fw("bold");
      line-height: 1.5;
      margin-bottom: 10px;
      width: 100%;
    }

    &__text {
      font-size: $footer-text-size;
      margin-bottom: 10px;

      a {
        color: $c-red;

        &:hover {
          color: $c-red-dark;
        }
      }
    }

    &__link {
      color: $c-primary;
      display: block;
      font-family: $font-title;
      font-size: $footer-text-size;
      font-weight: fw("medium");
      margin-bottom: 10px;

      &:hover {
        color: $c-primary-dark;
        text-decoration: none;
      }

      i {
        color: $c-gray-dark;
        font-size: rem(11);
        margin-right: 5px;
      }
    }
  }

  // Grupo de links do menu de mapa do site
  &-nav {
    margin: 0 (-$grid-gutter);

    @include media(">=tablet") {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$grid-gutter);
    }

    &__group {
      border-top: 1px solid $c-gray;
      overflow: hidden;
      position: relative;
      transition: height $ease-in-out;

      @include media(">=tablet") {
        @include flex-basis(33.3333%);
        border-top: 0;
        padding: 0 $grid-gutter;
      }

      &::after {
        color: $c-gray-darker;
        // icon-seta-direita-b
        content: "\e91b";
        display: inline-block;
        font-family: "bradesco_v2";
        height: 16px;
        line-height: 1;
        position: absolute;
        right: 15px;
        top: 22px;
        transition: transform $ease-in-out;
        width: 16px;

        @include media(">=tablet") {
          display: none;
        }
      }

      &:last-child {
        border-bottom: 1px solid $c-gray;

        @include media(">=tablet") {
          border-bottom: 0;
        }
      }

      &--is-open {
        &::after {
          transform: rotate(180deg);
          transition: transform $ease-in-out;
        }
      }
    }

    &__group-out {
      text-align: center;

      @include media(">=tablet") {
        @include flex-basis(33.3333%);
      }

      .bs-footer-nav__item {
        padding: 0;

        @include media(">=tablet") {
          padding: 0 $grid-gutter;
        }
      }

      .bs-footer-nav__list {
        padding: 0;
      }
    }

    &__title {
      color: $c-gray-darker;
      display: block;
      font-family: $font-title;
      font-size: $footer-title-size;
      font-weight: fw("bold");
      height: 60px;
      line-height: rem(30);
      padding: $grid-gutter;
      width: 100%;

      @include media(">=tablet") {
        border-bottom: 1px solid $c-gray;
        height: auto;
        line-height: 1.5;
        padding: 0 0 $grid-gutter;
      }
    }

    &__list {
      padding-bottom: ($grid-gutter * 2);

      @include media(">=tablet") {
        padding-top: $grid-gutter;
      }
    }

    &__item {
      padding: 0 $grid-gutter;

      @include media(">=tablet") {
        margin-bottom: $grid-gutter;
        padding: 0;
      }
    }

    &__link {
      color: $c-gray-darker;
      display: block;
      font-family: $font-text;
      font-size: $footer-text-size;
      font-weight: fw("medium");
      padding: $grid-gutter;

      &:hover {
        color: $c-primary;
        text-decoration: none;
      }

      @include media(">=tablet") {
        padding: 0;
      }
    }
  }

  // Grupo de informações e links localizado na parte do mapa do site, mas com estilo parecido com .bs-footer-info
  &-data {
    &__group {
      // border-top: 1px solid $c-gray;
      overflow: hidden;
      position: relative;
      transition: height $ease-in-out;

      @include media(">=tablet") {
        @include flex-basis(50%);
        border-top: 0;
        padding: 0 $grid-gutter;
      }

      @include media(">=desktop") {
        @include flex-basis(100%);
      }

      &::after {
        color: $c-gray-darker;
        // icon-seta-direita-b
        content: "\e91b";
        display: inline-block;
        font-family: "bradesco_v2";
        height: 16px;
        line-height: 1;
        position: absolute;
        right: 15px;
        top: 22px;
        transition: transform $ease-in-out;
        width: 16px;

        @include media(">=tablet") {
          display: none;
        }
      }

      &:last-child {
        border-bottom: 1px solid $c-gray;

        @include media(">=tablet") {
          border-bottom: 0;
        }
      }

      &--is-open {
        &::after {
          transform: rotate(180deg);
          transition: transform $ease-in-out;
        }
      }
    }

    &__list {
      padding-bottom: ($grid-gutter * 2);

      @include media(">=desktop") {
      }
    }

    &__item {
      padding: 0 $grid-gutter;
      width: 100%;

      @include media(">=tablet") {
        flex-grow: 1;
        padding: 0;
      }

      @include media(">=desktop") {
        margin-bottom: $grid-gutter;
      }
    }

    &__title {
      color: $c-gray-darker;
      display: block;
      font-family: $font-title;
      font-size: $footer-title-size;
      font-weight: fw("bold");
      height: 60px;
      line-height: rem(30);
      padding: $grid-gutter;
      width: 100%;

      @include media(">=tablet") {
        border-bottom: 1px solid $c-gray;
        height: auto;
        line-height: 1.5;
        margin-bottom: 15px;
        padding: 0 0 $grid-gutter;
      }
    }

    &__subtitle {
      color: $c-gray-darker;
      display: block;
      font-family: $font-title;
      font-size: $footer-title-size;
      font-weight: fw("bold");
      line-height: rem(30);
      margin-bottom: 10px;
      width: 100%;

      @include media(">=tablet") {
        height: auto;
        line-height: 1.5;
      }
    }

    &__text {
      font-size: $footer-text-size;
      margin-bottom: 10px;

      &--big {
        font-size: $footer-title-size;
      }
    }

    &__link {
      color: $c-primary;
      font-family: $font-title;
      font-size: $footer-text-size;
      font-weight: fw("medium");

      &:hover {
        color: $c-primary-dark;
        text-decoration: none;
      }

      i {
        color: $c-gray-dark;
        font-size: rem(11);
        margin-right: 5px;
      }
    }

    &__content {
      margin-bottom: 30px;
    }
  }

  &-tel {
    &__number {
      font-size: $footer-title-size;

      a {
        color: $c-primary;

        &:hover{
          color: $c-primary-dark;
        }
      }
    }

    &__item {
      margin-bottom: $grid-gutter;
    }

    &__description {}
  }

  // Lista de links
  &-links {
    background-color: $c-white;

    &__list {
      align-items: center;
      border-bottom: 1px solid $c-gray;
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$grid-gutter);
      padding: (2 * $grid-gutter) 0;

      @include media(">=tablet") {
        justify-content: center;
        text-align: center;
      }

      @include media(">=wide") {
        width: auto;
      }

      li {
        font-size: rem(12);
        padding: $grid-gutter;
        position: relative;

        @include media(">=desktop") {
          font-size: rem(14);
          width: auto;

          &::after {
            display: block;
          }
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }

      a {
        color: $c-primary;
        font-family: $font-title;
        font-weight: fw("bold");

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-links ~ &__bottom,
  &__top ~ &__bottom {
    @include media(">=desktop") {
      border-top: 0;
    }
  }

  // Link de âncora para o topo
  &-anchor {
    color: $c-primary;
    display: block;
    font-family: $font-text;
    font-size: rem(14);
    font-weight: fw("bold");
    padding: 25px 0;
    text-align: center;

    i {
      display: block;
      font-size: rem(20);
      margin-bottom: 5px;
    }
  }

  // Copyright text
  &-rights {
    border-top: 1px solid $c-gray;
    display: block;
    font-family: $font-title;
    font-size: rem(12);
    font-weight: fw("book");
    padding: ($grid-gutter * 2) 0;
    text-align: center;
    width: 100%;

    @include media(">=tablet") {
      border-top: 0;
      padding: $grid-gutter 0;
      text-align: left;
    }
  }

  // Redes sociais
  &-social {

    &__list {
      justify-content: space-around;
      margin: auto;
      max-width: 100%;
      padding: ($grid-gutter * 2) 0;
      text-align: center;
      width: 290px;

      @include media(">=tablet") {
        flex-direction: row;
        justify-content: flex-end;
        margin: 0 auto;
        padding: $grid-gutter 0;
        width: 100%;
      }

      li {

        @include media(">=tablet") {
          justify-content: center;
          margin-right: 5px;
          width: auto;

          &:last-of-type {
            margin-right: 0;
          }
        }

        @include media(">=desktop") {
          margin-right: 5px;
        }

        a {
          color: $c-gray-darker;
          font-size: rem(18);
          padding: $grid-gutter;

          &:hover {
            color: $c-primary;
          }
        }
      }
    }
  }

  &--has-no-map {
    .bs-footer-info__item {
      @include media(">=desktop") {
        width: 33.33%;
      }
    }
  }
}
