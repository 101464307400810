.bs-news {

  &__container {
    margin: 0 auto;
    width: 100%;

    @include media(">=tablet") {
      width: 82%;
    }
  }

  &__meta {
    align-items: center;
    border-bottom: 1px solid $c-gray-light;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 30px;

    @include media(">=desktop") {
      flex-wrap: nowrap;
    }

    .bs-media__meta {
      @include media(">=desktop") {
        margin: 0;
      }
    }

    .bs-social__link {
      color: $c-primary;

      &:hover {
        background-color: $c-gray-lighter;
      }
    }

    .bs-news__anchor-icon {
      font-size: 18px;
      font-weight: 700;
      transform: rotateZ(-45deg);
    }
  }

  &__title {
    margin-bottom: 30px;
  }

  &__image {
    margin-bottom: 30px;

    img {
      width: 100%;
    }
  }

  &__video {
    margin-bottom: 30px;
  }

  &__article {}

  &__tags {}

  &-list {
    &--filter {
      background-color: $c-gray-lighter;
      padding: 10px 15px;
    }
  }

  // sublocks
  &-tags {
    margin: 30px 0 0;

    &__title {
      display: inline-flex;
      font-family: $font-title;
      font-size: rem(16);
      font-weight: fw('medium');
    }

    &__list {
      display: inline-flex;
      margin: 0 10px;
    }

    &__link {
      background: $c-gray;
      border-radius: 15px;
      display: inline-flex;
      font-size: 14px;
      margin: 0 5px;
      padding: 4px 15px;
      transition: .3s;

      &:hover {
        background-color: darken($c-gray, 8%);
      }
    }
  }
}
