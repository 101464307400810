//-------------------------------
// Conteúdo (Conteúdo Box)
// Componente de conteúdo "controlado", sem utilizar texto rico do portal WCM
//-------------------------------
.bs-content {

  // Seção do componente
  &__section {
    padding: 60px 0;
  }

  // Lista que agrupa os itens
  &__list {
    margin: 0 (-$grid-gutter);
  }

  // Cada content item
  &__item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  // Wrapper interno do item (colunas)
  &__box {
    padding: $grid-gutter;

    // @include media(">=tablet") {
    //
    //   &:nth-child(1) {
    //     @include flex-basis(66.666%);
    //   }
    //
    //   &:nth-child(2) {
    //     @include flex-basis(33.333%);
    //   }
    // }
  }

  // Wrapper de texto
  &__text {
    font-size: 16px;
    line-height: 1.5;

    p {
      margin-bottom: $grid-gutter * 2;
    }
  }

  // Título do item (hierarquia default: 3)
  &__title {}

  // Subtítulo (texto comum destacado)
  &__subtitle {
    color: $c-gray-darker;
    font-family: $font-title;
    font-size: rem(18);
    font-weight: fw("bold");
    line-height: 1.22;
    margin-bottom: 10px;

    @include media(">=tablet") {
      font-size: rem(22);
      line-height: 1.18;
    }
  }

  // Texto descritivo
  &__description {}

  // Imagem usada dentro do texto. Adiciona margin bottom para que o espaçamento fique correto
  &__img {
    margin-bottom: $grid-gutter * 2;
  }

  // Wrapper de botão
  &__button {}

  //-------------------------------
  // Modifiers de componente (section)
  //-------------------------------

  // Proporção de boxes
  &--box-ratio {

    &-2-2 {

      .bs-content__box {
        @include media(">=desktop") {
          @include flex-basis(50%);
        }
      }
    }

    &-1-2 {

      .bs-content__box:nth-child(1) {
        @include media(">=desktop") {
          @include flex-basis(33.333%);
        }
      }

      .bs-content__box:nth-child(2) {
        @include media(">=desktop") {
          @include flex-basis(66.666%);
        }
      }
    }

    &-2-1 {

      .bs-content__box:nth-child(1) {
        @include media(">=desktop") {
          @include flex-basis(66.666%);
        }
      }

      .bs-content__box:nth-child(2) {
        @include media(">=desktop") {
          @include flex-basis(33.333%);
        }
      }
    }

    &-1-3 {

      .bs-content__box:nth-child(1) {
        @include media(">=desktop") {
          @include flex-basis(25%);
        }
      }

      .bs-content__box:nth-child(2) {
        @include media(">=desktop") {
          @include flex-basis(75%);
        }
      }
    }

    &-3-1 {

      .bs-content__box:nth-child(1) {
        @include media(">=desktop") {
          @include flex-basis(75%);
        }
      }

      .bs-content__box:nth-child(2) {
        @include media(">=desktop") {
          @include flex-basis(25%);
        }
      }
    }
  }
}

.bs-portal-content {
  a:not(.bs-button) {
    color: $c-primary;
    text-decoration: underline;
    transition: 0.2s;
  
    &:hover {
      color: $c-primary-dark;
      text-decoration: none;
    }
  }
}