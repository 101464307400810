//-------------------------------
// Menu Dropdown
// Cria um container que abre com efeito dropdown, contendo
// header, body e footer próprio e podendo receber outros
// componentes (em geral, listas)
//
// TODO: Tornar esse componente reutilizável como um menu dropdown. Hoje ele contém
// estilos da lista de notificação, que devem ser separadas em um arquivo específico
// da lista de notificações.
//-------------------------------
.bs-dropdown-menu {

  @include media(">=tablet") {
    position: relative;
  }

  // Header do container do dropdown
  &__header {
    background-color: $c-gray-lighter;
    display: flex;
    justify-content: space-between;
    padding: $grid-gutter 10px $grid-gutter $grid-gutter;

    .icon {
      color: $c-primary;
      font-size: rem(26);

      &:hover {
        color: $c-primary-dark;
      }
    }

    .bs-button-link {
      font-size: rem(14);

      &:hover {
        color: $c-primary-dark;
      }
    }
  }

  // Body do container do dropdown
  &__body {}

  // Footer do container do dropdown
  &__footer {
    .bs-button {
      border: {
        top-left-radius: 0;
        top-right-radius: 0;
      }
      font-size: rem(14);
      text-align: center;
      transition: background $transition-time;
      width: 100%;

      .icon {
        font-size: rem(12);
        margin-left: 5px;
      }
    }
  }

  // Botão que abre o container
  &__trigger {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    padding: 0 $grid-gutter;
    position: relative;
    text-align: center;
    transition: background-color $transition-time;

    @include media(">=tablet") {
      height: 80px;
    }

    .icon {
      color: $c-gray-darker;
      font-size: rem(24);

      @include media(">=tablet") {
        font-size: rem(40);
      }
    }

    &:hover {
      background-color: $c-gray-lightest;
    }
  }

  // Conteúdo interno do container
  &__content {
    background-color: $c-white;
    border-bottom: {
      left-radius: 5px;
      right-radius: 5px;
    }
    box-shadow: 0px 4px 6px 0px rgba(11, 5, 12, 0.2),inset 0px 1px 0px 0px rgba(232, 233, 236, 0.004);
    max-width: 100vw;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 120px;
    transition: opacity $transition-time, visibility $transition-time;
    visibility: hidden;
    width: 320px;

    @include media(">=tablet") {
      top: 80px;
      max-width: initial;
      width: 370px;
    }
  }

  &__item {
    border-top: 1px solid $c-gray-light;
    display: block;
    position: relative;
    transition: $transition-time;

    &:hover {
      background-color: $c-gray-lighter;
    }

    &:focus {
      > a {
        outline-offset: -5px;
        outline-style: dotted;
        outline-width: 1px;
      }
    }

    > a {
      display: block;
      height: 100%;
      padding: $grid-gutter;
    }
  }

  &__title {
    font: {
      family: $font-title;
      size: rem(16);
      weight: fw("bold");
    }
    position: relative;
  }

  &__description {
    color: $c-gray-darker;
    font: {
      size: rem(14);
      weight: fw("light");
    }
    line-height: 1.42;
  }

  &__detail {
    color: $c-gray-dark;
    font: {
      size: rem(12);
      weight: fw("light");
    }
    line-height: 2;
  }

  &__options {
    bottom: 15px;
    position: absolute;
    right: 3px;
  }

  &__options-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    width: 40px;
    z-index: z("absolute");

    span {
      background-color: $c-gray-dark;
      border-radius: 50%;
      display: block;
      height: 3px;
      margin: 0 auto;
      position: relative;
      transition: background-color $transition-time;
      width: 3px;
      transition-delay: .05s;

      &::after,
      &::before {
        background-color: $c-gray-dark;
        border-radius: 50%;
        content: "";
        display: block;
        height: 3px;
        position: absolute;
        transition: background-color $transition-time;
        width: 3px;
      }

      &::after {
        bottom: -8px;
        transition-delay: .1s;
      }

      &::before {
        top: -8px;
      }
    }

    &:hover {
      span,
      span::after,
      span::before {
        background-color: $c-gray-darker;
      }
    }

    &:focus {
      outline-style: dotted;
      outline-width: 1px;
    }

  }

  &--open {
    .bs-dropdown-menu__trigger {
      background-color: $c-gray-lighter;
    }

    .bs-dropdown-menu__content {
      opacity: 1;
      visibility: visible;
    }
  }
}
