.bs-md {
  background-color: $c-white;
  box-shadow: 0px 0px 6px 0px rgba($c-black, 0.31);
  overflow: auto;
  position: relative;
  width: 100%;

  // https://markus.oberlehner.net/blog/building-a-responsive-priority-plus-horizontal-scroll-navigation/
  &__shadow {
    background: linear-gradient(to right, rgba($c-white, 0), $c-white 80%);
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.1s;
    width: 4 * $grid-gutter;

    &.is-visible {
      opacity: 1;
    }

    &--start {
      left: 0;
      transform: rotate(180deg);
    }

    &--end {
      right: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
  }

  &__menu {
    background-color: $c-white;
    border-top: 3px solid $c-primary-light;
    box-shadow: 0px 0px 6px 0px rgba($c-black, 0.31);
    flex-direction: column;
    left: $grid-gutter;
    opacity: 0;
    position: fixed;
    transform: translateY(5px);
    // transition: opacity .15s;
    visibility: hidden;
    width: calc(100% - #{2 * $grid-gutter});
    z-index: z("absolute");

    @include media(">=phone") {
      left: 0;
      width: 220px;
    }

    > .bs-md__item {
      border-bottom: 1px solid $c-gray-light;
      border-right: 0;

      &:last-child {
        border: 0;
      }
    }

    &__link,
    &__back {
      padding: 10px;
    }

    &--open {
      display: block;
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0px);
      visibility: visible;

      > .bs-md__submenu {
        display: block;
      }
    }

    &--sub-open {
      > li {
        display: none;
      }
    }

    &--sortable {
      > .bs-md__item {
        > .bs-md__link {
          padding-left: 3 * $grid-gutter;

          &::before {
            background: center / 10px 17px no-repeat url("../icons/drag.svg");
            content: "";
            cursor: grab;
            top: 0;
            display: inline-block;
            height: 100%;
            left: 0;
            position: absolute;
            width: 40px;

            &:active {
              cursor: grab;
            }
          }
        }

        &.gu-transit {
          cursor: grabbing;
        }
      }
    }

    &--favorite {
      border-top: 0;
      box-shadow: 0px 4px 6px 0px rgba($c-black, 0.31);

      @include media(">=tablet") {
        width: 370px;
      }
    }
  }

  &__submenu {
    display: none;
    z-index: z("default");

    > .bs-md__item {
      border-bottom: 1px solid $c-gray-light;
      border-right: 0;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__back {
    align-items: center;
    background-color: $c-gray-light;
    display: flex;
    font-size: rem(14);
    font-weight: fw("semibold");
    padding: 10px $grid-gutter;

    &::before {
      content: "\ea6a";
      display: inline-block;
      font-family: $font-icon;
      font-size: rem(12);
      margin-right: 5px;
    }
  }

  &__item {
    border-right: 1px solid $c-gray-light;
    display: block;
    color: $c-gray-darker;
    font: {
      family: $font-title;
      size: rem(16);
      weight: fw("medium");
    }
    line-height: 1.2;
    position: relative;

    #{&}--sub-open {
      display: block;

      > .bs-md__submenu {
        background-color: $c-white;
        display: block;
      }

      > a {
        display: none;
      }
    }

    &--active {
      background-color: $c-gray-lighter;
    }
  }

  &__link {
    align-items: center;
    display: flex;
    padding: $grid-gutter (3 * $grid-gutter) $grid-gutter $grid-gutter;
    height: 100%;
    transition: .15s;

    &:hover {
      background-color: $c-gray-light;
    }

    &--arrow {
      &::after {
        display: inline-block;
        font-family: $font-icon;
        font-size: 12px;
        position: absolute;
        right: $grid-gutter;
      }

      &-right,
      &-down {
        @extend .bs-md__link--arrow;
      }

      &-right {
        &::after {
          content: "\ea69";
        }
      }

      &-down {
        &::after {
          content: "\ea67";
        }
      }
    }
  }

  &__icon {
    &:first-child {
      color: $c-red;
      font-size: 22px;
      margin-right: 5px;
    }

    &:last-child {
      color: $c-gray-darker;
      font-size: 12px;
      margin: 0 6px;
    }
  }

  &__favorite {
    background-color: $c-gray-lighter;
    line-height: 1.4;
    position: relative;

    &-link {
      display: block;
      padding: 18px $grid-gutter;
    }

    .bs-md__icon {
      font-size: rem(24);
    }

    .bs-md__icon,
    a {
      color: $c-blue;
    }

    a {
      font-weight: fw("bold");
    }
  }

  &--large {
    .bs-md__link {
      font-weight: 600;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .bs-md__back {
      padding-bottom: $grid-gutter;
      padding-top: $grid-gutter;
    }
  }

  &--border-left {
    .bs-md__item {
      &:first-child {
        border-left: 1px solid $c-gray-light;
      }
    }
  }

  &--margin {
    margin-bottom: $grid-gutter;
    margin-top: $grid-gutter;
  }

  &__icon-sub {
    color: $c-red;
    font-size: 20px;
    padding-right: 5px;
  }

  &--active-list {

    > .bs-md__link {
      border-top: 5px solid $c-red;
      padding-top: 15px;
    }
  }
}
