//-------------------------------
// Menu de Usuários [deprecated]
// Lista de usuários
//
// Depreciado porque faz parte do header-logado que foi descontinuado.
//-------------------------------
.bs-user-menu {
  display: flex;
  height: 100%;

  &__item {
    align-items: center;
    border-left: 1px solid $c-gray-light;
    display: flex;
    position: relative;
    transition: $transition-time;

    &:last-of-type {
      border-right: 1px solid $c-gray-light;
    }

    &:hover {
      background-color: $c-gray-lighter;
    }

    &--open {
      background-color: #fafafa;

      .bs-user-menu__button {
        .icon {
          color: $c-gray-dark;
        }
      }

      .bs-user-menu__dropdown {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
      }
    }
  }

  &__button {
    height: 100%;
    padding: 0 20px;
    position: relative;
    z-index: 2;

    @include media(">=tablet") {
      padding: 0 (2 * $grid-gutter);
    }

    @include media(">=tablet") {
      padding: 0 25px;
    }

    .icon {
      color: $c-gray;
      font-size: 46px;

      @include media(">=tablet") {
        font-size: 40px;
      }
    }

    .bs-icon-notification {
      bottom: 29px;
      position: absolute;
      right: 18px;

      @include media(">=tablet") {
        bottom: 10px;
        right: 15px;
      }
    }

    &--has-notification {
      .bs-icon-notification {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
      }
    }
  }

  &__dropdown {
    background-color: #fafafa;
    border: {
      bottom-left-radius: 5px;
      bottom-right-radius: 5px;
    }
    box-shadow:  0 4px 7px 0px rgba($c-black, .15);
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 100px;
    transform: scale(.98) translateY(-5px);
    transition: $transition-time;
    visibility: hidden;
    width: 100%;
    overflow: auto;
    max-height: 400px;
    z-index: 1;

    @include media(">=tablet") {
      left: inherit;
      border-top: 0;
      position: absolute;
      right: -1px;
      top: 100%;
      width: 320px;
    }

    &.bs-box-links {
      position: absolute;
      left: inherit;
      width: 220px;
    }

    // Aplica cor ao fundo da seleção de texto
    &::selection {
      background-color: lighten($c-secondary-light, 12%);
      color: $c-white
    }

    &::-webkit-scrollbar {
      width: 6px;  /* for vertical scrollbars */
      height: 6px; /* for horizontal scrollbars */
    }

    &::-webkit-scrollbar-track {
      background: $c-gray-light;
    }

    &::-webkit-scrollbar-thumb {
      background: $c-gray-dark;
    }

    .icon {
      color: $c-primary;
      transform: none;
      position: relative;
      right: 0;
    }
  }

  .bs-media__list {
    margin: 0;
  }

  .bs-media__box {
    display: flex;
  }

  .bs-media__text {
    padding: 0;
  }

  .bs-media__item {
    border-bottom: 1px solid $c-gray;

    &:last-child {
      border-bottom: 0;
    }

    .icon {
      font-size: 40px;
    }
  }

  .bs-media__figure {
    @include flex-basis(70px);
    padding: 0 $grid-gutter 0 0;
  }

  .bs-media__subtitle {
    font-size: rem(14);
    margin-bottom: 5px;
  }

  .bs-media__description {
    font-size: rem(14);
  }
}
