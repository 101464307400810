.bs-gallery {
  position: relative;
  width: 100%;

  @include media(">=tablet") {
    display: flex;
    height: 400px;
    order: 1;
  }

  @include media(">=desktop") {
    height: 560px;
  }

  @include media(">=wide") {
    padding: 0 ($grid-gutter * 4);
  }

  &__list {

    @include media(">=tablet") {
      flex: 1 1 100%;
      height: 100%;
      order: 1;
      padding-left: $grid-gutter * 2;
      // width: 100%;
    }
  }

  &__item {
    align-items: center;
    display: none;
    flex-direction: column;
    justify-content: center;

    @include media(">=tablet") {
      height: 100%;
    }

    &--active {
      display: flex;
    }
  }

  &__figure {
    align-items: center;
    display: flex;
    flex: 1 1;
    height: 300px;
    overflow: hidden;
    width: 100%;

    @include media(">=tablet") {

    }
  }

  &__image {
    display: block;
    // flex: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    // object-fit: cover;
  }

  &__video {
    display: block;
    height: 100%;
    width: 100%;

    .bs-video,
    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &__footer {
    align-items: flex-start;
    display: flex;
    flex: 0 1 auto;
    padding-top: $grid-gutter;
    width: 100%;
  }

  &__caption {}

  &__pagination {
    background-color: rgba(0,0,0,0.6);
    color: $c-white;
    display: flex;
    font-size: 18px;
    height: 50px;
    left: 0;
    padding: 12px;
    position: absolute;
    top: 0;
    z-index: 3;

    @include media(">=tablet") {
      left: 230px;
    }

    @include media(">=wide") {
      left: 270px;
    }
  }

  &__nav {
    @include scrollbar;
    display: flex;
    overflow: auto;
    padding: $grid-gutter 0;

    @include media(">=tablet") {
      display: block;
      flex: 1 0 200px;
      height: 100%;
      order: 0;
      padding: 0;
      // width: 200px;
    }
  }

  &__thumb {
    flex: 1 0 auto;
    height: 120px;
    margin-right: $grid-gutter;
    width: 170px;

    @include media(">=tablet") {
      margin-bottom: $grid-gutter;
      margin-right: 0;
    }

    img {
      @include object-fit(contain);
      display: block;
      height: 100%;
      width: 100%;
    }

    &--active {
      border: 1px solid $c-red;
    }

    &--video {
      position: relative;

      &::before {
        bottom: 0;
        color: $c-white;
        content: "\e993";
        display: block;
        font-family: "bradesco_v2";
        font-size: 60px;
        height: 60px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
      }
    }
  }
}
