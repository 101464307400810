.bs-loading {
  display: block;
  padding: $grid-gutter;
  text-align: center;
  width: 100%;
}

.bs-loader {
  @include loader;
  display: inline-block;
  height: 30px;
  position: relative;
  width: 30px;

  &--large,
  &--larger { // deprecated
    height: 90px;
    width: 90px;
  }

  &--white {

    &::before,
    &::after {
      border-color: $c-white;
    }
  }
}
