.pa-ans-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: block;
    margin: 0;
    padding: 15px;
    width: 100%;

    @include media(">=tablet") {
      width: 50%;
    }

    @include media(">=desktop") {
      width: 33.33%;
    }
  }

  &__button {
    display: block;
    width: 100%;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }

    img {
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      vertical-align: middle;
      width: 20px;
    }
  }
}
