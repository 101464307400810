//-------------------------------
// Tooltip
// A custom implementation of tippy.js
// @link https://atomiks.github.io/tippyjs/themes/
//-------------------------------
.pa-tooltip {
  display: inline-block;
}

.tippy-tooltip.pa-theme {
  background-color: $c-gray-darker;
  color: $c-gray-lighter;

  .tippy-backdrop {
    // background-color: red;
  }

  .tippy-arrow {
    border-top-color: $c-gray-darker;
  }

  .tippy-content {
    // background-color: blue;
  }
}


.pa-js-hospitals-table {

  td .pa-tooltip .icon-info-quarto {
    font-size: 17px;
  }
}
