.bs-search-empty {
  margin: 30px 0;
  text-align: center;

  &__wrapper {
    border-bottom: 1px solid $c-gray;
    margin: 0 auto 30px;
    padding-bottom: 30px;
  }

  &__title {
    color: $c-red;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: $grid-gutter;
    width: 100%;

    @include media(">=tablet") {
      font-size: 60px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;

    @include media(">=tablet") {
      font-size: 24px;
    }
  }

  &__description {
    font-size: 16px;

    @include media(">=tablet") {
      font-size: 20px;
    }
  }

  &__tag {
    color: $c-red;
    text-decoration: underline;

    &:hover {
      color: $c-red-dark;
      text-decoration: initial;
    }
  }

  .bs-button {
    margin: 0 10px;
  }
}
