//-------------------------------
// Ícone (old) [deprecated]
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-icon {
  display: inline-flex;
  // height: 25px;
  // width: 25px;

  i {
    align-items: center;
    display: inline-flex;
  }
}
