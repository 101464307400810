//-------------------------------
// Busca
// Form de busca do header
//-------------------------------
.pa-search {
  display: flex;
  justify-content: center;
  width: 100%;

  &__form {
    align-items: center;
    background-color: $c-gray-lighter;
    border: 1px solid $c-gray;
    border-radius: 5px;
    display: flex;
    height: auto;
    padding: 0;
    position: relative;
    transition: $transition-time;
    width: 100%;
    z-index: 1;

    > wlauto {
      width: 100%;
    }
  }

  &__field {
    background-color: transparent;
    display: block;
    font-size: rem(16);
    font-weight: fw("light");
    height: 38px;
    line-height: 28px;
    padding: 5px 10px;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $c-gray-light;
    }
  }

  &__button {
    align-items: center;
    background-color: transparent;
    color: $c-gray-dark;
    display: flex;
    font-size: rem(26);
    height: 38px;
    justify-content: center;
    text-align: center;
    transition: height $transition-time;
    width: 60px;

    &:hover,
    &:focus {
      color: $c-gray-darker;
    }
  }

  &__toggle {
    display: none;
  }

  &__submit {
    display: flex;
  }

  &__dropdown {
    left: 0;
    overflow: hidden;
    padding: $grid-gutter;
    position: absolute;
    top: calc(100% - 21px);
    width: 100%;

    @include media(">=tablet") {
      padding: 0 ($grid-gutter * 2);
      top: calc(100% - 16px);
    }

    @include media(">=desktop") {
      padding: 0;
      top: calc(100% - 1px);
    }
  }

  // Os elementos dropdown e suggestions não estão sendo usados atualmente,
  // consequentemente, não foram atualizados para o novo modificador "invisible".
  // Caso seja necessário usar, deverá ser adaptado para a nova realidade.
  &-suggestions {
    background-color: $c-white;
    border: 1px solid $c-gray;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: none;
    width: 100%;

    &__title {
      background-color: $c-gray-lighter;
      border-bottom: 1px solid $c-gray;
      color: $c-primary;
      display: block;
      font-family: $font-title;
      font-size: rem(12);
      font-weight: fw("medium");
      line-height: 18px;
      min-height: 30px;
      padding: 5px 10px;

      &:empty {
        display: none;
      }
    }

    &__list {}

    &__item {
      &--active {
        .pa-search-suggestions__link {
          background-color: $c-gray-lighter;
        }
      }
    }

    &__link {
      color: $c-gray-darker;
      display: block;
      font-size: rem(14);
      font-weight: fw("regular");
      line-height: 20px;
      padding: 10px;
      width: 100%;

      &:hover,
      &a:focus {
        background-color: $c-gray-lighter;
      }
    }

    &__term {
      display: inline-block;
      margin-right: 5px;

      b {
        font-weight: fw("bold");
      }
    }

    &__location {
      color: $c-gray-dark;
      display: inline-block;
      font-size: rem(12);
    }

    &--open {
      display: block;
    }

    &--loading {
      .pa-search-suggestions__list {
        &::before {
          background: transparent url("../img/loading.svg") center / 40px no-repeat;
          content: "";
          display: block;
          height: 40px;
          margin: auto;
          width: 40px;
        }
      }
    }
  }

  //-------------------------------
  // Modifiers
  //-------------------------------

  &--invisible {

    &-to-mobile {
      @include media("<tablet") {
        flex: 0;

        .pa-search {
          &__form {
            border: 0;
            border-radius: 0;
            height: 70px;
            left: 0;
            opacity: 0;
            padding: $grid-gutter;
            position: absolute;
            top: 0;
            visibility: hidden;
          }

          &__field {
            background-color: $c-white;
            border: 1px solid $c-gray-light;
            border-radius: 3px;
            height: 100%;
          }

          &__button {
            height: 100%;
          }

          &__toggle {
            display: block;
            height: 60px;
          }

          &__submit {
            display: none;
          }
        }
      }
    }

    &-to-tablet {
      @include media("<desktop") {
        flex: 0;

        .pa-search {

          &__form {
            border: 0;
            border-radius: 0;
            height: 70px;
            left: 0;
            opacity: 0;
            padding: $grid-gutter;
            position: absolute;
            top: 0;
            visibility: hidden;

            @include media(">=tablet") {
              height: 80px;
              padding: 20px ($grid-gutter * 2);
            }
          }

          &__field {
            background-color: $c-white;
            border: 1px solid $c-gray;
            height: 100%;

            @include media("<=tablet") {
              border: 1px solid $c-gray-light;
              border-radius: 3px;
            }
          }

          &__button {
            height: 100%;

            @include media(">=tablet") {
              font-size: rem(32);
              width: 90px;
            }
          }

          &__toggle {
            display: block;
            height: 60px;

            @include media(">=tablet") {
              height: 80px;
            }
          }

          &__submit {
            display: none;
          }
        }
      }
    }

    &-to-desktop {
      flex: 0;

      .pa-search {
        @include media(">=desktop") {
          position: relative;
        }

        &__form {
          border: 0;
          border-radius: 0;
          height: 70px;
          left: 0;
          opacity: 0;
          padding: $grid-gutter;
          position: absolute;
          top: 0;
          visibility: hidden;

          @include media(">=tablet") {
            height: 80px;
            padding: 20px ($grid-gutter * 2);
          }

          @include media(">=desktop") {
            left: auto;
            right: 0;
            width: 320px;
          }
        }

        &__field {
          background-color: $c-white;
          border: 1px solid $c-gray;
          height: 100%;

          @include media("<=tablet") {
            border: 1px solid $c-gray-light;
            border-radius: 3px;
          }
        }

        &__button {
          height: 100%;

          @include media(">=tablet") {
            font-size: rem(32);
            width: 90px;
          }
        }

        &__toggle {
          display: block;
          height: 60px;

          @include media(">=tablet") {
            height: 80px;
          }

          @include media(">=desktop") {
            height: 60px;
            width: 60px;
          }
        }

        &__submit {
          display: none;
        }
      }
    }
  }

  &--open {

    .pa-search__button {
      background: $c-gray-lighter;
      transition: $transition-time;
    }

    .pa-search__form {
      display: flex;
      opacity: 1;
      top: 80px;
      transition: $transition-time;
      visibility: visible;

      @include media("<tablet") {
        top: 60px;
      }

      @include media(">=desktop") {
        top: 60px;
      }
    }
  }
}
