

.bs-list-description {
  $dl: &;

  &__list {}

  &__item {}

  &__term {}

  &__title {
    border-bottom: 1px solid $c-gray-light;
    color: $c-primary;
    display: block;
    font-family: $font-title;
    font-size: 16px;
    font-weight: fw("medium");
    line-height: 20px;
    padding: 20px $grid-gutter;
    width: 100%;
  }

  &__desc {}

  &__content {
    border-bottom: 1px solid $c-gray-light;
    height: 100%;
    padding: 20px $grid-gutter;
  }

  &--accordion {
    #{$dl}__item {
      &.bs-is-expanded {
        #{$dl}__title::after {
          transform: rotate(-180deg);
        }

        #{$dl}__desc {
          height: 270px;
          transition: .3s;
        }
      }
    }

    #{$dl}__desc {
      height: 0;
      overflow: hidden;
      transition: .3s;
    }

    #{$dl}__title {
      position: relative;

      &::after {
        bottom: 0;
        color: inherit;
        content: "\e91b";
        display: block;
        font-family: "bradesco_v2";
        height: 16px;
        line-height: 1;
        margin: auto;
        position: absolute;
        right: $grid-gutter;
        top: 0;
        transform: rotate(0);
        transition: 0.3s;
        width: 16px;
      }
    }
  }
}
