//-------------------------------
// Seção
// Elemento de seção padrão
//-------------------------------
.bs-section {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: (4 * $grid-gutter) 0;

  // Seção para incluir textos ou conteúdo genérico
  &__content {
    p {
      margin-bottom: $grid-gutter;
    }

    li {
      margin-bottom: $grid-gutter;
    }
  }

  &__header,

  &-header {
    display: block;
    margin-bottom: (2 * $grid-gutter);

    &__description {
      color: $c-gray-darker;
      font-family: $font-secondary;
      font-size: rem(16);
      font-weight: fw("regular");
      line-height: 1.5;
      margin: $grid-gutter 0;
      word-break: break-word;

      + .bs-section-header__subtitle {
        margin-top: (2 * $grid-gutter);
      }
    }

    &__title {
      color: $c-gray-darker;
      display: inline-block;
      font-family: $font-title;
      font-size: rem(26);
      font-weight: fw("light");
      letter-spacing: -0.3px;
      line-height: 1.2;
      padding-left: 20px;
      position: relative;

      &::before {
        background-color: $c-primary;
        content: "";
        display: block;
        height: calc(100% - 10px);
        left: 0;
        position: absolute;
        top: 5px;
        width: 5px;
      }

      span {
        font-weight: fw("bold");
        white-space: nowrap;
      }

      @include media(">=tablet") {
        font-size: rem(30);
      }

      @include media(">=desktop") {
        font-size: rem(34);
      }

      &--white {
        color: $c-white;
      }
    }

    &__subtitle {
      color: $c-gray-darker;
      font-family: $font-title;
      font-size: rem(20);
      line-height: 1.3;

      @include media(">=tablet") {
        font-size: rem(22);
      }
    }

    &:only-child {
      margin-bottom: 0;
    }

    &--divider {
      margin-bottom: 50px;
      padding: 0 0 20px;

      @include media(">=tablet") {
        padding: 10px 0 40px;
      }

      @include media(">=wide") {
        padding: 0 0 40px;
      }

      .bs-section-header__title {

        &::before {
          display: none;
        }

        &::after {
          background: $c-secondary-dark;
          bottom: -22px;
          content: "";
          height: 3px;
          left: 50%;
          margin-left: -25px;
          position: absolute;
          width: 50px;
        }
      }
    }

    &--lighter {

      .bs-section-header__title {
        color: $c-white;

        &::after {
          background-color: $c-white;
        }
      }
    }
  }

  &__footer,
  &-footer {
    margin-top: (2 * $grid-gutter);

    .bs-button-group {
      justify-content: center;
    }
  }

  &__form {
    padding: $grid-gutter 0;
  }

  // reseta o padding da primeira seção filha
  // da coluna de conteúdo em um template com sidebar
  section:first-of-type {
    padding-top: 0;
  }

  &--full-height {
    min-height: 100vh;

    @include media(">=tablet") {
      min-height: 0;
    }

    @include media(">desktop") {
      min-height: 100vh;

      .bs-container-old {
        padding-bottom: 60px;
        padding-top: 60px;
      }
    }
  }

  &--gray {
    background-color: $c-gray-lighter;

    .bs-media__list--linear-prev.is-draggable .flickity-viewport::before {
      background: linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 25%, rgba(255,255,255,0.78) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);background: linear-gradient(to left, rgba(244,244,246,0) 0%, rgba(244,244,246,0.47) 25%, rgba(244,244,246,0.78) 47%, rgba(244,244,246,1) 75%, rgba(244,244,246,1) 100%);

    }
    .bs-media__list--linear-next.is-draggable .flickity-viewport::after {
      background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 25%, rgba(255,255,255,0.78) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);background: linear-gradient(to right, rgba(244,244,246,0) 0%, rgba(244,244,246,0.47) 25%, rgba(244,244,246,0.78) 47%, rgba(244,244,246,1) 75%, rgba(244,244,246,1) 100%);
    }


    .is-nav-selected::before {
      background-color: $c-gray-lighter;
    }
  }

  &--blue,
  &--red {
    color: $c-white;

    .bs-section-header__title,
    .bs-section-header__description {
      color: $c-white;

      &::before {
        background-color: $c-white;
      }
    }

    // Mensagens de erro
    .bs-form {
      &-error {

        &__message,
        &__checkbox {
          color: $c-error-alt;
        }
      }

      &__field {
        &[data-validation-state="invalid"] {
          border-color: $c-error-alt;

          &:focus,
          &:hover {
            border-color: $c-error-alt;
          }
        }
      }

      &__field-group {
        &[data-validation-state="invalid"] {

          input {
            border-color: $c-error-alt;

            &:focus,
            &:hover {
              border-color: $c-error-alt;
            }
          }
        }
      }
    }
  }

  &--red {
    background-color: $c-red;
  }

  &--blue {
    background-color: $c-blue;
  }

  &--boxed {
    // background-position: center top;
    // background-repeat: no-repeat;
    // background-size: cover;

    @include media("<=phone") {
      padding: 0;
    }

    .bs-container {
      background-color: $c-white;
      padding: (2 * $grid-gutter) (2 * $grid-gutter) $grid-gutter;

      @include media(">=tablet") {
        box-shadow: 0 10px 40px 0 rgba($c-black, 0.1);
      }

      @include media(">=desktop") {
        padding: (3 * $grid-gutter) (2 * $grid-gutter) $grid-gutter;
      }
    }
  }
}