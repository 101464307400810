//-------------------------------
// Header
//-------------------------------
.pa-header {
  $i: &;
  align-items: flex-start;
  background: $c-white;
  box-shadow: $box-shadow-fixed;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  transition: none;
  width: 100%;
  z-index: z("fixed");

  // Faixa do topo do header
  &__top {
    border-bottom: 1px solid $c-gray;
    display: none;
    min-height: ($grid-gutter * 2);
    order: 1;
    width: 100%;

    @include media(">=desktop") {
      display: block;
      width: 100%;
    }

    .pa-container,
    .container {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      // padding: 0;
    }
  }

  &__social {
    margin-left: -10px;
    padding-right: $grid-gutter * 2;

    & + * {
      margin-left: auto;
    }
  }

  &__top-menu {
    padding: 0 $grid-gutter;
  }

  &__top-menu,
  &__accessibility {

    &:first-child {
      margin-left: auto;
      padding: 0;
    }

    &:last-child {
      margin-right: -10px;
    }
  }

  // Faixa pricipal do Header
  &__main {
    // background-color: $c-white;
    // border-bottom: 1px solid $c-gray;
    order: 2;
    width: 100%;

    .pa-container,
    .container {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      position: relative;

      @include media(">=tablet") {
        justify-content: flex-start;
        padding: 0 15px;
      }

      wlauto + .pa-header__nav-horizontal {
        margin-left: auto;
      }

      // below are rules for positioning header-main
      // child elements based in the childs length
      & > * {
        @include media("<tablet") {

          &.pa-header__login {
            margin: 0;
            padding: 0 10px;
          }

          // if there's only one element
          &:first-child:nth-last-child(1) {
            // the element must be logo (because it is
            // the only required child of header-main)
            // so it should aligned at center
            margin: 0 auto;
          }

          // if there are two elements
          // from 2 childs the first-child can be only logo or nav
          &:first-child:nth-last-child(2) {
            &,
            & ~ * {
              // max-width: 25%;
              // width: 60px;
            }

            // if the first-child is logo
            &.pa-header__logo,
            & ~ .pa-header__logo {
              max-width: 75%;
              width: auto;

              & + * {
                margin-left: auto;
              }
            }

            // if the first-child is nav
            &.pa-header__nav,
            & ~ .pa-header__nav {
              & + .pa-header__logo {
                margin: 0 auto;
                padding-right: 60px;
              }
            }

            & ~ .pa-header__login {
              display: flex;
              justify-content: center;
              width: 70px;
            }
          }

          // if there are three elements
          &:first-child:nth-last-child(3) {
            &,
            & ~ * {
              max-width: 20%;
              width: 60px;
            }

            // if the first-child is logo
            &.pa-header__logo,
            & ~ .pa-header__logo {
              max-width: 60%;
              width: auto;
            }

            & ~ .pa-header__logo {
              margin: 0 auto;
            }
          }

          // if there are four elements
          &:first-child:nth-last-child(4) {
            &,
            & ~ * {
              display: flex;
              justify-content: center;
              max-width: 80px;
              min-width: 70px;
              order: 1;
              width: 25%;
            }

            & ~ :nth-child(3) {
              margin-left: auto;
            }

            // if the first-child is logo
            &.pa-header__logo,
            & ~ .pa-header__logo {
              border-bottom: 1px solid $c-gray;
              max-width: 100%;
              order: 0;
              width: 100%;
            }

            & ~ .pa-header__user .pa-dropdown-menu__content,
            & ~ .pa-header__search .pa-search__form {
              top: 120px;
            }
          }

          // if there are five elements
          &:first-child:nth-last-child(5) {
            &,
            & ~ * {
              display: flex;
              justify-content: center;
              margin: 0;
              max-width: 80px;
              min-width: 70px;
              order: 1;
              width: 25%;
            }

            // if the first-child is logo
            &.pa-header__logo,
            & ~ .pa-header__logo {
              border-bottom: 1px solid $c-gray;
              max-width: 100%;
              order: 0;
              width: 100%;
            }

            & ~ .pa-header__user .pa-dropdown-menu__content,
            & ~ .pa-header__search .pa-search__form {
              top: 120px;
            }
          }
        }

        @include media(">=tablet") {
          &.pa-header__logo + * {
            margin-left: auto;
          }
        }

        @include media("<desktop") {
          &.pa-header__nav-horizontal:not(:last-child) {
            margin-left: 0;
            order: 1;

            & + .pa-header__search {
              margin-left: auto;
              margin-right: 0;
            }
          }
        }

        @include media(">=desktop") {
          &:last-child:not(:first-child) {
            margin-right: -15px;
          }

          &.pa-header__nav-horizontal.pa-header-nav--invisible:not(:last-child) {
            margin-left: 0;
            order: 1;

            & + .pa-header__search {
              margin-left: auto;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    padding: 10px $grid-gutter;

    a {
      align-items: center;
      display: flex;

      & + a {
        border-left: 1px solid $c-gray;
        margin-left: 10px;
        padding-left: 10px;
      }
    }

    @include media(">=tablet") {
      padding: 10px 0;
    }

    @include media(">=desktop") {
      padding-right: $grid-gutter * 2;
    }

    // Logo as SVG has an extra padding
    .pa-logo {
      display: block;
      max-height: 40px;
      max-width: 100%;
      transition: max-height $transition-time;

      path {
        fill: $c-red;
      }

      &--white {
        display: none;
      }
    }
  }

  &__search {
    align-items: center;
    display: flex;
    // position: relative;

    @include media(">=tablet") {
      // margin-left: auto;
    }

    @include media(">=desktop") {
      flex: 1;
      padding: 0 ($grid-gutter * 2);

      &:last-child {
        padding-right: $grid-gutter;
      }
    }
  }

  &__nav {
    // display: flex;
    // order: -1;

    @include media(">=tablet") {
      margin-left: -15px;
    }

    &-toggle {
      display: block;
      height: 60px;
      transition: height $transition-time;
      width: 60px;

      @include media(">=tablet") {
        height: 80px;
      }

      @include media(">=desktop") {
        height: 60px;
      }

      // Botão (hamburger) para abrir o menu
      .pa-hamburger {
        @include hamburger();
        display: block;
        height: 20px;
        margin: 0 auto;
        width: 30px;

        span {
          height: 3px;
          margin-top: 0;
        }

        span::before {
          top: -8px;
        }

        span::after {
          top: -16px;
        }
      }

      &:hover,
      &:focus {

        .pa-hamburger span,
        .pa-hamburger span::before,
        .pa-hamburger span::after {
          background-color: $c-primary;
        }
      }
    }

    &--only-top {
      @include media(">=desktop") {
        display: none;
      }
    }
  }

  &__nav-toggle {
    display: block;

    @include media(">=desktop") {
      display: none;
    }
  }

  &__nav-horizontal {}

  &__login,
  &__accessibility {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: $grid-gutter * 2;

    @include media(">=wide") {
      // min-width: 285px;
    }
  }

  &__login {
    display: flex;
    // margin-left: auto;
    padding: 0 15px;

    .pa-button span {
      @include media("<tablet") {
        display: none;
      }
    }
  }

  &__user {
    display: flex;
  }

  .pa-user-menu {
    position: absolute;
    right: $grid-gutter;
    top: 0;
  }

  .pa-social__link,
  .pa-accessibility-menu__button {
    height: 30px;
    overflow: hidden;

    &:hover,
    &:focus {
      background-color: $c-gray-lighter;
    }
  }


  // ---------------
  // Variações
  // ---------------

  // floating
  &--fixed,
  &--floating {
    left: 0;
    position: fixed;
    top: 0;
    transition: none;
  }

  // pinned
  &--pinned {
    opacity: 1;
    transform: translateY(0);
    transition: transform $transition-time;

    // .pa-logo {
    //   max-height: 60px;
    //   transition: max-height $transition-time;
    // }
    //
    // .pa-header__nav-toggle {
    //   height: 60px;
    //   transition: height $transition-time;
    // }
  }

  &--unpinned {
    transform: translateY(-100%);
    transition: transform $transition-time;
  }

  // header com fundo transparente
  @at-root .transparent &, // for debuggin only
  &--transparent {
    background-color: transparent;

    #{$i}__logo {

      .pa-logo path {
        fill: $c-white;
      }
    }

    &#{$i} {
      box-shadow: none;
    }

    #{$i}__main {
      background-color: transparent;
    }

    #{$i}__top {
      background-color: rgba(0, 0, 0, 0.25);
      border: 0;
    }

    #{$i}__nav-toggle .pa-hamburger {

      span,
      span::before,
      span::after {
        background-color: $c-white;
      }
    }


    .pa-accessibility-menu__button,
    .pa-top-menu__link,
    .pa-dropdown-user__info-text {
      color: $c-white;
    }

    .pa-accessibility-menu__button {
      &:hover {
        background: $c-red;
      }
    }

    .pa-dropdown-menu {

      &__trigger  {

        &:hover {
          background: transparent;
        }

        .icon {
          color: $c-white;
        }
      }
    }

    .pa-top-menu {
      &__link {
        &:hover {
          background: $c-red;
        }
      }

      &__dropdown .pa-top-menu__link {
        color: $c-black;

        &:hover {
          background: $c-gray-lighter;
        }
      }
    }

    .pa-social {
      &__link {
        &:hover {
          background: $c-red;
        }
      }

      &__icon {
        color: $c-white;
      }
    }
  }

  // header com fundo transparente
  &--transparent {
    background: transparent;
    box-shadow: none;
    color: $c-white;
    left: 0;
    position: fixed;
    top: 0;
    transition: $transition-time;

    .pa-header__main {
      background-color: transparent;
      border-color: transparent;
    }

    .pa-header__top {
      background-color: rgba($c-black, .1);
    }

    .pa-top-menu__list > .pa-top-menu__item > .pa-top-menu__link,
    .pa-top-menu__list > .pa-top-menu__item > .pa-top-menu__link {
      background-color: transparent;
      color: $c-white;

      &:hover,
      &:focus {
        background-color: rgba($c-white, .1);
        color: $c-white;
      }
    }

    .pa-top-menu__list > .pa-top-menu__item:hover > .pa-top-menu__link {
      background-color: rgba($c-white, .1);
    }

    .pa-social__link,
    .pa-social__icon,
    .pa-accessibility-menu__button {
      color: $c-white;
      transition: color $transition-time;

      &:hover,
      &:focus {
        background-color: rgba($c-white, .1);
      }
    }

    .pa-hamburger span,
    .pa-hamburger span::after,
    .pa-hamburger span::before {
      background: $c-white;
      transition: background $transition-time;
    }

    img.pa-logo {
      display: none;

      &--white {
        display: block;
      }
    }

    .pa-logo__text {
      fill: $c-white;
      transition: fill $transition-time;
    }

    .pa-logo {
      path {
        fill: $c-white;
      }
    }

    .pa-header__top,
    .pa-header__nav-toggle,
    .pa-search__form {
      border-color: transparent;
      transition: border $transition-time;
    }

    .pa-search__form {
      background-color: rgba($c-black, .2);
      transition: $transition-time;

      @include media(">=desktop") {
        background-color: transparent;
      }
    }

    .pa-search__field,
    .pa-search__button {
      box-shadow: none;
      transition: $transition-time;

      @include media(">=desktop") {
        background-color: rgba($c-white, .2);
        color: $c-white;

        &:hover,
        &:focus {
          background-color: rgba($c-white, .4);
        }

        @include placeholder() {
          color: $c-white;
        };
      }

      &:hover,
      &:focus {

      }

    }

    .pa-search__button {
      border-color: transparent;
      margin-left: 1px;

      @include media("<desktop") {
        color: $c-white;
      }
    }

    .pa-search--open .pa-search__button {
      @include media("<desktop") {
        background-color: rgba($c-black, .2);
      }
    }

    .pa-search:not(.pa-search--open) .pa-search__button {
    }

    .pa-header__login .pa-button {
      @include buttonDefaults();
      @include button(ghost, whites);
      transition: $transition-time;
    }
  }

  &--search-is-open:not(#{&}--static) {
    @include media("<desktop") {
      // background: rgba($c-black, .5);
      // height: calc(100vh);
      // position: fixed;
      // overflow: auto;
      // top: 0;

      .pa-header__main,
      .pa-header__main .pa-container {
        // background: $c-white;
      }

      .pa-search__dropdown {
        margin-bottom: 30vh;
      }

      &.pa-header--transparent {
        .pa-header__main,
        .pa-header__main .pa-container {
          background: transparent;
        }
      }
    }
  }

  // header sem campo de busca, menu hamburger
  &--logado {
    .pa-header__main .pa-container {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .pa-header__nav {
      // margin-right: auto;
    }

    .pa-header__logo {
      border-bottom: 1px solid $c-gray-light;
      justify-content: center;
      order: -1;
      width: 100%;

      @include media(">=tablet") {
        border: 0;
        order: 0;
        width: auto;
      }
    }

    &.pa-search--open .pa-search__form {
      top: 120px;
    }
  }

  &--static {
    position: relative;
  }

  &--legacy {
    &.pa-header--logado .pa-header__main .pa-container,
    .pa-container {
      justify-content: flex-start;

      @include media(">=tablet") {
        flex-wrap: nowrap;
      }
    }

    .pa-header__logo {
      padding-right: 15px;
    }

    .pa-search {
      width: auto;

      @include media("<desktop") {
        margin-left: auto;
      }
    }

    .pa-header__login {
      white-space: nowrap;
    }
  }
}

.pa-header-nav {
  display: none;

  @include media(">=desktop") {
    display: block;

    & ~ .pa-header__nav-toggle {
      display: none;
    }
  }

  &__list {
    align-items: center;
    display: flex;
  }

  &__item {
    border-right: 1px solid $c-gray-light;
    position: relative;

    &:last-child {
      border-right: 0;
    }

    &--has-children > .pa-header-nav__link::after {
      bottom: 0;
      content: "\ea67";
      display: block;
      font-family: "bradesco_v2";
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      margin: auto;
      position: absolute;
      right: 10px;
      top: 0;
      width: 12px;
    }
  }

  &__link {
    align-items: center;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    height: 60px;
    line-height: 20px;
    max-width: 150px;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    // white-space: nowrap;

    &--active,
    &:hover {
      background-color: $c-gray-lighter;
      border-bottom-color: $c-primary;
    }

    &:focus {
      background-color: $c-gray-lighter;
      @include focus($c-gray-dark, -5);
    }
  }

  &__sublist {
    background-color: $c-white;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
    display: none;
    left: 0;
    position: absolute;
    top: 100%;

    &.pa-is-open {
      display: block;
    }
  }

  &__subitem {
    border-bottom: 1px solid $c-gray-light;

    &::after {
      // border-bottom: 0;
    }
  }

  &--invisible {

    .pa-header-nav {
      display: none;
    }

    .pa-header__nav-toggle {
      display: block;
    }
  }
}

//-------------------------------
// Menu de Acessibilidade
// Lista as opções de acessibilidade do site e geralmente fica no top header
//-------------------------------
.pa-accessibility-menu {

  // Lista de itens
  &__list {
    display: flex;
  }

  // Item
  &__item {}

  // Botão para ativar as funções
  &__button {
    align-items: center;
    display: flex;
    line-height: 20px;
    padding: 5px 10px;
  }

  // Ícone do botão
  &__icon {
    font-size: rem(18);
  }

  // Texto de Acessibilidade
  // Fica escondido visualmente, mas mantém a
  // marcação para leitores de tela
  &__screen-reader {
    @include sr-only();
  }
}

//-------------------------------
// Menu do Topo
// Lista links na faixa do topo do header
//-------------------------------
.pa-top-menu {

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
  }

  &__link {
    display: block;
    font-size: rem(14);
    line-height: 20px;
    min-height: 30px;
    padding: 5px 10px;

    &:hover,
    &:focus {
      background-color: $c-gray-lighter;
    }

    &:not(:only-child) {

      &::after {
        // icon-seta-baixo-b
        content: "\e91b";
        display: inline-block;
        font-family: "bradesco_v2";
        font-size: rem(10);
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }

  &__dropdown {
    background: $c-white;
    box-shadow: b-shadow("2");
    display: none;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    width: auto;
    z-index: z("absolute");

    &.pa-is-open {
      display: block;
    }

    .pa-top-menu__item:hover {
      background-color: $c-gray-lighter;
    }

    .pa-top-menu__link {
      padding: 8px 10px;
      white-space: nowrap;
    }
  }

  &__group {
    padding-bottom: $grid-gutter / 2;
  }

  &__group-title {
    background-color: $c-gray-lighter;
    border-bottom: 1px solid $c-gray;
    color: $c-primary;
    display: block;
    font-size: rem(12);
    font-weight: fw("semibold");
    line-height: 18px;
    min-height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  &__subitem {}
}

//-------------------------------
// Social Media
// Lista de redes sociais
//
// TODO: Adequar estilo da lista (existe no rodapé) ao padrão do header
//-------------------------------
.pa-social {

  &__list {
    display: flex;
  }

  &__item {}

  &__link {
    align-items: center;
    display: flex;
    font-size: rem(16);
    line-height: 20px;
    padding: 10px;
    transition: 0.2s;

    &:hover {
      background-color: $c-gray-lighter;
    }
  }

  &__icon {
    color: $c-gray-darker;
  }

  // variações social media
  &--primary {
    .pa-social__icon {
      color: $c-red;
    }
  }
}


.pa-share {
  align-items: center;
  display: flex;
  flex-wrap: wrap;


  &__title {
    margin-right: 10px;
  }

  &--border-top {
    border-top: 1px solid $c-gray-light;
    margin-top: 30px;
    padding-top: 30px;
  }

}
