// progress
.bs-progress {
  align-items: center;
  border-top: 8px solid $c-gray;
  display: flex;
  font-size: 16px;
  height: 50px;
  justify-content: space-between;
  line-height: 20px;
  padding: 10px;
  position: relative;
  width: 100%;

  // &::before,
  &__bar {
    background-color: $c-red;
    bottom: 100%;
    content: "";
    display: block;
    height: 9px;
    left: 0;
    position: absolute;
    top: -8px;
    // transition: .6s;
    width: 0%;
    z-index: 1;
  }

  &__value {
    font-weight: fw("semibold");
    margin-left: auto;
  }

  &__text {
    display: flex;
    font-weight: fw("semibold");
  }

  &__actions {
    color: $c-white;
    margin-right: -($grid-gutter / 2);
  }

  &__link,
  &__cancel {
    color: $c-white;
    display: inline-block;
    font-size: 16px;
    padding: 0 ($grid-gutter / 2);

    &:hover {
      color: rgba($c-white, .6);
    }
  }

  &__cancel {
    font-size: 14px;
  }

  &--is-loading {
    .bs-progress__text {
      &::after {
        animation: dots 1s infinite;
        content: "...";
        display: block;
        height: 20px;
        overflow: hidden;
        white-space: initial;
        width: 0;
      }
    }
  }
}

@keyframes dots {
  0% {
    width: 0;
  }

  20% {
    width: 5px;
  }

  40% {
    width: 10px;
  }

  60% {
    width: 15px;
  }

  80% {
    width: 10px;
  }
}
