//-------------------------------
// Título (old) [deprecated]
// Título de seções
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-title {
  position: relative;

  &--border-bottom {
    padding-bottom: 10px;

    &::after {
      background-color: $c-primary;
      bottom: 0;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      width: 20px;
    }
  }
}

// deprecated
// foi criado para um exemplo antes de termos o bskit
.bs-styleguide-header {
  background-color: $c-primary;
  padding: 15px 0;

  &__title {
    color: $c-white;
    font-family: $font-title;
    font-size: rem(20);
    font-weight: fw("bold");
    text-transform: uppercase;
  }

  p {
    color: $c-white;
  }
}
