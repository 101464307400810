//-------------------------------
// Cards (old) [deprecated]
// Componente para cards, ao estilo do media
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-card {

  &__title {
		display: inline-block;
		font-family: $font-title;
    font-size: rem(14);
		font-weight: 500;
		line-height: 18px;
    margin: 0 0 5px 0;

		@include media(">=desktop"){
			font-size: rem(20);
			line-height: 24px;
		}
  }

  &__figure {
    align-items: center;
    background-position: center center;
    background-size: cover;
    border: 1px solid $c-gray-light;
    border-radius: 90px;
    color: $c-secondary;
    height: 90px;
    justify-content: center;
    margin-right: 20px;
    padding: 12px 18px;
    text-align: center;
    vertical-align: top;
    width: 90px;

    @include media(">=tablet") {
      height: 120px;
      margin: 0 auto 20px;
      transition: $ease-in-out;
      width: 120px;
    }

    @include media(">=desktop") {
      height: 140px;
      width: 140px;
    }

    &.bs-icon-box {
      font-size: rem(50);

      @include media(">=tablet") {
        font-size: rem(75);
      }

      @include media(">=desktop") {
        font-size: rem(90);
      }
    }
  }

	&__list {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		width: 100%;

		@include media(">=tablet"){
			align-items: flex-start;
			flex-direction: row;
		}
	}

	&__item {
		align-items: center;
		display: flex;
		justify-content: space-between;
		height: auto;
		margin-bottom: 15px;
		position: static;
		width: 290px;

		@include media(">=tablet"){
			flex-direction: column;
			margin-bottom: 0;
			position: relative;
			width: 31%;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__container {
		align-items: center;
		color: $c-gray-darker;
		text-decoration: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 100%;
		width: 100%;

		@include media(">=tablet"){
			flex-direction: column;
		}

		@include media(">=desktop"){
			transition: $ease-in-out;

			&:hover {
				color: $c-secondary;

				.bs-card__figure {
					border: 1px solid $c-secondary;
				}
			}
		}
	}

	&__description {
		font-size: rem(12);
		line-height: 16px;
		@include media(">=desktop"){
			font-size: rem(14);
		}
	}

	&__text {
		@include media("<tablet"){
			display: block;
			padding: 0 5px;
			height: auto;
			width: 170px;
		}

		&__description {
			line-height: 16px;
		}
	}

  &__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  // deprecated
  &__social {
    display: block;
    margin: 35px auto 0;
    width: 290px;

    @include media(">=tablet") {
      margin: 60px auto;
      width: 100%;
    }
  }

  &__social-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    li {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: center;
      margin-bottom: 6px;
      width: 60px;

      @include media(">=tablet") {
        height: 40px;
        width: 69px;
      }
    }

    a {
      color: $c-secondary;
      font-size: rem(22);
      transition: $ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }

	&--flip-card {

		.bs-card {

			&__text {

				@include media(">=tablet"){
					height: 70px;
				}
			}

			&__item {
				cursor: pointer;
				height: auto;
				justify-content: center;
				overflow: hidden;

				@include media(">=tablet"){
					height: 380px;

					&:hover {

						.bs-card__figure {
							border: 1px solid transparent;

							@include media(">=desktop"){
								height: 160px;
								width: 160px;
							}
						}
					}
				}
			}

			&__figure {

				@include media(">=desktop"){
					transition: $ease-in-out;
					will-change: width;
				}
			}

			// IE11
			@media all and (-ms-high-contrast:none) {
				*::-ms-backdrop, &__figure {
					height: 90px;
				}

				@include media(">=tablet"){
					*::-ms-backdrop, &__figure {
						height: 140px;
					}
				}
			}

			&__text {
				@include media("<tablet"){
					width: 140px;
				}
			}
		}
	}

	&--contact-list {
		flex-direction: row;
		justify-content: space-around;
		width: 290px;

		@include media(">=tablet"){
			justify-content: center;
			width: 100%;
		}

		.bs-card {

			&__item {
				flex-direction: column;
				color: $c-gray-darker;
				width: 48%;

				@include media(">=tablet"){
					justify-content: flex-start;
					width: 24%;

					&:hover {
						color: $c-secondary;

						.bs-card {

							&__figure {
								background: transparent;
								border: 1px solid $c-secondary;
								color: $c-secondary;
							}
						}
					}
				}
			}

			&__figure {
				color: $c-gray-darker;
				background: $c-secondary;
				color: white;
				margin-bottom: 10px;
				&.bs-icon-box {
					font-size: rem(50);
				}

				@include media("<tablet"){
					height: 90px;
					margin-right: 0;
					padding: 12px 14px;
					width: 90px;
				}

				@include media(">=tablet"){
					height: 120px;
					margin-bottom: 15px;
					width: 120px;
					&.bs-icon-box {
						font-size: rem(70);
					}
				}
			}

			&__title {

				@include media("<tablet"){
					font-size: rem(14);
					padding: 0 12px;
				}
			}

			&__text {
				display: block;
				padding: 0 5px;
				height: 50px;
				width: 140px;

				@include media(">=tablet"){
					height: 100%;
				}

				&__description {
					line-height: 16px;
				}
			}
		}
	}
}
