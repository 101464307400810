  body {
      // transition: margin-top ease .4s;
  }
  .js-is-hidden {
      display: none;
  }

  /* ----- Overlay */
  body.overlay-on {
      overflow: hidden;
  }
  .header-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100vh;
      background: rgba(0,0,0,.6);
      display: block;
  }

  .bs-refactor .bs-header__exp-content .bs-media__list {
      margin-left: 0;
  }

  .bs-refactor .bs-header__accessibility {
      padding-right: 30px;
      justify-content: initial;
  }

  ul.bs-header__accessibility.bs-accessibility-menu__list.bs-js-accessibility-menu {
    flex: 11 1 auto;
  }

  ul.bs-header__top-menu.bs-top-menu__list {
    display: flex;
    flex: 1 1 auto;
  }

  ul.bs-header__social.bs-social__list {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding-right: 0;
  }


  /* ----- Acessecibilidade Primeiro TAB */
  a.skip-main {
      left:-999px;
      position:absolute;
      top:auto;
      width:1px;
      height:1px;
      overflow:hidden;
      z-index:-999;
  }
  a.skip-main:focus, a.skip-main:active {
      color: #fff;
      background-color:#000;
      left: auto;
      top: auto;
      width: 30%;
      height: auto;
      overflow:auto;
      margin: 10px 35%;
      padding:5px;
      border-radius: 5px;
      border:4px solid #ff0;
      text-align:center;
      font-size:1.2em;
      z-index:999;
      outline: 3px dashed;
  }

  // Acessibilidade ativado ao uso do TAB
  .bs-header-menu-option:focus > .bs-header-menu-option__accessibility  {
      text-align:center;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.dropdown:after a span {
      outline: none;
  }

  // Acessibilidade ativado ao uso do TAB Search
  .bs-form__field:focus {
      outline: .125rem solid var(--main-color);
      font-size: 1rem;
      border-radius: 5px;
  }

  //Hack corrige linha :before no IE11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .bs-media__list--login .bs-media__item a::after {
      margin-left: -35px;
      }
  }
  .is-ie .bs-header__login, .bs-header__accessibility {
    justify-content: flex-start;
  }

  /* ----- Fixed Menu Top */
  .fixed-nav .bs-refactor .bs-header {
      position: fixed;
  }

  .fixed-nav .bs-refactor .bs-header .bs-header__main {
  //   transition: all ease 1s;
  }

  .fixed-nav .bs-refactor .bs-header__logo .bs-logo svg {
      //width: 180px;
  }

  .fixed-nav .bs-refactor .bs-header .bs-header__exp-content {
      //top: 102px;
  //   transition: all ease 1s;
  }

  /* ----- Menu Container */
  .bs-refactor .bs-header {
      position: relative;
      box-shadow: none;
  }

  /* ----- Menu Superior (Social / Acessibilidade) */
  .bs-refactor .bs-header .bs-header__top {
      background-color: #FFFFFF;
      z-index: 1;
  }

  .bs-refactor .bs-header .bs-header__top .bs-container {
    justify-content: initial;
  }

  /* ----- Menu Principal */
  .bs-refactor .bs-header .bs-header__main {
      position: relative;
      width: 100%;
      min-height: 80px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
  }
  .bs-refactor .bs-header .bs-header__main:after {
      content: "";
      position: absolute;
      top: 80px;
      width: 100%;
      height: 4px;
      background-color: #cc0930;
      z-index: 0;
  }
  .bs-refactor .bs-header.expanded .bs-header__main:after {
      z-index: -2;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container {
      justify-content: space-between;
  }
  .is-ie .bs-refactor .bs-header .bs-header__main {
      display: block;
  }

  .is-ie .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login {
    right: 38vw;
  }
  /* ----- Logo */
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header__logo {
      padding-left: 0;
      padding-right: 0;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header__logo a {
      margin-top: -10px;
      padding-bottom: 10px;
  }
  .bs-refactor .bs-header__logo .bs-logo svg {
      display: block;
      height: 60px;
      max-width: 100%;
      width: 220px;
  }
  .bs-contrast--high .bs-refactor .bs-header__logo .bs-logo svg path {
      fill: #FFFFFF !important;
  }
  .bs-contrast--high .bs-logo,
  .bs-contrast--high :not(svg).bs-logo {
      display: block !important;
  }

  /* ----- Opcoes menu: Principal */
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu {
      font-size: 1rem;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option {
      min-height: 80px;
      font-weight: 500;
      padding: 0 16px 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option:hover {
      color: #cc0930;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option:focus {
    color: #cc0930;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option span {
      width: 100%;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__main .bs-container .bs-header-menu .bs-main-menu-option.open .bs-header-menu-option span {
      padding-left: 12px;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.dropdown.is-open {
      color: #cc0930;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.dropdown:after {
      content: "\e91b";
      display: inline-block;
      font-family: "bradesco_v2";
      font-size: 0.9rem;
      margin-left: 5px;
      vertical-align: middle;
      transition-duration: 0.4s;
      transition-property: transform;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.dropdown.is-open:after {
      transform: rotate(-180deg);
  }
  .bs-refactor .bs-header.header-mobile .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option {
      min-height: 62px;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.dropdown:after {
      content: "\e922";
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.is-header-option-selected {
      color: #cc0930;
      font-weight: 500;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option.is-header-option-selected:after {
      color: #cc0930;
      font-weight: 500;
      transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      -o-transform:rotate(180deg);
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option i {
      color: #cc0930;
      font-size: 1.6em;
      padding-right: 8px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option i {
      display: flex;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__main .bs-container .bs-header-menu .bs-main-menu-option.open .bs-header-menu-option i {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option:not(:only-child)::after {
      position: absolute;
      right: 24px;
      width: 40px;
      content: "\e922";
      font-size: 1.4em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
  }
  .bs-refactor .bs-header .bs-header__exp-content--main-menu .bs-container-acessibility__mobile {
      display: none;
  }
  .bs-refactor .bs-header .bs-header__exp-content--main-menu.open .bs-container-acessibility__mobile {
      display: block
  }
  .bs-refactor .bs-header .bs-header__exp-content--main-menu .bs-container-acessibility__mobile.hide {
      display: none !important;
  }

  /* ----- Opcoes Menu: Buscar */
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar {
      position: relative;
      min-width: 120px;
      height: 32px;
      margin-top: 24px;
      margin-bottom: 24px;
      padding-left: 24px;
      border-left: 1px solid #e8e9ec;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar:hover {
      color: #cc0930;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar:focus {
    color: #cc0930;
    font-weight: 500;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar:before {
      content: "\e95e";
      position: absolute;
      right: 15px;
      font-size: 1.3em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: 500;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar i {
      font-size: 20px;
      margin-left: 8px;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open {
      color: #cc0930;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open:before {
      content: "\e965";
      position: absolute;
      right: 20px;
      font-size: 0.8em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: 600;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open i {
      display: none;
  }

  /* ----- Opcoes Menu: Login */
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login {
      position: relative;
      max-width: 135px;
      line-height: 1;
      padding: 8px;
      border-radius: 6px;
      z-index: 0;
      background: linear-gradient(to bottom,#e60935 70%,#99000f 140%);
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login:hover {
      border: 1px solid transparent;
      border-color: transparent;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login:hover::before {
      background: #99000f;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login:focus {
    background: #99000f;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login span + i {
      margin-left: 5px;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login,
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:active,
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:focus,
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:hover {
      background-color: #ffffff;
      background-image: none;
      color: #cc0930;
      border: 1px solid #cc0930;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:hover::before {
      background-color: #ffffff;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login span {
      padding-right: 25px;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login i {
      display: none;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login:before {
      background: transparent;
  }
  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:before {
      position: absolute;
      top: 13px;
      right: 8px;
      left: auto;
      bottom: auto;
      content: "\e965";
      width: 14px;
      height: 14px;
      font-size: 0.8em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: 600;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      transition: none;
      opacity: 1;
  }

  /* ----- Menu expansivel: Geral */
  .bs-refactor .bs-header .bs-header__exp-content:not(.bs-header__exp-contentMobile) {
      position: absolute;
      top: 75px;
      left: 0;
      width: 100%;
      background-color: #FFFFFF;
      order: 3;
      border-bottom: 4px solid #cc0930;
      // transition: all ease 1s;
      box-shadow: 0px 3px 6px #00000029;
      z-index: -1;
  }
  .bs-refactor .bs-header .bs-header__exp-contentMobile {
      display: none;
  }

  .bs-refactor .bs-header .bs-header-login .bs-header__exp-content.exp-login .bs-media {
    position: initial;
  }

  .bs-refactor .bs-header .bs-header__exp-content .menu.bs-container {
      align-items: flex-start;
      padding-bottom: 32px;
  }

  .bs-refactor .bs-header .bs-header__exp-content #opt_search .bs-form__control--icon label {
      display: block;
  }

  /* ----- Menu expansivel: Principal */
  .bs-refactor .bs-header__exp-content .bs-media__item {
      padding-top: 0;
      padding-bottom: 0;
      display: block;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item a {
      position: relative;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      border-bottom: 1px solid #d4d3d8;
      padding: 12px 0;
      display: block;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item a:not(.bs-option-title):hover {
      color: #cc0930;
      font-weight: 500;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item a:not(.bs-option-title):after {
      content: "";
      position: absolute;
      bottom: -1px;
      width: 0%;
      height: 2px;
      background-color: #cc0930;
      display: block;
      transition: all ease 0.4s;
      left: 0;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item a:not(.bs-option-title):hover:after {
      width: 100%;
  }
  .bs-refactor .bs-header__exp-content .bs-media__list {
      padding: 24px 0;
  }
  .bs-refactor .bs-header__exp-content .bs-menu-section-title {
      display: none;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item li:first-child a.bs-option-title {
      padding: 24px 0 0 0;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item li a.bs-option-title {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      border-bottom: none;
      padding: 32px 0 0 0;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item li a.bs-option-title:hover {
      color: #cc0930;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .bs-media__item a {
      min-height: 62px;
      padding: 20px 40px!important;
      border: 0;
      //border-bottom: 1px solid #D2D4DA;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open a:not(.bs-header-menu-option):not(.bs-option-title) {
    border-bottom: 1px solid #d4d3d8;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .bs-media__item a.bs-option-title:hover {
      color: #cc0930;
  }
  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.open {
      color: #cc0930;
  }

  .bs-refactor .bs-header.header-mobile .bs-header .bs-header__exp-content .menu.open

  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.open.bs-option-title:before {
  //   right: 45px!important;
      font-weight: 500;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
  }


  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.single-option {
      position: relative;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid #d4d3d8;
      padding: 12px 0;
      display: block;
  }

  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.single-option:after {
      content: "";
      position: absolute;
      bottom: -1px;
      width: 0%;
      height: 2px;
      background-color: #cc0930;
      display: block;
  }

  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.single-option:hover {
      color: #cc0930;
      font-weight: 500;
  }

  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.single-option:hover:after {
      width: 100%;
  }

  .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title i {
      color: #cc0930;
      font-size: 28px;
      padding-right: 8px;
  }

  /* ----- Menu expansivel: Busca */
  .bs-refactor .bs-header__exp-content .menu_search {
      padding-left: 20px;
      padding-right: 20px;
  }
  .bs-refactor .bs-header__exp-content .menu_search .bs-form__control {
      width: 65%;
      margin: 32px 0;
  }
  .bs-refactor .bs-header__exp-content .menu_search .bs-form__control label {
      font-size: 1rem;
      //display: none;
  }
  .bs-refactor .bs-header__exp-content .menu_search .bs-form__control .bs-form__field {
      font-size: 1rem;
      border: 2px solid #acacae;
      margin-top: 8px;
  }

  .bs-refactor .bs-header__exp-content .menu_search .bs-form__control .bs-form__field::-ms-clear {
      display: none;
      height: 0;
      width: 0;
  }

  .bs-refactor .bs-header__exp-content .menu_search .most-searched {
      width: 50%;
      font-weight: 600;
      padding-bottom: 4px;
      border-bottom: 1px solid #d4d3d8;
  }
  .bs-refactor .bs-header__exp-content .menu_search a {
      font-size: 1rem;
      display: inline-block;
      float: left;
      clear: left;
      padding: 10px 0;
      }
  .bs-refactor .bs-header__exp-content .menu_search a:last-child {
      margin-bottom: 32px;
  }
  .bs-refactor .bs-header__exp-content .menu_search .icon-nav-busca {
      width: 46px;
      height: 46px;
      bottom: 2px;
      right: 0;
      padding: 10px 4px;
      cursor: pointer;
  }
  .bs-refactor .bs-header__exp-content .menu_search .bs-media a:first-of-type {
      padding-top: 15px!important;
  }

  /* ----- Menu expansivel: Login
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: #fff;
      border-bottom: 4px solid #cc0930;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-box-shadow: 0px 3px 6px #00000029;
      -moz-box-shadow: 0px 3px 6px #00000029;
      box-shadow: 0px 3px 6px #00000029;
  }
  */
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login {
    position: absolute;
    top: 0;
    min-width: 600px;
    background-color: #fff;
    border-bottom: 4px solid #cc0930;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    right: 0vw;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .bs-refactor .bs-header .bs-header-login .bs-header__exp-content.exp-login {
      display: none;
  }
  .bs-refactor .bs-header .bs-header-login.is-open .bs-header__exp-content.exp-login {
      display: block;
  }
  /*
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) {
      background-color: #e8e9ed;
      margin: 0 !important;
      padding-left: 0;
      padding-right: 0;
      flex-basis: 320px;
      max-width: 320px;
  }
  */
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) {
    background-color: #e8e9ed;
    margin: 0 !important;
    padding-left: 0;
    padding-right: 0;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) li:first-child {
    border-top: 1px solid #d4d3d8;
    padding-top: 0;
  }
    .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) li {
      border-bottom: 1px solid #d4d3d8;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-header-option-section{
      display: none;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-header-option-section {
      display: none;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-header-option-section.open {
      display: block;
  }
  /*
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item {
      padding: 32px;
      padding-right: 0;
      flex-basis: 250px;
      max-width: 250px;
  }
  */
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item {
    padding: 32px 50px;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title:first-child {
      border-top: 0;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title {
      position: relative;
      font-weight: 500;
      padding: 20px 40px;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title:hover {
      color: #cc0930;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title:last-child {
    //   border-bottom: 1px solid #d3d4d8;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title:before {
      position: absolute;
      right: 44px;
      width: 20px;
      content: "\e922";
      font-size: 1.4em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      transition-duration: 0.4s;
      transition-property: transform;
  }

  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title-nosubitems:before {
      content: '';
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title.open:before {
      transform: rotate(180deg);
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-option-sub-item {
      padding: 6px 0;
      display: flex;
      align-items: center;
  }
  .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-option-sub-item i {
      color: #cc0930;
      font-size: 28px;
      padding-right: 8px;
  }
  .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom a {
      position: relative;
      width: 100%;
      height: 62px;
      padding: 0 72px 0 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8e9ed;
  }
  .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom a:after {
      content: none;
  }
  .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom i {
      color: #cc0930;
      font-size: 1.6em;
      padding-right: 8px;
      display: inline-flex;
  }
  .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom span {
      font-weight: 300;
      display: inline-flex;
  }

  /* Mobile */
  .bs-refactor .bs-header-menu .bs-mobile-header-main-menu {
      display: none;
  }



  /* Conteúdo Expansível (não login) */
  /* Produtos */
  .bs-refactor .bs-header .bs-header__exp-content:not(.exp-login) {
      max-height: calc(100vh - 112px);
      overflow-y: auto;
      padding: 0 1000em; /* trick from css-tricks comments */
      margin: 0 -1000em; /* trick from css-tricks comments */
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content {
      position: initial;
      padding: 0;
      margin: 0;
      border-bottom: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content .bs-media__item {
      max-width: 100%;
      width: 100%;
      margin: 0 !important;
      padding: 0;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content .bs-media__item li {
      padding: 0;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content .bs-media__item li {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content .bs-media__item li:only-child a:before {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content .bs-media__item li:first-child {
      display: block;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content .bs-media__item li.open a.bs-option-title:before {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .menu_login .bs-media__list--login {
    flex-wrap: wrap;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) {
    max-width: 100vw;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .menu_search .bs-media {
    width: 100%;
  }

  .bs-refactor .bs-header .bs-header__exp-content .menu {
      width: 100vw;
      flex-wrap: wrap;
      flex-direction: initial;

  }
  .bs-refactor .bs-header .bs-header__exp-content .menu ul {
      max-width: 24%;
      border: 1px solid transparent !important;
      margin-top: 15px !important;
      margin-bottom: 10px !important;
      padding-bottom: 20px;
  }
  .bs-refactor .bs-header .bs-header__exp-content .menu ul li:first-child {
      padding-top: 12px;
  }
  .bs-refactor .bs-header .bs-header__exp-content .menu {
      display: none;
  }
  .bs-refactor .bs-header .bs-header__exp-content .menu.open {
      display: flex;
  }
  .bs-refactor .bs-header .bs-header__exp-content .menu_login.open {
      display: block;
  }
  .bs-refactor .bs-header .bs-header__exp-content--main-menu {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .bs-main-menu-option {
        margin-right: 15px;
      }
  }

  .bs-refactor .bs-header .bs-header__exp-content--main-menu.bs-space-elements {
      justify-content: space-around;
    .bs-main-menu-option {
        margin-right: 0;
    }
  }

  // Produtos Mobile
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu.open {
      position: absolute;
      top: 123px;
      max-height: calc(100vh - 123px);
      background-color: #ffffff;
      border-bottom: 4px solid #cc0930;
      display: block;
      overflow: scroll;
      z-index: -1;
  }


  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open:not(.bs-accessibility-menu__item) {
      background-color: #e8e9ec;
  }


  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li:not(.bs-accessibility-menu__item) {
      border-bottom: 1px solid #e8e9ed;
      padding: 0 30px;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open {
      display: block !important;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open .bs-header-menu-option.dropdown:after {
      display: none;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open .icon-nav-produtos:before {
      display: none;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open a.bs-header-menu-option {
      padding-left: 30px!important;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open a.bs-header-menu-option:before {
      position: absolute;
      left: 40px;
      width: 40px;
      content: "\e924";
      font-size: 1.4em;
      font-family: bradesco_v2!important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
  }


  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.open a.bs-header-menu-option {
      background: #e8e9ed;
      color: #cc0930;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu li.hide {
      display: none;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content--main-menu .bs-header__exp-content {
      display: table;
      background: #fff;
      margin-left: -30px;
  }

  .bs-refactor .bs-header.header-mobile .bs-header__exp-content .bs-media__item a.bs-option-title:before {
      right: 40px!important;
  }

  // Busca
  .bs-refactor .bs-header__exp-content .menu_search .bs-media {
      width: 65%;
  }

  .bs-refactor .bs-header__exp-content .bs-header-option-section {
      margin-bottom: 16px;
  }

  .scroll-down {
      transform: translate3d(0, -100%, 0);
  }

  .scroll-down .bs-button--login {
      font-size: 2rem!important;
  }

  .scroll-down #btn_search {
      font-size: 2rem!important;
  }

  .scroll-down .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar {
      font-size: 2rem;
  }

  .scroll-up {
      transform: none;
  }

  .scroll-down .bs-refactor .bs-header :not(.bs-header__exp-content) .bs-container {
      height: 100px!important;
  }

  .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-header-option-selected:hover .bs-button--login:before {
      background: transparent!important;
  }

  // Menu Superior Mobile
  .bs-refactor .bs-header .bs-header__menu-superior {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior {
      display: block;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list {
      padding: 0;
      display: block;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option:hover,
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option {
      background-color: #e8e9ed;
      border-color: #ffffff;
      padding: 0;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option.hide {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option.open {
      display: block !important;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option.open>.bs-top-menu__link {
      color: #cc0930;
      font-weight: 500 !important;
      padding-left: 78px !important;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option.open>.bs-top-menu__link:before {
      position: absolute;
      left: 40px;
      width: 40px;
      content: "\e924";
      font-size: 1.4em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option.open>.bs-top-menu__link:after {
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option .bs-top-menu__link:hover,
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option .bs-top-menu__link {
      position: initial;
      width: 100%;
      height: 62px;
      background-color: #e8e9ed;
      font-size: 1rem;
      font-weight: 300;
      padding: 0 72px 0 40px;
      display: flex;
      align-items: center;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__option .bs-top-menu__link:not(:only-child)::after {
      position: absolute;
      right: 24px;
      width: 40px;
      content: "\e922";
      font-size: 1.4em;
      font-family: 'bradesco_v2' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__dropdown {
      position: initial;
      display: none;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__dropdown.open {
      box-shadow: none;
      display: block;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__dropdown .bs-top-menu__group {
      border: 0;
      padding: 0;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__dropdown .bs-top-menu__group .bs-top-menu__group-title {
      height: 62px;
      border-color: #e8e9ed;
      padding-left: 45px;
      display: flex;
      align-items: center;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__dropdown .bs-top-menu__group .bs-top-menu__subitem {
      padding: 0;
  }
  .bs-refactor .bs-header.header-mobile .bs-header__menu-superior .bs-top-menu__list .bs-top-menu__dropdown .bs-top-menu__group .bs-top-menu__subitem .bs-top-menu__link {
      background-color: #ffffff;
      border: 0;
  }

  .bs-refactor .bs-header.header-mobile li.bs-main-menu-option span.button-content {
    display: flex;
    align-items: center;
  }

  /* Medium Desktop */
  @media screen and (max-width: 1199px) {
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) {
          flex-basis: 305px;
          max-width: 305px;
      }
  }

  @media screen and (min-width: 1312px) {
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option {
          min-height: auto;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar {
          font-size: 1rem;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar:before {
      //   top: 10px;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-selected:before {
          top: 15px;
          font-size: 0.7em;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login {
          width: auto;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-header-option-selected:before {
      top: 15px;
      font-size: 0.7em;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login {
      max-width: 160px;
      }
  }

  /* Tablet / Mobile */
  @media screen and (max-width: 991px) {

      .fixed-nav-mobile {
          scroll-behavior: smooth;
      }

      .fixed-nav-mobile .bs-header {
      //   transition: transform 0.4s;
      }

      /* ----- Container */
      .is-ie .bs-refactor .bs-header .bs-header__main {
          display: flex;
      }
      .is-ie .bs-refactor .bs-header .bs-header__main .bs-container {
          margin-top: 0px;
      }
      .bs-refactor .bs-header :not(.bs-header__exp-content) .bs-container {
          position: relative;
          height: 124px;
          padding-top: 48px;
      }
      .bs-refactor .bs-header .bs-header__exp-content:not(.bs-header__exp-contentMobile) {
          top: 120px;
      }
      .bs-refactor .bs-header .bs-header__exp-content.exp-login {
          top: 124px;
      }
      .bs-refactor .bs-header.header-mobile .bs-header__exp-content .bs-container {
          max-width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
      }
      .bs-refactor .bs-header .bs-header__exp-content .bs-container .menu_search {
          padding: 20px;
      }

      .bs-refactor .bs-header .bs-header__exp-content .bs-top-menu__group-title {
          padding: 15px 10px;
      }

      .bs-refactor .bs-header .bs-header__exp-content .bs-top-menu__group {
          padding-bottom: initial;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg {
          border-bottom: none;
      }
      .bs-refactor .bs-header .bs-header__main:after {
          top: 124px;
      }

      /* ----- Opcoes menu: Principal */
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu {
          width: auto;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-header-menu-option {
          // display: none;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-mobile-header-main-menu {
          position: relative;
          width: 80px;
          min-width: auto;
          min-height: 40px;
          text-align: center;
          border-left: none;
          padding: 0;
          margin-left: 16px;
          display: flex;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-mobile-header-main-menu.is-open {
          color: #cc0930;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-mobile-header-main-menu span {
          font-size: 0.9em;
          font-weight: 400;
          margin: 28px auto 0 auto;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-menu .bs-mobile-header-main-menu i {
          position: absolute;
          top: -5px;
          left: 0;
          right: 0;
          font-size: 2.4em;
          font-weight: 1000;
          margin: 0;
      }

      /* ----- Opcoes Menu: Buscar */
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open {
          position: relative;
          width: 80px;
          min-width: auto;
          min-height: 40px;
          color: inherit;
          text-align: center;
          border-left: none;
          padding: 0;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open {
          color: #cc0930;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar span {
          // font-size: 0.9em;
          font-weight: 400;
          margin: 28px auto 0 auto;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar i,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open i {
          position: absolute;
          top: -5px;
          left: 0;
          right: 0;
          font-size: 2em;
          font-weight: 100;
          margin: 0;
          display: block;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open i {
          color: #cc0930;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar {
          font-size: 0.9rem;
          display: initial;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar:before {
          position: initial;
          height: 26px;
          font-size: 2em;
          display: block;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-buscar.is-open:before {
          content: "\e95e";
          position: initial;
          height: 26px;
          color: #cc0930;
          font-size: 2em;
          font-weight: 500;
          display: block;
      }

      /* ----- Opcoes Menu: Login */
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button:active,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button:focus,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button:hover,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:active,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:focus,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:hover {
          position: relative;
          width: 80px;
          background: transparent;
          color: #4d4e53;
          border: none;
          text-align: center;
          padding: 0;
          margin-right: 16px;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:active,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:focus,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:hover {
          color: #cc0930;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button:before {
          content: none;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button span {
          font-size: 0.9em;
          font-weight: 400;
          margin-top: 30px;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button--login {
          line-height: 1.2;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login .bs-button i,
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button i {
          position: absolute;
          left: 0;
          right: 0;
          font-size: 2em;
          font-weight: 100;
          display: inline-block;
      }
      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button i {
          color: #cc0930;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login:before {
          display: none;
      }

      .bs-refactor .bs-header .bs-header__main .bs-container .bs-header-login.is-open .bs-button--login span {
          padding: 0;
      }

      /* Opcoes menu: Acessibilidade */
      .bs-refactor .bs-container-acessibility__mobile {
          padding: 24px;
      }
      .bs-refactor .bs-container-acessibility__mobile .bs-header__accessibility .bs-accessibility-menu__item {
          border: 1px solid #d4d3d8;
          color: #cc0930;
      }
      .bs-refactor .bs-container-acessibility__mobile .bs-header__accessibility .bs-accessibility-menu__item .bs-accessibility-menu__icon {
          font-size: 1.3rem;
          font-weight: bold;
      }

      /* ----- Menu expansivel: Principal */
      .bs-refactor .bs-header__exp-content {
          max-height: calc(100vh - 124px);
          overflow: auto;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title a {
          position: relative;
          width: 100%;
          height: 62px;
          padding: 0 72px 0 40px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid #e8e9ec;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.dropdown a:after {
          position: absolute;
          right: 24px;
          width: 40px;
          content: "\e922";
          font-size: 1.4em;
          font-family: 'bradesco_v2' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title i {
          color: #cc0930;
          font-size: 1.6em;
          padding-right: 8px;
          display: inline-flex;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title span {
          font-weight: 300;
          display: inline-flex;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title.open {
          background-color: #e8e9ed;
          //padding-left: 74px;
          border-top: none;
          border-bottom: none;
          padding: 20px 40px 20px 75px;
          display: block !important;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title.open span {
          padding-left: 32px;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title.hide {
          display: none;
      }

      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title.open:before {
          position: absolute;
          left: 40px;
          right: 0;
          width: 40px;
          content: "\e924";
          font-size: 1.4em;
          font-family: 'bradesco_v2' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.single-option {
          display: flex;
          padding: 12px 40px;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title.single-option:before {
          display: none;
      }

      /* ----- Menu expansivel: Busca */
      .bs-refactor .bs-header__exp-content .menu_search .bs-form__control {
          width: 100%;
      }
      .bs-refactor .bs-header__exp-content .menu_search .most-searched {
          width: 100%;
      }
      .bs-refactor .bs-header__exp-content .menu_search .bs-form__control label {
          display: block;
      }
      .bs-refactor .bs-header__exp-content .menu_search .bs-form__control .bs-form__field {
          margin-top: 8px;
      }

      /* ----- Menu expansivel: Login */
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login {
          position: initial;
          border-bottom: none;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item {
          padding: 0;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-header-option-section{
          display: none;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item:not(.menu_login--subitems) {
          max-width: 100%;
          flex-basis: 100%;
          background-color: transparent;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title {
          border-top: none;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item .subitem.bs-option-title:last-child {
          border-bottom: none;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item {
          max-width: 100%;
          flex-basis: 100%;
      }
      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list--login .bs-media__item.menu_login--subitems .bs-option-sub-item {
          height: 62px;
          border-bottom: 1px solid #e8e9ec;
          padding: 12px 40px;
          align-items: center;
      }

      /* ----- Logo */
      .bs-refactor .bs-header__logo {
          position: absolute;
          top: 0;
          height: 48px;
          width: 100%;
          border-bottom: 1px solid #e8e9ec;
      }
      .bs-refactor .bs-header__logo a {
          padding-bottom: 0;
          margin: 0 auto;
      }
      .bs-refactor .bs-header__logo .bs-logo,
      .bs-refactor .bs-header__logo .bs-logo svg {
          height: 40px;
      }

      /* Menu Mobile */
      .bs-refactor .bs-header__exp-content .bs-media__list {
          display: none;
          padding: 0;
      }

      .bs-refactor .bs-header__exp-content .menu_login .bs-media__list {
          margin-left: 0;
      }

      .bs-refactor .bs-header__exp-content .bs-media__list--login {
          display: block;
      }

      .bs-refactor .bs-header .bs-media__item {
          flex-basis: 100%;
          max-width: 100%;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title {
          display: block;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title a, .bs-menu-section-title-custom a {
          font-size: 1rem;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open {
          background-color: #e8e9ed;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open i {
          display: none;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open a:before {
          position: absolute;
          left: 40px;
          width: 40px;
          content: "\e924";
          font-size: 1.4em;
          font-family: 'bradesco_v2' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open a:after {
          display: none;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open a {
          padding-left: 78px;
          border-bottom: none;
      }
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open a,
      .bs-refactor .bs-header__exp-content .bs-menu-section-title.open a span {
          color: #cc0930;
          font-weight: 500;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section {
          margin-bottom: 0;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section.open {
          // background-color: #e8e9ed;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section.open a.bs-option-title {
          color: #cc0930;
          /* border-top: 1px solid #d4d3d8; */
          border-bottom: none;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section.open a {
          border-bottom: 1px solid #d4d3d8;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item a {
          // display: none;
      }
      .bs-refactor .bs-header.header-mobile .bs-header__exp-content .bs-media__item a.bs-option-title {
          height: 62px;
          border-bottom: 1px solid #e8e9ec;
          padding: 12px 72px 12px 40px;
          align-items: center;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item a.bs-option-title:before {
          position: absolute;
          right: 40px;
          content: "\e91c";
          font-size: 1.4em;
          font-family: 'bradesco_v2' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section.open a.bs-option-title:before {
          transform:rotate(180deg);
          -webkit-transform:rotate(180deg);
          -moz-transform:rotate(180deg);
          -o-transform:rotate(180deg);
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section a.bs-option-sub-item {
          height: 40px;
          padding-left: 48px;
      }
      .bs-refactor .bs-header__exp-content .bs-media__item .bs-header-option-section a.bs-option-sub-item:after {
          display: none;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg {
          background-color: #e8e9ed;
          //border-bottom: 1px solid #fff;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg a {
          font-weight: 300;
          border-bottom: 1px solid #fff;
      }


      .bs-container-acessibility__mobile {
          padding: 15px 25px;
      }

      .bs-container-acessibility__mobile .bs-header__accessibility {
          justify-content: center!important;
      }

      .bs-container-acessibility__mobile .bs-header__accessibility .bs-accessibility-menu__item {
          border: 1px solid #E8E9EC;
          padding: 3px;
          border-radius: 5px;
          margin-right: 10px;
      }

      .bs-container-acessibility__mobile .bs-header__accessibility .bs-accessibility-menu__item .bs-accessibility-menu__button {
          padding: 0;
          height: initial;
      }

      .bs-container-acessibility__mobile .bs-header__accessibility {
          padding-left: 0;
      }

      .bs-container-acessibility__mobile .bs-header__accessibility i.bs-accessibility-menu__icon {
          color: #cc0930;
          font-size: 1.6em;
          padding: 5px 6px;
          display: inline-flex;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg.open {
          background-color: #fff;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg.open a {
          color: #cc0930;
          font-weight: 500;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg.open a span{
          color: #cc0930;
          font-weight: 500;
          margin-left: 25px;
      }

      .bs-refactor .bs-header__exp-content .bs-menu-section-title-custom__bg.open a:before {
          position: absolute;
          left: 40px;
          width: 40px;
          content: "\e924";
          font-size: 1.4em;
          font-family: 'bradesco_v2' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
      }

      .bs-top-menu__group {
          padding-bottom: 7.5px;
          display: flex;
          flex-direction: column;
      }

      .bs-top-menu__group-title {
          font-size: initial;
      }

      .bs-top-menu__dropdown .bs-top-menu__link {

          color: #4d4e53;

          position: relative;
          width: 100%;
          height: 62px;
          padding: 0 72px 0 40px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e8e9ed;
      }

      #opt_5 .bs-top-menu__dropdown {
          background: #fff;
          box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
          height: auto;
          left: 0;
          opacity: 1;
          position: inherit;
          top: auto;
          visibility: visible;
          width: 100%;
          z-index: 100;
          overflow: scroll;
      }

  }
