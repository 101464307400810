//-------------------------------
// Default variables
//-------------------------------

// Base font
$base-font: 16;

// Grid
$grid-gutter: 15px;

// Easing transitions
$transition-parallax: .1s;
$transition-time: .3s;
$transition-long-time: .6s;
$ease-in-out: ease-in-out $transition-time;

// Box Shadow
$box-shadow-fixed: 0 0 10px 0 rgba($c-black, .4);
$box-shadow-inset: inset 0 0 10px 0 rgba($c-black, .2);
$box-shadow-form-field: inset 0 1px 3px 0 rgba($c-black, .1);

$box-shadow-depth: (
  "1": 0 0 10px 0 rgba($c-black, .4),
  "2": 0 6px 20px 0 rgba($c-black, .2),
) !default;

// Media Query config
// For _include-media.scss file in helpers
$breakpoints: (
  "phone":   576px,
  "tablet":  768px,
  "desktop": 992px,
  "wide":    1200px,
  "larger":  1440px
) !default;

// Z-index Depth Map config
// For _z-index.scss file in helpers
$z-depth: (
  "default":  1,
  "absolute": 100,
  "fixed":    200,
  "overlay":  300,
  "modal":    400,
) !default;
