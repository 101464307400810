@font-face {
  font-family: 'bradesco_v2';
  src:  url('../fonts/bsicons/bradesco_v2.eot');
  src:  url('../fonts/bsicons/bradesco_v2.eot#iefix') format('embedded-opentype'),
    url('../fonts/bsicons/bradesco_v2.ttf') format('truetype'),
    url('../fonts/bsicons/bradesco_v2.woff') format('woff'),
    url('../fonts/bsicons/bradesco_v2.svg#bradesco_v2') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bradesco_v2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-saude-pin-bebe:before {
  content: "\eaaa";
}
.icon-saude-fralda:before {
  content: "\eaab";
}
.icon-adiantar10:before {
  content: "\eaa1";
}
.icon-retroceder10:before {
  content: "\eaa7";
}
.icon-som-volume-medio:before {
  content: "\eaa0";
}
.icon-som-mudo:before {
  content: "\eaa2";
}
.icon-som-volume-alto:before {
  content: "\eaa3";
}
.icon-som-volume-baixo:before {
  content: "\eaa9";
}
.icon-nav-pause:before {
  content: "\eaa4";
}
.icon-info-quarto:before {
  content: "\eaa8";
}
.icon-doc-ibook:before {
  content: "\e91e";
}
.icon-nav-localizador:before {
  content: "\e906";
}
.icon-acess-baixa-visao:before {
  content: "\e9ef";
}
.icon-acess-cadeirante:before {
  content: "\e9eb";
}
.icon-acess-font-maior:before {
  content: "\e9ec";
}
.icon-acess-font-menor:before {
  content: "\e9ed";
}
.icon-acess-surdos:before {
  content: "\e9ee";
}
.icon-alerta-atencao:before {
  content: "\e9e7";
}
.icon-alerta-duvida:before {
  content: "\e9e8";
}
.icon-alerta-erro:before {
  content: "\e9e9";
}
.icon-alerta-erro2:before {
  content: "\e9f0";
}
.icon-alerta-mensagem-urgente:before {
  content: "\ea3d";
}
.icon-alerta-qrcode-erro:before {
  content: "\ea81";
}
.icon-alerta-qrcode-sucesso:before {
  content: "\ea82";
}
.icon-alerta-sucesso:before {
  content: "\e9ea";
}
.icon-alerta-transmissao-urgente:before {
  content: "\ea3a";
}
.icon-atend-adicionar-contato:before {
  content: "\e9db";
}
.icon-atend-atendente:before {
  content: "\e9dc";
}
.icon-atend-bia:before {
  content: "\e9f1";
}
.icon-atend-call-center:before {
  content: "\e9f5";
}
.icon-atend-campainha:before {
  content: "\e9f6";
}
.icon-atend-cel:before {
  content: "\e9dd";
}
.icon-atend-celular:before {
  content: "\e9de";
}
.icon-atend-chat:before {
  content: "\e9df";
}
.icon-atend-chatbot:before {
  content: "\e9e0";
}
.icon-atend-chat-online:before {
  content: "\e9f7";
}
.icon-atend-email:before {
  content: "\e9e2";
}
.icon-atend-email-celular:before {
  content: "\ea28";
}
.icon-atend-email-envio:before {
  content: "\e9e1";
}
.icon-atend-fixo:before {
  content: "\e9e3";
}
.icon-atend-mensagem-celular:before {
  content: "\e9f8";
}
.icon-atend-presencial:before {
  content: "\e9e4";
}
.icon-atend-sms:before {
  content: "\e9e5";
}
.icon-atend-telefone:before {
  content: "\e9e6";
}
.icon-auto-app-seguro:before {
  content: "\e9f9";
}
.icon-auto-assistencia:before {
  content: "\e9fb";
}
.icon-auto-autoline:before {
  content: "\ea83";
}
.icon-auto-autoridades:before {
  content: "\e9ce";
}
.icon-auto-avaliacao-risco:before {
  content: "\ea84";
}
.icon-auto-cambio:before {
  content: "\e9cf";
}
.icon-auto-caminhao:before {
  content: "\e9d0";
}
.icon-auto-coberturas-adicionais:before {
  content: "\e9d1";
}
.icon-auto-completo:before {
  content: "\e9d2";
}
.icon-auto-condutor:before {
  content: "\e9d3";
}
.icon-auto-corretores:before {
  content: "\e9d4";
}
.icon-auto-oficinas-guinchos:before {
  content: "\e9d5";
}
.icon-auto-onibus:before {
  content: "\e9fc";
}
.icon-auto-passageiro:before {
  content: "\e9ff";
}
.icon-auto-personalizado:before {
  content: "\ea1b";
}
.icon-auto-portas:before {
  content: "\e9d6";
}
.icon-auto-posto:before {
  content: "\ea00";
}
.icon-auto-protecao:before {
  content: "\e9d7";
}
.icon-auto-restricao:before {
  content: "\ea85";
}
.icon-auto-roubo-veiculo:before {
  content: "\ea11";
}
.icon-auto-sinistro:before {
  content: "\e9d8";
}
.icon-auto-tracar-rota:before {
  content: "\e9d9";
}
.icon-auto-veiculo:before {
  content: "\e9da";
}
.icon-dados-cpf:before {
  content: "\e9c8";
}
.icon-dados-estado-civil:before {
  content: "\ea01";
}
.icon-dados-grafico:before {
  content: "\ea04";
}
.icon-dados-meu-cartao:before {
  content: "\ea05";
}
.icon-dados-profissao:before {
  content: "\e9c9";
}
.icon-dados-sexo:before {
  content: "\e9ca";
}
.icon-dados-usuario:before {
  content: "\e9cb";
}
.icon-doc-2a-via:before {
  content: "\ea19";
}
.icon-doc-add-calendario:before {
  content: "\e9bc";
}
.icon-doc-alerta:before {
  content: "\e9bd";
}
.icon-doc-analisar:before {
  content: "\e9be";
}
.icon-doc-calendario:before {
  content: "\e9bf";
}
.icon-doc-calendario-proximo:before {
  content: "\ea08";
}
.icon-doc-carencia:before {
  content: "\e9c0";
}
.icon-doc-clausulas:before {
  content: "\ea86";
}
.icon-doc-coleta-documentos:before {
  content: "\ea09";
}
.icon-doc-consulta:before {
  content: "\ea0b";
}
.icon-doc-contratos:before {
  content: "\e9c1";
}
.icon-doc-demonstrativo:before {
  content: "\ea87";
}
.icon-doc-dicionario:before {
  content: "\e9c2";
}
.icon-doc-doc:before {
  content: "\ea88";
}
.icon-doc-documento:before {
  content: "\e9c3";
}
.icon-doc-documento-facil:before {
  content: "\ea0c";
}
.icon-doc-form:before {
  content: "\e9c4";
}
.icon-doc-html:before {
  content: "\ea89";
}
.icon-doc-inclusa-itens:before {
  content: "\ea8a";
}
.icon-doc-papel:before {
  content: "\ea0d";
}
.icon-doc-pdf:before {
  content: "\e9c5";
}
.icon-doc-prancheta:before {
  content: "\e9c6";
}
.icon-doc-protocolos:before {
  content: "\e9c7";
}
.icon-doc-questionario:before {
  content: "\ea8b";
}
.icon-doc-questionario-avaliacao-risco:before {
  content: "\ea8c";
}
.icon-doc-renovacao-apolice:before {
  content: "\ea06";
}
.icon-doc-susep:before {
  content: "\ea8d";
}
.icon-doc-xls:before {
  content: "\ea8e";
}
.icon-fin-acompanhamento-proposta:before {
  content: "\ea0e";
}
.icon-fin-acordo:before {
  content: "\e99c";
}
.icon-fin-adicionar-carteira:before {
  content: "\ea0f";
}
.icon-fin-banco:before {
  content: "\e99d";
}
.icon-fin-boleto:before {
  content: "\e99e";
}
.icon-fin-caixa:before {
  content: "\e99f";
}
.icon-fin-cartao:before {
  content: "\e9a0";
}
.icon-fin-cartoes:before {
  content: "\ea12";
}
.icon-fin-cheque:before {
  content: "\e9a1";
}
.icon-fin-compra-online:before {
  content: "\e9a2";
}
.icon-fin-compra-online2:before {
  content: "\ea13";
}
.icon-fin-comprar:before {
  content: "\e9a3";
}
.icon-fin-compras:before {
  content: "\e9a4";
}
.icon-fin-contribuicao:before {
  content: "\e9a5";
}
.icon-fin-custo:before {
  content: "\e9a6";
}
.icon-fin-custo-total:before {
  content: "\ea15";
}
.icon-fin-debito-online:before {
  content: "\e9a7";
}
.icon-fin-debito-online2:before {
  content: "\ea16";
}
.icon-fin-deposito-cheque:before {
  content: "\e9a8";
}
.icon-fin-despesas:before {
  content: "\ea17";
}
.icon-fin-dinheiro:before {
  content: "\ea18";
}
.icon-fin-fatura:before {
  content: "\e9a9";
}
.icon-fin-formas-pagamento:before {
  content: "\ea8f";
}
.icon-fin-guardar-dinheiro:before {
  content: "\ea1a";
}
.icon-fin-imposto-renda:before {
  content: "\ea1c";
}
.icon-fin-industria:before {
  content: "\e9aa";
}
.icon-fin-investimento:before {
  content: "\e9ab";
}
.icon-fin-ir:before {
  content: "\e9ac";
}
.icon-fin-nota-fiscal:before {
  content: "\e9ad";
}
.icon-fin-novo-cartao-pronto:before {
  content: "\ea90";
}
.icon-fin-pgbl:before {
  content: "\e9ae";
}
.icon-fin-poupanca:before {
  content: "\e9af";
}
.icon-fin-preco-acessivel:before {
  content: "\e9b0";
}
.icon-fin-premios:before {
  content: "\e9b1";
}
.icon-fin-previa-reembolso:before {
  content: "\ea76";
}
.icon-fin-receber:before {
  content: "\e9b2";
}
.icon-fin-receber-valor:before {
  content: "\e9fe";
}
.icon-fin-recibo:before {
  content: "\e9b3";
}
.icon-fin-reembolso:before {
  content: "\ea1d";
}
.icon-fin-rendimento:before {
  content: "\e9b4";
}
.icon-fin-resgate:before {
  content: "\e9b5";
}
.icon-fin-resumo:before {
  content: "\ea10";
}
.icon-fin-sacar-cheque:before {
  content: "\e9b6";
}
.icon-fin-saldo:before {
  content: "\e9b7";
}
.icon-fin-sem-custo:before {
  content: "\e9b8";
}
.icon-fin-simulacao:before {
  content: "\e9b9";
}
.icon-fin-solicitar-previa-reembolso:before {
  content: "\ea77";
}
.icon-fin-tranquilidade:before {
  content: "\e9ba";
}
.icon-fin-transferencia:before {
  content: "\e9bb";
}
.icon-info-anunciar:before {
  content: "\e975";
}
.icon-info-bilhete-sorteio:before {
  content: "\ea1e";
}
.icon-info-bloqueio-aberto:before {
  content: "\e976";
}
.icon-info-caixao:before {
  content: "\ea20";
}
.icon-info-circuito-cultural:before {
  content: "\ea70";
}
.icon-info-clique:before {
  content: "\ea29";
}
.icon-info-consultoria-sustentavel:before {
  content: "\ea23";
}
.icon-info-dados-gerais:before {
  content: "\ea91";
}
.icon-info-dados-renovacao:before {
  content: "\ea92";
}
.icon-info-desconto-loja:before {
  content: "\e977";
}
.icon-info-descubra:before {
  content: "\ea24";
}
.icon-info-dia-noite:before {
  content: "\e978";
}
.icon-info-diversidade:before {
  content: "\e979";
}
.icon-info-educacao:before {
  content: "\e97a";
}
.icon-info-embaralhar:before {
  content: "\ea25";
}
.icon-info-emergencia:before {
  content: "\e97b";
}
.icon-info-empresa:before {
  content: "\e97c";
}
.icon-info-endereco:before {
  content: "\e97d";
}
.icon-info-endereco-correspondencia:before {
  content: "\eb02";
}
.icon-info-endereco-de-risco:before {
  content: "\eb03";
}
.icon-info-estrategia:before {
  content: "\e97e";
}
.icon-info-explosao:before {
  content: "\ea26";
}
.icon-info-familia:before {
  content: "\ea27";
}
.icon-info-familiar:before {
  content: "\e97f";
}
.icon-info-foto:before {
  content: "\e980";
}
.icon-info-funcionarios:before {
  content: "\e981";
}
.icon-info-galeria-fotos:before {
  content: "\ea2a";
}
.icon-info-gestante:before {
  content: "\ea2b";
}
.icon-info-ideia:before {
  content: "\e982";
}
.icon-info-importante:before {
  content: "\ea2c";
}
.icon-info-incendio:before {
  content: "\e983";
}
.icon-info-inclusao-exclusao:before {
  content: "\ea2d";
}
.icon-info-infantil:before {
  content: "\ea2e";
}
.icon-info-informar:before {
  content: "\e984";
}
.icon-info-informatica:before {
  content: "\e985";
}
.icon-info-invalidez:before {
  content: "\e986";
}
.icon-info-ladrao:before {
  content: "\e987";
}
.icon-info-lavadeira:before {
  content: "\e988";
}
.icon-info-limpeza:before {
  content: "\e989";
}
.icon-info-livre-escolha:before {
  content: "\e98a";
}
.icon-info-local-inspecao:before {
  content: "\ea93";
}
.icon-info-localizar-mapa:before {
  content: "\e98b";
}
.icon-info-localizar-sucursal:before {
  content: "\ea2f";
}
.icon-info-loja:before {
  content: "\e98c";
}
.icon-info-mobile:before {
  content: "\e98d";
}
.icon-info-morte:before {
  content: "\e98e";
}
.icon-info-mover:before {
  content: "\ea60";
}
.icon-info-nautico:before {
  content: "\e98f";
}
.icon-info-nome:before {
  content: "\ea94";
}
.icon-info-noticias:before {
  content: "\ea71";
}
.icon-info-objeto-risco:before {
  content: "\ea95";
}
.icon-info-oferta:before {
  content: "\e9f4";
}
.icon-info-operacao-especial:before {
  content: "\ea96";
}
.icon-info-outros-seguros:before {
  content: "\e990";
}
.icon-info-pensar:before {
  content: "\ea30";
}
.icon-info-perfil:before {
  content: "\e991";
}
.icon-info-perfil-corretor:before {
  content: "\ea31";
}
.icon-info-perfil-corretor2:before {
  content: "\ea33";
}
.icon-info-perfil-corretora:before {
  content: "\ea34";
}
.icon-info-perfil-dentista:before {
  content: "\ea35";
}
.icon-info-perfil-medico:before {
  content: "\ea36";
}
.icon-info-pet:before {
  content: "\e992";
}
.icon-info-play:before {
  content: "\e993";
}
.icon-info-premio-individual:before {
  content: "\ea97";
}
.icon-info-premio-item:before {
  content: "\ea98";
}
.icon-info-premio-total:before {
  content: "\ea99";
}
.icon-info-previdencia:before {
  content: "\e994";
}
.icon-info-qualidade:before {
  content: "\e995";
}
.icon-info-qualificao:before {
  content: "\e9fa";
}
.icon-info-re:before {
  content: "\e9fd";
}
.icon-info-relogio:before {
  content: "\ea37";
}
.icon-info-reparos:before {
  content: "\ea07";
}
.icon-info-responsabilidade-civil:before {
  content: "\e996";
}
.icon-info-restricao-coberturas:before {
  content: "\eb04";
}
.icon-info-resultado-sorteio:before {
  content: "\e997";
}
.icon-info-resumo-itens:before {
  content: "\ea9a";
}
.icon-info-sala-vip:before {
  content: "\ea14";
}
.icon-info-senha-eletronica:before {
  content: "\ea21";
}
.icon-info-situacao-risco:before {
  content: "\ea9b";
}
.icon-info-sobre:before {
  content: "\ea6e";
}
.icon-info-solucoes-negocio:before {
  content: "\e998";
}
.icon-info-status-senha:before {
  content: "\ea75";
}
.icon-info-ticket:before {
  content: "\ea32";
}
.icon-info-traducao:before {
  content: "\e999";
}
.icon-info-trofeu:before {
  content: "\e99a";
}
.icon-info-unlink:before {
  content: "\ea61";
}
.icon-info-vida:before {
  content: "\ea1f";
}
.icon-info-web:before {
  content: "\e99b";
}
.icon-info-wifi:before {
  content: "\ea62";
}
.icon-nav-adicionar:before {
  content: "\e958";
}
.icon-nav-alvo:before {
  content: "\ea6d";
}
.icon-nav-anexar:before {
  content: "\e959";
}
.icon-nav-apresentacao:before {
  content: "\e95a";
}
.icon-nav-atualizar:before {
  content: "\e95b";
}
.icon-nav-audio:before {
  content: "\e95c";
}
.icon-nav-baixar:before {
  content: "\e95d";
}
.icon-nav-busca:before {
  content: "\e95e";
}
.icon-nav-cadastrar-senha:before {
  content: "\e95f";
}
.icon-nav-check:before {
  content: "\ea74";
}
.icon-nav-compartilhar:before {
  content: "\e960";
}
.icon-nav-configuracoes:before {
  content: "\e961";
}
.icon-nav-conversa:before {
  content: "\e962";
}
.icon-nav-desfazer:before {
  content: "\ea9c";
}
.icon-nav-desligar:before {
  content: "\e963";
}
.icon-nav-editar:before {
  content: "\e964";
}
.icon-nav-enviar:before {
  content: "\ea3b";
}
.icon-nav-expandir:before {
  content: "\ea9d";
}
.icon-nav-exportar:before {
  content: "\ea72";
}
.icon-nav-favoritos:before {
  content: "\ea9e";
}
.icon-nav-fechar:before {
  content: "\e965";
}
.icon-nav-filme:before {
  content: "\e966";
}
.icon-nav-filtro:before {
  content: "\ea73";
}
.icon-nav-imprimir:before {
  content: "\e967";
}
.icon-nav-informacoes:before {
  content: "\e968";
}
.icon-nav-login:before {
  content: "\e969";
}
.icon-nav-login2:before {
  content: "\ea9f";
}
.icon-nav-mensagem:before {
  content: "\ea3e";
}
.icon-nav-menu-hamburger:before {
  content: "\ea3f";
}
.icon-nav-notificacao:before {
  content: "\ea6c";
}
.icon-nav-ocultar:before {
  content: "\e96a";
}
.icon-nav-organizar:before {
  content: "\ea7b";
}
.icon-nav-pasta:before {
  content: "\e96b";
}
.icon-nav-produtos:before {
  content: "\e96c";
}
.icon-nav-proibido:before {
  content: "\e9f3";
}
.icon-nav-remover:before {
  content: "\e96d";
}
.icon-nav-reportar:before {
  content: "\e96e";
}
.icon-nav-sair:before {
  content: "\e96f";
}
.icon-nav-salvar:before {
  content: "\eb01";
}
.icon-nav-seguir:before {
  content: "\e970";
}
.icon-nav-selecionar:before {
  content: "\e971";
}
.icon-nav-servicos:before {
  content: "\ea6b";
}
.icon-nav-som:before {
  content: "\e972";
}
.icon-nav-vazio:before {
  content: "\ea6f";
}
.icon-nav-visualizar:before {
  content: "\e973";
}
.icon-nav-voltar:before {
  content: "\e974";
}
.icon-nav-zoom-in:before {
  content: "\ea46";
}
.icon-nav-zoom-out:before {
  content: "\ea47";
}
.icon-redes-facebook:before {
  content: "\e951";
}
.icon-redes-getlink:before {
  content: "\ea7c";
}
.icon-redes-google:before {
  content: "\e952";
}
.icon-redes-instagram:before {
  content: "\e953";
}
.icon-redes-linkedin:before {
  content: "\e954";
}
.icon-redes-twitter:before {
  content: "\e955";
}
.icon-redes-whatsapp:before {
  content: "\e956";
}
.icon-redes-youtube:before {
  content: "\e957";
}
.icon-residencial-antenas:before {
  content: "\e93a";
}
.icon-residencial-assist:before {
  content: "\e93b";
}
.icon-residencial-bens:before {
  content: "\e93c";
}
.icon-residencial-chaveiro:before {
  content: "\e93d";
}
.icon-residencial-cobertura:before {
  content: "\e93e";
}
.icon-residencial-condominio:before {
  content: "\ea7d";
}
.icon-residencial-descarte:before {
  content: "\e93f";
}
.icon-residencial-eletricista:before {
  content: "\e940";
}
.icon-residencial-encanador:before {
  content: "\e941";
}
.icon-residencial-explosao:before {
  content: "\e942";
}
.icon-residencial-linha-branca:before {
  content: "\e943";
}
.icon-residencial-lixeira:before {
  content: "\e944";
}
.icon-residencial-obra:before {
  content: "\e945";
}
.icon-residencial-patrimonio:before {
  content: "\e946";
}
.icon-residencial-personalizado:before {
  content: "\e947";
}
.icon-residencial-provisoria:before {
  content: "\e948";
}
.icon-residencial-quadros:before {
  content: "\e949";
}
.icon-residencial-raio:before {
  content: "\e94a";
}
.icon-residencial-reparo:before {
  content: "\e94b";
}
.icon-residencial-restaurante:before {
  content: "\e94c";
}
.icon-residencial-telhas:before {
  content: "\e94d";
}
.icon-residencial-vendaval:before {
  content: "\e94e";
}
.icon-residencial-vidraceiro:before {
  content: "\e94f";
}
.icon-residencial-vigilancia:before {
  content: "\e950";
}
.icon-saude-acidentes:before {
  content: "\ea40";
}
.icon-saude-agendar-consulta:before {
  content: "\e926";
}
.icon-saude-alimentacao-saudavel:before {
  content: "\ea4b";
}
.icon-saude-ambulancia:before {
  content: "\e927";
}
.icon-saude-a-pe:before {
  content: "\ea42";
}
.icon-saude-calendario:before {
  content: "\e9f2";
}
.icon-saude-cardiograma:before {
  content: "\e928";
}
.icon-saude-cirurgia-plastica:before {
  content: "\ea43";
}
.icon-saude-concierge:before {
  content: "\ea22";
}
.icon-saude-curativos:before {
  content: "\ea44";
}
.icon-saude-dental-cirurgia:before {
  content: "\e929";
}
.icon-saude-dental-dentedeleite:before {
  content: "\ea78";
}
.icon-saude-dental-dentista:before {
  content: "\e92a";
}
.icon-saude-dental-endodontia:before {
  content: "\e92b";
}
.icon-saude-dental-ideal:before {
  content: "\ea79";
}
.icon-saude-dental-junior:before {
  content: "\ea7a";
}
.icon-saude-dental-odontopediatria:before {
  content: "\e92c";
}
.icon-saude-dental-pais:before {
  content: "\e92d";
}
.icon-saude-dental-periodontia:before {
  content: "\e92e";
}
.icon-saude-dental-prevencao:before {
  content: "\e92f";
}
.icon-saude-dental-protese:before {
  content: "\e930";
}
.icon-saude-dental-radiologia:before {
  content: "\e931";
}
.icon-saude-dermatologia:before {
  content: "\e932";
}
.icon-saude-despesas-medicas:before {
  content: "\ea45";
}
.icon-saude-diaria-hospitalar:before {
  content: "\e933";
}
.icon-saude-diaria-hospitalar2:before {
  content: "\ea48";
}
.icon-saude-dieta:before {
  content: "\ea49";
}
.icon-saude-farmacias-proximas:before {
  content: "\ea4a";
}
.icon-saude-funeral:before {
  content: "\ea4c";
}
.icon-saude-ganho-peso:before {
  content: "\ea4d";
}
.icon-saude-homeopatia:before {
  content: "\e934";
}
.icon-saude-hospitais-proximos:before {
  content: "\ea4e";
}
.icon-saude-hospital:before {
  content: "\e935";
}
.icon-saude-infantil:before {
  content: "\e936";
}
.icon-saude-medicamento:before {
  content: "\ea4f";
}
.icon-saude-medico:before {
  content: "\e937";
}
.icon-saude-perda-peso:before {
  content: "\ea50";
}
.icon-saude-pilula:before {
  content: "\ea51";
}
.icon-saude-plano-dental:before {
  content: "\ea52";
}
.icon-saude-planos-saude:before {
  content: "\e938";
}
.icon-saude-primeiros-socorros:before {
  content: "\e939";
}
.icon-saude-relatorio-medico:before {
  content: "\ea03";
}
.icon-saude-soro:before {
  content: "\ea53";
}
.icon-saude-vacinas:before {
  content: "\ea3c";
}
.icon-saude-viagem:before {
  content: "\ea0a";
}
.icon-seta-baixo-a:before {
  content: "\e91a";
}
.icon-seta-baixo-b:before {
  content: "\e91b";
}
.icon-seta-baixo-c:before {
  content: "\e91c";
}
.icon-seta-cima-a:before {
  content: "\e91d";
}
.icon-seta-cima-b:before {
  content: "\ea7e";
}
.icon-seta-cima-c:before {
  content: "\e91f";
}
.icon-seta-direita-a:before {
  content: "\e920";
}
.icon-seta-direita-b:before {
  content: "\e921";
}
.icon-seta-direita-c:before {
  content: "\e922";
}
.icon-seta-dupla-b:before {
  content: "\ea59";
}
.icon-seta-dupla-direita-b:before {
  content: "\ea5a";
}
.icon-seta-esquerda-a:before {
  content: "\e923";
}
.icon-seta-esquerda-b:before {
  content: "\e924";
}
.icon-seta-esquerda-c:before {
  content: "\e925";
}
.icon-seta-longa-baixo-c:before {
  content: "\ea54";
}
.icon-seta-longa-cima-c:before {
  content: "\ea55";
}
.icon-seta-ponta-baixo:before {
  content: "\ea63";
}
.icon-seta-ponta-cima:before {
  content: "\ea64";
}
.icon-seta-ponta-direita:before {
  content: "\ea65";
}
.icon-seta-ponta-esquerda:before {
  content: "\ea66";
}
.icon-seta-solida-baixo:before {
  content: "\ea67";
}
.icon-seta-solida-cima:before {
  content: "\ea68";
}
.icon-seta-solida-direita:before {
  content: "\ea69";
}
.icon-seta-solida-esquerda:before {
  content: "\ea6a";
}
.icon-shop-estrela:before {
  content: "\e9cc";
}
.icon-shop-shopping:before {
  content: "\e9cd";
}
.icon-vb-beneficios:before {
  content: "\e90e";
}
.icon-vb-beneficios2:before {
  content: "\ea56";
}
.icon-vb-bercario:before {
  content: "\e90f";
}
.icon-vb-circuito-cultural:before {
  content: "\ea57";
}
.icon-vb-danca:before {
  content: "\e910";
}
.icon-vb-documentarios:before {
  content: "\e911";
}
.icon-vb-exposicoes:before {
  content: "\e912";
}
.icon-vb-exposicoes2:before {
  content: "\ea58";
}
.icon-vb-facilidade:before {
  content: "\ea5b";
}
.icon-vb-facilidades:before {
  content: "\e913";
}
.icon-vb-jazz:before {
  content: "\e914";
}
.icon-vb-livro:before {
  content: "\e915";
}
.icon-vb-musical:before {
  content: "\e916";
}
.icon-vb-recompensas:before {
  content: "\e917";
}
.icon-vb-servicos:before {
  content: "\ea7f";
}
.icon-vb-teatro:before {
  content: "\e918";
}
.icon-vb-todas:before {
  content: "\e919";
}
.icon-vert-auto:before {
  content: "\e907";
}
.icon-vert-capitalizacao:before {
  content: "\e908";
}
.icon-vert-dental:before {
  content: "\e909";
}
.icon-vert-previdencia:before {
  content: "\e90a";
}
.icon-vert-residencial:before {
  content: "\e90b";
}
.icon-vert-saude:before {
  content: "\e90c";
}
.icon-vert-vida:before {
  content: "\e90d";
}
.icon-viagem-abrangencia:before {
  content: "\e900";
}
.icon-viagem-acessoria:before {
  content: "\e901";
}
.icon-viagem-acessoria-mundial:before {
  content: "\ea5c";
}
.icon-viagem-bagagem:before {
  content: "\e902";
}
.icon-viagem-cancelamento:before {
  content: "\ea5d";
}
.icon-viagem-hospedagem:before {
  content: "\e903";
}
.icon-viagem-mala:before {
  content: "\ea5e";
}
.icon-viagem-perda:before {
  content: "\e904";
}
.icon-viagem-perda-bagagem:before {
  content: "\ea5f";
}
.icon-viagem-pousar:before {
  content: "\e905";
}
.icon-viagem-regresso:before {
  content: "\ea02";
}
.icon-viagem-seguro:before {
  content: "\ea80";
}
.icon-viagem-seguro2:before {
  content: "\ea41";
}
.icon-viagem-translado-corpo:before {
  content: "\ea38";
}
.icon-viagem-translado-medico:before {
  content: "\ea39";
}

@font-face {
  font-family: 'bradesco_v3';
  src:  url('../fonts/bsiconsV3/bradesco_v3.eot?4kdzqw');
  src:  url('../fonts/bsiconsV3/bradesco_v3.eot?4kdzqw#iefix') format('embedded-opentype'),
    url('../fonts/bsiconsV3/bradesco_v3.ttf?4kdzqw') format('truetype'),
    url('../fonts/bsiconsV3/bradesco_v3.woff?4kdzqw') format('woff'),
    url('../fonts/bsiconsV3/bradesco_v3.svg?4kdzqw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon_v3"], [class*=" icon_v3"] {
  font-family: 'bradesco_v3' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_v3-avancar10:before {
  content: "\e900";
}
.icon_v3-alerta-basico:before {
  content: "\e901";
}
.icon_v3-dots:before {
  content: "\e902";
}
.icon_v3-pause:before {
  content: "\e903";
}
.icon_v3-regressar10:before {
  content: "\e904";
}
.icon_v3-som-mudo:before {
  content: "\e905";
}
.icon_v3-som-volume-alto:before {
  content: "\e906";
}
.icon_v3-som-volume-baixo:before {
  content: "\e907";
}
