.bs-dropdown-user {
  &__header {
    flex-direction: column;

    .bs-dropdown-user__name {
      font-size: rem(16);
      font-weight: fw("bold");
      margin-bottom: 3px;
    }

    .bs-button-link {
      margin: $grid-gutter 0 5px 0;
    }
  }

  &__info {
    display: flex;
  }

  &__info-text {
    display: none;

    @include media(">=tablet") {
      color: $c-gray-darker;
      display: block;
      text-align: right;
    }
  }

  &__name {
    font-size: rem(12);
  }

  &__role {
    display: block;
    font-size: rem(8);
    font-style: italic;
  }

  &__session {
    font-size: rem(12);
  }

  &__company {
    color: $c-gray-dark;
    display: block;
    font-size: rem(14);
    font-weight: fw("semibold");

    &--title {
      color: $c-primary;
      font-size: rem(12);
      font-weight: fw("light");
      margin-bottom: 3px;
    }
  }

  &__cnpj {
    color: $c-gray-dark;
    font-size: rem(14);
  }

  &__img {
    border-radius: 50%;
    border: 1px solid $c-primary;
    height: 30px;
    overflow: hidden;
    width: 30px;

    @include media(">=tablet") {
      height: 40px;
      margin-left: $grid-gutter;
      width: 40px;
    }
  }

  .bs-dropdown-menu__title {
    text-transform: uppercase;
  }

  .bs-dropdown-menu__item {
    > a {
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}