//-------------------------------
// Breadcrumb
// Componente de breadcrumbs padrão
//-------------------------------
.bs-breadcrumb {
  background-color: $c-gray-lighter;
  padding: $grid-gutter 0;

  .bs-container {
    display: flex;
    align-items: center;
  }

  li {
    display: inline-block;
    position: relative;

    &:hover {
      .bs-breadcrumb__submenu {
        display: block;
      }
    }
  }

  &__link {
    color: $c-gray-darker;
    font: {
      family: $font-title;
      size: rem(14);
    }

    @include media(">=tablet") {
      font-size: rem(16);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__current {
    color: $c-gray-darker;
    font: {
      family: $font-title;
      size: rem(14);
      weight: fw("bold");
    }

    @include media(">=tablet") {
      font-size: rem(16);
    }
  }

  &__icon {
    color: $c-primary-light;
    display: inline-block;
    font-size: rem(10);
    margin: 0 8px;

    @include media(">=tablet") {
      font-size: rem(12);
      margin: 0 10px;
    }
  }

  &__submenu {
    background:$c-white;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    display: none;
    left: -8px;
    min-width: 215px;
    position: absolute;
    top: 21px;
    z-index: 99;

    li {
      display: block;
    }
  }

  &__sublink {
    display: block;
    font-size: 14px;
    padding: 11px 20px;

    &:hover {
      background-color: $c-gray-lighter;
    }
  }
}
