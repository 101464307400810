//-------------------------------
// Social Media
// Lista de redes sociais
//
// TODO: Adequar estilo da lista (existe no rodapé) ao padrão do header
//-------------------------------
.bs-social {

  &__list {
    display: flex;
  }

  &__item {}

  &__link {
    align-items: center;
    display: flex;
    font-size: rem(16);
    line-height: 20px;
    padding: 10px;
    transition: 0.2s;
    margin-right: 3px;

    &:hover {
      background-color: $c-gray-lighter;
    }
  }

  &__icon {
    color: $c-gray-darker;
  }

  // variações social media
  &--primary {
    .bs-social__icon {
      color: $c-red;
    }
  }
}


.bs-share {
  align-items: center;
  display: flex;
  flex-wrap: wrap;


  &__title {
    margin-right: 10px;
  }

  &--border-top {
    border-top: 1px solid $c-gray-light;
    margin-top: 30px;
    padding-top: 30px;
  }

}
