.bs-classind {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 20px;
  width: 20px;

  &--l {
    background-image: url("../img/icons/classind-l.svg");
  }

  &--10 {
    background-image: url("../img/icons/classind-10.svg");
  }

  &--12 {
    background-image: url("../img/icons/classind-12.svg");
  }

  &--14 {
    background-image: url("../img/icons/classind-14.svg");
  }

  &--16 {
    background-image: url("../img/icons/classind-16.svg");
  }

  &--18 {
    background-image: url("../img/icons/classind-18.svg");
  }
}
