.bs-progress-bar {
  display: flex;
  flex-direction: column;

  &__title {
    color: $c-gray-darker;
    font-family: $font-title;
    margin-bottom: 5px;
    text-align: center;
  }

  progress {
    background-color: $c-gray;
    border-radius: 5px;
    border: none;
    color: $c-primary; // IE
    height: 8px;
    overflow: hidden;
    width: 100%;

    &::-moz-progress-bar {
      background-color: $c-primary;
      transition: $transition-time;
    }

    &::-webkit-progress-value {
      background-color: $c-primary;
      transition: $transition-time;
    }
  }
}