//-------------------------------
// Main Nav (old) [deprecated]
// Menu principal
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-main-nav {
  position: fixed;
  right: 100%;
  top: 0;
}
