//-------------------------------
// Icon Grid (old) [deprecated]
// Grid (linhas/colunas) de links com ícone e título
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-icon-grid {
  display: block;
  width: 100%;

  &__list {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__item {
    color: $c-white;
    display: block;
    margin-bottom: 20px;
    padding: 0 8px;
    text-align: center;
    width: 33.333%;

    @include media(">=tablet") {
      margin-bottom: 50px;
      width: 25%;
    }

    @include media(">=desktop") {
      width: 20%;
    }

    &:hover {

      @include media(">=tablet") {

        .icon {
          top: -5px;
          transition: $transition-time;
        }

        .bs-icon-grid__title::after {
          bottom: -10px;
          opacity: 1;
          transition: $transition-time;
        }
      }
    }

    a {
      color: inherit;
      font: inherit;
    }
  }

  &__container {
    display: block;
  }

  &__figure {
    display: block;
    height: 42px;

    @include media(">=tablet") {
      height: 60px;
      margin-bottom: 10px;
    }

    .icon {
      font-size: rem(40);
      position: relative;
      top: 0;
      transition: $transition-time;

      @include media(">=tablet") {
        font-size: rem(50);
      }

      @include media(">=desktop") {
        font-size: rem(65);
      }
    }
  }

  &__title {
    display: block;
    font-size: rem(12);
    font-weight: 400;
    margin: 0 auto;
    max-width: 180px;
    position: relative;

    @include media(">=tablet") {
      font-size: rem(16);
    }

    @include media(">=desktop") {
      font-size: rem(18);
    }

    &::after {
      @include media(">=tablet") {
        background: $c-white;
        bottom: -20px;
        content: "";
        height: 2px;
        left: 50%;
        margin-left: -25px;
        opacity: 0;
        position: absolute;
        width: 50px;
      }
    }
  }
}
