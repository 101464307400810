
.bs-help-search { 

  &__input {
    width: 100%;
    height: rem(50);
    margin-bottom: rem(30);
    padding: rem(10) rem(15);
    font-size: rem(40);
    color: orangered;
    border: rem(1) solid orangered;
  }

  &__highlight {
    display: block;
  }

  &__remove-highlight {
    display: none;
  }
    
  &__search-empty {
    background-color: #f1f1f1;
    font-size: 14px;
    display: none;
    margin: 20px 0;
    padding: 15px;
  }

  &__search-empty.bs-active {
    display: block;
  }
}