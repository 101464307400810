//-------------------------------
// Ícone de notificação [deprecated]
// Usado no header-logado antigo
//
// Depreciado porque o componente header-logado foi depreciado
// e o novo componente de notificações foi refeito em outro formato
//-------------------------------
.bs-icon-notification {
  background-color: $c-primary-light;
  border-radius: 50%;
  display: inline-block;
  max-height: 24px;
  max-width: 24px;
  min-height: 22px;
  min-width: 22px;
  opacity: 0;
  padding: 3px;
  transform: scale(.9) translateY(5px);
  transition: all .15s ease-in-out;
  visibility: hidden;

  span {
    color: $c-white;
    display: block;
    font-family: $font-text;
    font-size: rem(11);
    line-height: 0;
    padding-bottom: 50%;
    padding-top: 50%
  }
}
