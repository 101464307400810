//-------------------------------
// Modal Info
// Modal com conteúdos de informação, tendo um ícone ou imagem principal
//-------------------------------
.modal-info {

  &__content {}

  &__figure {
    margin: {
      bottom: ($grid-gutter * 2);
      left: (-$grid-gutter * 3);
      right: (-$grid-gutter * 3);
      top: (-$grid-gutter * 2);
    };
    position: relative;

    img {
      height: auto;
      width: 100%;
    }
  }

  &__utils {
    bottom: 0;
    display: flex;
    padding: 20px 45px 0px;
    width: 100%;

    @include media(">=tablet") {
      padding: 20px 45px;
      position: absolute;
    }
  }

  &__age {
    align-items: center;
    background-color: $c-yellow-dark;
    border-radius: 5px;
    color: $c-white;
    display: flex;
    font-size: 16px;
    padding: 5px 8px;
  }

  &__category {
    background: -moz-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 2%, rgba(0,0,0,0.4) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.4) 2%,rgba(0,0,0,0.4) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.4) 2%,rgba(0,0,0,0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border: 1px solid $c-white;
    color: $c-white;
    font-size: 13px;
    margin-left: 10px;
    padding: 5px 12px;
    text-transform: uppercase;
  }

  &__text {}

  &--icon {
    .modal-info__figure {
      display: none;

      @include media(">=tablet") {
        display: block;
        margin: 0;
      }
    }

    .modal-info__content {
      display: flex;
      margin: 0 (-$grid-gutter);
    }

    .modal-info__figure,
    .modal-info__text {
      flex: 1 1 auto;
      padding: 0 $grid-gutter;
    }
  }
}
