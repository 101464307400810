//-------------------------------
// Menu de Acessibilidade
// Lista as opções de acessibilidade do site e geralmente fica no top header
//-------------------------------
.bs-accessibility-menu {

  // Lista de itens
  &__list {
    display: flex;
    margin: 0 -10px;
  }

  // Item
  &__item {}

  // Botão para ativar as funções
  &__button {
    align-items: center;
    display: flex;
    line-height: 20px;
    padding: 5px 10px;
  }

  // Ícone do botão
  &__icon {
    font-size: rem(18);
  }

  // Texto de Acessibilidade
  // Fica escondido visualmente, mas mantém a
  // marcação para leitores de tela
  &__screen-reader {
    @include sr-only();
  }
}
// Customização HandTalk icon
.ht-skip {
  // display: none;
}
.ht-skip.open {
  // display: block;
}
