//-------------------------------
// Flip Card (old) [deprecated]
// Componente para cards com efeito de flip
//
// Depreciado por ser um componente da v0 que provavelmente não vai ser utilizado.
//-------------------------------
.bs-flip-card {
  background: $c-gray-lighter;
  bottom: -40px;
  display: flex;
  flex-direction: column;
  height: 0;
  justify-content: flex-start;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 70px 20px 20px;
  position: absolute;
  text-align: left;
  visibility: hidden;
  width: 100%;
  will-change: height;

  @include media(">=tablet") {
    padding: 30px 20px 20px;
    transition: $ease-in-out;
  }

  @include media(">=desktop") {
    justify-content: center;
    padding: 50px 30px 30px;
  }

  @include media(">=wide") {
    padding: 0 30px 30px;
  }

  &__header {
    display: inline-block;
    font-size: rem(12);
    margin-bottom: 10px;
  }

  &__title {
    display: block;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("regular");
    margin-bottom: 15px;

    @include media(">=tablet") {
      display: none;
    }
  }

  &__description {
    font-size: rem(26);
    line-height: 28px;
    margin-bottom: 10px;

    @include media(">=tablet") {
      font-size: rem(18);
      line-height: 22px;
      margin-bottom: 10px;
    }
  }

  &__text {
    p {
      font-size: rem(14);
      line-height: 25px;
      margin-bottom: 15px;

      @include media("<tablet") {
        font-size: rem(16);
      }

      @include media(">=tablet") {
        line-height: 20px;
      }

      @include media(">=desktop") {
        line-height: 25px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    display: block;
    padding-top: 20px;
  }

  &--is-open {
    bottom: 0;
    cursor: default;
    height: 100%;
    opacity: 1;
    visibility: visible;
    will-change: height;
    z-index: 1;

    @include media("<tablet") {
      background: $c-gray-lighter;
      height: 100%;
      justify-content: flex-start;
      opacity: 1;
      top: 0;
      transition: $ease-in-out;
      width: 100%;
      will-change: height;
      z-index: 999;

      @include media(">=tablet") {
        padding-top: 60px;
      }
    }
  }

  &--is-removed {
    background: $c-gray-lighter;
    bottom: auto;
    height: 0;
    justify-content: flex-start;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: $ease-in-out;
    width: 100%;
    will-change: height;
    z-index: 999;

    @include media(">=tablet") {
      bottom: 0;
      padding-top: 60px;
      top: auto;
    }
  }

  &__close {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    margin: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;

    @include media(">=tablet") {
      margin: 6px;
      padding: 3px 4px;
      top: 30px;
    }

    @include media(">=desktop") {
      background: transparent;
      margin: 20px;
      top: 30px;
    }


    i {
      font-size: rem(24);
      transition: $ease-in-out;

      @include media(">=tablet") {
        font-size: rem(18);
      }
      @include media(">=desktop") {
        font-size: rem(24);
      }
    }

    .bs-icon {
      stroke: $c-black;
    }

    @include media(">=tablet") {
      top: 0;
    }

    &:hover {
      i {
        color: $c-secondary-light;
      }
    }
  }

  &--red {
    @extend .bs-flip-card;
    background-color: $c-primary-dark;
    color: $c-black;
    display: flex;
    justify-content: flex-start;
    padding: 60px 15px 20px;

    @include media(">=desktop") {
      padding-left: 20px;
      padding-right: 20px;
    }

    h5 {
      font-size: rem(20);
      font-weight: fw("regular");
      margin-bottom: 25px;
      min-height: 95px;

      @include media(">=wide") {
        font-size: rem(24);
      }
    }

    input[type="text"],
    input[type="email"],
    textarea,
    select {
      @include placeholder();
      border-bottom: 2px solid rgba($c-white, .8);
      color: $c-white;

      &:focus {
        border-color: rgba($c-white, 1);
      }
    }

    select {
      background-color: $c-primary-dark;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy40MSA3Ljg0TDEyIDEyLjQybDQuNTktNC41OEwxOCA5LjI1bC02IDYtNi02eiIvPjxwYXRoIGQ9Ik0wLS43NWgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==");
    }

    .bs-flip-card__button {

      > .bs-button {
        text-align: center;
        width: 100%;
      }

      @include media(">=tablet") {
        margin-top: auto;
        padding-top: 0;

        > .bs-button {
          width: 100%;
        }
      }

      @include media(">=wide") {
        > .bs-button {
          width: auto;
        }
      }
    }

    .bs-flip-card__close {
      i {
        color: $c-white;
        font-size: rem(20);
        font-weight: 600;
      }

      &:hover {
        i {
          opacity: .6;
        }
      }
    }

    .bs-flip-card__text {
      color: $c-white;
      display: block;
      margin: 0 auto;
      max-width: 350px;

      p {
        font-size: rem(16);
        font-weight: fw("regular");
        line-height: 1.5;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        @include media(">=wide") {
          font-size: rem(20);
        }
      }
    }

    .bs-flip-card__button {
      @extend .bs-flip-card__text;
      width: 100%;

      @include media(">=tablet") {
        margin-top: auto;
      }
    }
  }
}

.bs-is-mobile {
  .bs-flip-card--is-fixed {
    position: fixed !important;
    z-index: z("fixed");
  }
}
