

//
// Fonticons
//
// Attention: This file is automatically generated by Gulp
// in font-icons task. Never change this file directly, use
// the fonticons-template file in gulp's lib instead.
//
@font-face {
  font-family: "bs-fonticons";
  src: url('../icons/bs-fonticons.eot');
  src: url('../icons/bs-fonticons.eot?#iefix') format('eot'),
    url('../icons/bs-fonticons.woff2') format('woff2'),
    url('../icons/bs-fonticons.woff') format('woff'),
    url('../icons/bs-fonticons.ttf') format('truetype'),
    url('../icons/bs-fonticons.svg#bs-fonticons') format('svg');
  font-weight: normal;
  font-style: normal;
}



// Matches all classes prefixed by 'bs-icon--', so is not
// necessary to use an aditional 'bs-icon' class
[class^="bs-icon--"],
[class*=" bs-icon--"] {
  display: inline-flex;
  line-height: 1;
}

// Default icon style
[class^="bs-icon--"]::before,
[class*=" bs-icon--"]::before {
  display: inline-block;
  font-family: "bs-fonticons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

// SASS map of fonticons
$mapbs-icon: (
   "arrow-down": "\E001",
   "arrow-right": "\E002",
   "check": "\E003",
   "close": "\E004",
   "drag": "\E005",
   "loading-white": "\E006",
   "loading": "\E007",
   "menu": "\E008",
   "more": "\E009",
   "pdf": "\E00A",
   "plus": "\E00B",
   "search": "\E00C",
   "servicos": "\E00D",
   "test-1": "\E00E",
   "test-2": "\E00F",
   "test-3": "\E010",
   "test-4": "\E011",
  
) !default;

// Mixin of fonticons
@mixin bs-icon($icon) {
  content: map-get($mapbs-icon, $icon);
  font-family: "bs-fonticons";
}

// Classes

.bs-icon--arrow-down::before {
  content: "\E001";
}

.bs-icon--arrow-right::before {
  content: "\E002";
}

.bs-icon--check::before {
  content: "\E003";
}

.bs-icon--close::before {
  content: "\E004";
}

.bs-icon--drag::before {
  content: "\E005";
}

.bs-icon--loading-white::before {
  content: "\E006";
}

.bs-icon--loading::before {
  content: "\E007";
}

.bs-icon--menu::before {
  content: "\E008";
}

.bs-icon--more::before {
  content: "\E009";
}

.bs-icon--pdf::before {
  content: "\E00A";
}

.bs-icon--plus::before {
  content: "\E00B";
}

.bs-icon--search::before {
  content: "\E00C";
}

.bs-icon--servicos::before {
  content: "\E00D";
}

.bs-icon--test-1::before {
  content: "\E00E";
}

.bs-icon--test-2::before {
  content: "\E00F";
}

.bs-icon--test-3::before {
  content: "\E010";
}

.bs-icon--test-4::before {
  content: "\E011";
}
