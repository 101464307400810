.bs-audio {
  
  &__player {
    align-items: center;
    display: flex;
    position: relative;
  }

  &__content {
    flex: 1;
  }
}

.bs-audio-controls {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @include media(">=tablet") {
    flex-wrap: nowrap;
    justify-content: inherit;
  }

  &--off {
    display: none;
  }
  
  &__progress-bar-fake {
    align-items: center;
    display: flex;
    height: 20px;
    position: relative;
    width: 100%;

    input[type=range] {
      background: transparent;
      cursor: pointer;
      left: 0;
      position: absolute;
      top: 3px;
      height: 15px;
    }

    &:hover input[type=range]::-webkit-slider-thumb {
      height: 12px;
      margin-top: -5px;
      width: 12px;
    }

    progress {
      background: $c-gray;
      border-radius: 30px;
      height: 3px;
      transition: 0.2s;
      width: 100%;

      &::-webkit-progress-bar {
        background: $c-gray;
        border-radius: 30px;
      }

      &::-moz-progress-bar {
        background: $c-red;
        border-radius: 30px;
      }

      &::-webkit-progress-value{  
        background: $c-red;
        border-radius: 30px; 
      }

      &::-moz-range-progress {  
        background: $c-red;
        border-radius: 30px; 
      }

      &::-ms-fill {
        background: $c-red;
      }
    }
  }

  &__progress {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    
    @include media(">=tablet") {
      flex: 1;
      flex-wrap: nowrap;
      margin-left: 20px;
      margin-top: 0;
      width: inherit;
    }

    &--current,
    &--total {
      margin: 0 10px;
    }

    &--current {
      order: 2;

      @include media(">=tablet") {
        order: initial;
      }
    }

    &--total {
      order: 3;

      @include media(">=tablet") {
        order: initial;
      }
    }

    input[type=range] {
      -webkit-appearance: none;
      width: 100%;
    
      &:focus {
        outline: none;
      }
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-radius: 1.3px;
      }
      &::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-radius: 1.3px;
      }
      &::-webkit-slider-thumb {
        height: 8px;
        width: 8px;
        border-radius: 30px;
        background: $c-red;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        z-index: 9;
      }
      &::-moz-range-thumb {
        height: 8px;
        width: 8px;
        border-radius: 30px;
        background: $c-red;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        z-index: 9;
      }
      &::-ms-thumb {
        height: 8px;
        width: 8px;
        border-radius: 30px;
        background: $c-red;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        z-index: 9;
      }
      &::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      &::-ms-fill-lower {
        background: transparent;
        border: 0;
      }
      &::-ms-fill-upper {
        background: transparent;
        border: 0;
      }
    }
  }

  &__play-pause,
  &__backward,
  &__forward {
    font-size: 0;
    margin: 0 9px;
    transition: 0.3s;

    button:focus {
      outline-offset: 5px;
    }

    .icon {
      font-size: 34px;
    }

    &:hover .icon {
      color: $c-red;
    }
  }
  
  &__play-pause {

    .icon {
      color: $c-red;
      font-size: 45px;
    }
  }
  
  &__volume {
    position: relative;
    width: 110px;
    display: none;
    align-items: center;

    @include media(">=tablet") {
      display: flex;
    }

    input[type=range] {
      background: $c-gray-light;
      cursor: pointer;
      height: 3px;
      -webkit-appearance: none;
      width: 100%;
    
      &:focus {
        outline: none;
      }
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-radius: 1.3px;
      }
      &::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-radius: 1.3px;
      }
      &::-webkit-slider-thumb {
        height: 8px;
        width: 8px;
        border-radius: 30px;
        background: $c-gray-dark;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        position: relative;
        z-index: 9;
      }
      &::-moz-range-thumb {
        height: 8px;
        width: 8px;
        border-radius: 30px;
        background: $c-gray-dark;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        position: relative;
        z-index: 9;
      }
      &::-ms-thumb {
        height: 8px;
        width: 8px;
        border-radius: 30px;
        background: $c-gray-dark;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        position: relative;
        z-index: 9;
      }
      &::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      &::-ms-fill-lower {
        background: transparent;
        border: 0;
      }
      &::-ms-fill-upper {
        background: transparent;
        border: 0;
      }
    }

    button {
      align-items: center;
      display: flex;
      font-size: 26px;
      padding-right: 10px;
    }

    output {
      background: $c-gray-dark;
      font-size: 0;
      height: 3px;
      left: 0;
      pointer-events: none;
      position: absolute;
      z-index: 0;
    }
  }

  &__volume-range {
    display: flex;
    position: relative;
    width: 100%;

    &:hover input[type=range]::-webkit-slider-thumb,
    &:hover input[type=range]::-moz-range-thumb,
    &:hover input[type=range]::-ms-thumb {
      height: 12px;
      margin-top: -5px;
      width: 12px;
    }
  }
}