.bs-dropdown-notification {
  .bs-dropdown-menu__trigger {
    width: 60px;

    @include media(">=tablet") {
      width: 80px;
    }
  }

  &__count {
    background-color: $c-primary-light;
    border-radius: 50%;
    display: block;
    height: 20px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 10px;
    top: 19px;
    visibility: hidden;
    width: 20px;

    @include media(">=tablet") {
      right: 17px;
      top: 25px;
    }
  }

  &__number {
    color: $c-white;
    font-size: 14px;
    font-weight: fw("bold");
    line-height: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;

    &--in-down-count {
      animation: in-down-count .3s forwards;
    }
    &--in-up-count {
      animation: in-up-count .3s forwards;
    }

    &--out-down-count {
      animation: out-down-count .3s forwards;
    }
    &--out-up-count {
      animation: out-up-count .3s forwards;
    }
  }

  &__item {
    height: 112px;

    > a {
      padding-right: (5 * $grid-gutter);
    }

    &--has-notification {
      background-color: $c-gray-lightest;

      &::after {
        background-color: $c-primary;
        border-radius: 50%;
        content: "";
        display: block;
        height: 11px;
        position: absolute;
        right: 18px;
        top: 20px;
        width: 11px;
      }
    }

    &--removing {
      opacity: 0;
      height: 0;
    }
  }

  &--has-notification {
    .bs-dropdown-notification__count {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes in-down-count {
    from {
      opacity: 0;
      transform: translateY(15px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes out-down-count {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-15px);
    }
  }

  @keyframes in-up-count {
    from {
      opacity: 0;
      transform: translateY(-15px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes out-up-count {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(15px);
    }
  }
}