//-------------------------------
// Tooltip
// Moldura flutuante que contém uma explicação adicional sobre algum elemento.
//-------------------------------
.bs-tooltip {
  background: rgba($c-white, 1);
  border-radius: 3px;
  box-shadow: b-shadow("2");
  display: none;
  font-size: 14px;
  height: auto;
  margin-bottom: $grid-gutter;
  padding: $grid-gutter;
  position: absolute;
  z-index: z("absolute");

  &__base {
    position: relative;

    &[data-tooltip-on="hover"] {
      input:hover ~ .bs-tooltip {
        display: block;
      }
    }

    &[data-tooltip-on="focus"] {
      input:focus ~ .bs-tooltip {
        display: block;
      }
    }
  }

  &::before {
    border-color: transparent;
    border-style: solid;
    border-width: 10px;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    z-index: z("absolute");
  }

  &--bottom {
    left: 0;
    top: calc(100% + 15px);

    &::before {
      border-bottom-color: $c-white;
      bottom: calc(100% + 20px);
    }
  }

  &--left {
    right: calc(100% + 15px);
    top: 0;

    &::before {
      border-left-color: $c-white;
      left: calc(100% + 20px);
    }
  }

  &--right {
    left: calc(100% + 15px);
    top: 0;

    &::before {
      border-right-color: $c-white;
      right: calc(100% + 20px);
    }
  }

  &--top {
    bottom: calc(100% + 15px);
    left: 0;

    &::before {
      border-top-color: $c-white;
      top: calc(100% + 20px);
    }
  }

  p {
    margin-bottom: $grid-gutter;
  }

  li {
    list-style: inside disc;
  }

  ol li {
    list-style-type: decimal;
  }
}

.bs-tooltip-new {

  &__item {
    display: inline-block;
    margin: 0 10px;
    width: 22px;
    height: 22px;

    .icon {
      font-size: 22px;
    }
  }
}

.bs-tooltip-new__box {
  position: absolute;
  background: $c-gray-lighter;
  border-radius: 2px;
  border: 1px solid $c-white;
  padding: 15px;
  font-size: 14px;
  margin-right: 15px;
  margin-left: -5px;
  // height: 110px;
  z-index: 9;

  &::before {
    content : "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $c-gray-lighter;
    position:absolute;
    bottom:-8px;
    left:5px;
  }
}

// A tippy.js theme
.tippy-tooltip.bs-theme {
  background-color: $c-gray-lighter;
  color: $c-gray-darker;

  .tippy-backdrop {
    // background-color: red;
  }

  .tippy-arrow {
    border-top-color: $c-gray-lighter;
  }

  .tippy-content {
    // background-color: blue;
  }
}
