.bs-information {

  .bs-time-line__select .flickity-enabled.is-draggable .flickity-viewport {
    height: 75px !important;
  }

  .flickity-page-dots,
  .flickity-button {
    display: none;
  }

}
.bs-js-lightpick {
  font-size: 1rem !important;
}
