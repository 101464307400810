@include media(">=desktop") {
  .bs-news__meta {
    height: 70px;
    position: relative;

    .bs-social__list {
      position: relative;
      right: 0;
      top: 0;
      transition: position 0.3s, right 0.3s;

      &.bs-is-fixed,
      &.bs-is-floating {
        background-color: $c-white;
        border-radius: 3px;
        box-shadow: 0 0 5px 0 rgba($c-black, .3);
        padding: 0;
        right: $grid-gutter;
        transition: top 0.3s;
        z-index: z("absolute");
      }

      &.bs-is-floating {
        position: fixed;
      }
    }
  }
}
