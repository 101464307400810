.bs-compare {

  &__result {
    display: none;
    margin-top: 2 * $grid-gutter;

    .bs-media__item {
      display: none;
      opacity: 0;
      transition: 0.4s;

      &.bs-compare__active {
        display: flex;
        opacity: 1;
      }
    }
  }

  &__select-cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__select-card {
    padding: 15px;
    position: relative;
    width: 25%;

    img {
      @include object-fit(contain, top);
      display: block;
      // height: 100%;
      width: 100%;
    }

    .bs-form__label {
      font-size: 0;
    }

    .bs-form__field {
      left: -10px;
      position: absolute;
      top: -10px;
    }
  }

  &__link {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;

    &::before,
    &::after  {
      border-top: 1px solid $c-gray-dark;
      content: "";
      display: block;
      position: absolute;
      top: 18px;
      width: 1000px;
    }

    &::before {
      right: 60%;
    }

    &::after {
      left: 60%;
    }
  }

}
