//-------------------------------
// HR - Horizontal rule
//-------------------------------
.bs-hr {
  // Espaçamento vertical médio
  margin: ($grid-gutter * 2) 0;

  // Espaçamento vertical pequeno
  &--small {
    margin: $grid-gutter 0;
  }

  // Espaçamento vertical grande
  &--large {
    margin: ($grid-gutter * 2) 0 ($grid-gutter * 3);
  }
}
