.bs-browser-update {
  background: $c-yellow;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  text-align: center;
  top: 0;

  &__content {
    padding: 15px;
    width: 400px;
  }

  &__text {
    color: $c-black;
    font-size: 28px;
    padding-bottom: 20px;
  }

  &__text-description {
    color: $c-black;
    font-size: 14px;
    padding-bottom: 20px;
  }
}
