.bs-comments {
  &__list {
    border: 1px solid $c-gray-light;
    border-radius: 5px;
  }

  &__item {
    border-bottom: 1px solid $c-gray-light;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.bs-comment {
  padding: ($grid-gutter * 2) $grid-gutter;

  @include media(">=tablet") {
    display: flex;
  }

  &__image {
    flex: 1 0 auto;
    height: 60px;
    position: absolute;
    width: 60px;

    @include media(">=tablet") {
      position: static;
    }

    img {
      border-radius: 100%;
      height: 100%;
      width: 100%;
    }
  }

  &__text {
    flex: 1 1 auto;
    width: 100%;

    @include media(">=tablet") {
      padding: 0 $grid-gutter;
    }
  }

  &__header {
    margin-bottom: 10px;
    min-height: 60px;
    padding-left: 70px;
    padding-top: 10px;

    @include media(">=tablet") {
      min-height: 0;
      padding: 0;
    }
  }

  &__title {
    font-family: $font-title;
    font-size: 16px;
    font-weight: fw("bold");
    text-transform: uppercase;
  }

  &__label {
    font-size: 14px;
    font-style: italic;
  }

  &__body {
    p {
      margin-bottom: $grid-gutter;
    }
  }
}
