//-------------------------------
// Flexgrid
//-------------------------------

// Number of grid columns
$grid-columns: 12 !default;

// Gutter between columns
$gutter-width: 2 * $grid-gutter !default;

// Margin for the container-fluid sides
$outer-margin: 2 * $grid-gutter !default;

// Breakpoints
// Syntax:
// name viewport container-width,
$grid-breakpoints:
  xs 0px 100%,
  ph 576px 540px,
  sm 768px 750px,
  md 992px 970px,
  lg 1200px 1170px !default;

$grid-max-width: 1200px !default;
$gutter-compensation: $gutter-width * .5 * -1;
$half-gutter-width: $gutter-width * .5;
$name: xs;

.bs-container,
.container {
  display: block;
  margin: 0 auto;
  padding: 0 $grid-gutter;
  width: 100%;

  // Não deve existir um container dentro de outro,
  // mas como a maioria dos componentes já vem com seu
  // próprio container, a regra baixo reseta a largura dele
  // nos casos em que são usados templates com sidebar
  .bs-container,
  .container {
    width: 100%;
    padding: 0;
  }
}

.bs-container-fluid {
  margin-right: auto;
  margin-left: auto;
  // padding-right: $outer-margin;
  // padding-left: $outer-margin;
}

.bs-row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin-left: $gutter-compensation;
  margin-right: $gutter-compensation;

  &--thin {
    margin-left: $gutter-compensation / 2;
    margin-right: $gutter-compensation / 2;

    // @for $i from 1 through $grid-columns {
    //   .bs-col-#{$name}-#{$i} {
    //     padding-left: $half-gutter-width / 2;
    //     padding-right: $half-gutter-width / 2;
    //   }
    // }s
  }
}

.bs-row.bs-reverse {
  flex-direction: row-reverse;
}

.bs-col.bs-reverse {
  flex-direction: column-reverse;
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: $half-gutter-width;
  padding-right: $half-gutter-width;
}

.bs-col-#{$name} {
  @include flexboxgrid-sass-col-common;
  flex-basis: auto;
}

@for $i from 1 through $grid-columns {
  .bs-col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    flex-basis:(100% / $grid-columns * $i);
    max-width: 100% / $grid-columns * $i;
  }

  @at-root {
    .bs-row--thin {
      .bs-col-#{$name}-#{$i} {
        padding-left: $half-gutter-width / 2;
        padding-right: $half-gutter-width / 2;
      }
    }
  }
}

@for $i from 0 through $grid-columns {
  .bs-col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $grid-columns * $i;
    }
  }
}

.bs-col-#{$name} {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.bs-start-#{$name} {
  justify-content: flex-start;
  text-align: left;
}

.bs-center-#{$name} {
  justify-content: center;
  text-align: center;
}

.bs-end-#{$name} {
  justify-content: flex-end;
  text-align: right;
}

.bs-top-#{$name} {
  align-items: flex-start;
}

.bs-middle-#{$name} {
  align-items: center;
}

.bs-bottom-#{$name} {
  align-items: flex-end;
}

.bs-around-#{$name} {
  justify-content: space-around;
}

.bs-between-#{$name} {
  justify-content: space-between;
}

.bs-first-#{$name} {
  order: -1;
}

.bs-last-#{$name} {
  order: 1;
}

@each $breakpoint in $grid-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);
  @media only screen and (min-width: $size) {
    .bs-container,
    .container {
      max-width: $container;
    }

    .bs-col-#{$name} {
      @include flexboxgrid-sass-col-common;
      flex-basis: auto;
    }

    @for $i from 1 through $grid-columns {
      .bs-col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        flex-basis: (100% / $grid-columns * $i);
        max-width: 100% / $grid-columns * $i;
      }

      .bs-row--thin {
        .bs-col-#{$name}-#{$i} {
          padding-left: $half-gutter-width / 2;
          padding-right: $half-gutter-width / 2;
        }
      }
    }

    @for $i from 0 through $grid-columns {
      .bs-col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $grid-columns * $i;
        }
      }
    }

    .bs-col-#{$name} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
    .bs-start-#{$name} {
      justify-content: flex-start;
      text-align: left;
    }

    .bs-center-#{$name} {
      justify-content: center;
      text-align: center;
    }

    .bs-end-#{$name} {
      justify-content: flex-end;
      text-align: right;
    }

    .bs-top-#{$name} {
      align-items: flex-start;
    }

    .bs-middle-#{$name} {
      align-items: center;
    }

    .bs-bottom-#{$name} {
      align-items: flex-end;
    }

    .bs-around-#{$name} {
      justify-content: space-around;
    }

    .bs-between-#{$name} {
      justify-content: space-between;
    }

    .bs-first-#{$name} {
      order: -1;
    }

    .bs-last-#{$name} {
      order: 1;
    }
  }
}
