//-------------------------------
// Paginação
//-------------------------------
.bs-pagination {
  display: flex;
  justify-content: center;
  margin: (2 * $grid-gutter) 0;
  width: 100%;

  &__item {
    font-family: $font-title;
    font-size: rem(16);
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    margin: 0 2px;
    min-width: 30px;
    padding: 0 10px;
    text-align: center;

    @include media(">=desktop") {
      font-size: rem(18);
    }

    a,
    button {
      align-items: center;
      border: 1px solid transparent;
      border-radius: 5px;
      color: $c-primary;
      // display: block;
      display: flex;
      font-family: inherit;
      font-size: inherit;
      height: 30px;
      margin: 0 -10px;
      padding: 0 10px;
      transition: 0.3s;

      &:hover {
        border-color: $c-primary;
      }
    }

    .icon {
      font-size: rem(12);

      @include media(">=desktop") {
        font-size: rem(14);
      }
    }

    // WCM não permite adicionar classe no item ativo
    &--current {
      a {
        color: $c-text;
      }
    }

    &--disabled {
      a {
        border: 0;
        color: $c-gray;
        cursor: default;
        pointer-events: none;

        &:focus {
          // outline-width: 0;
        }

        &:hover {
          background-color: transparent;
          border: 0;
          color: inherit;
        }
      }

      &:hover {
        a {
          color: $c-gray;
        }
      }
    }
  }

  &__label {
    font-size: rem(14);
    margin: 0 5px;

    @include media(">=desktop") {
      font-size: rem(16);
    }
  }
}
