//-------------------------------
// Listas
// Componente para listas ordenadas e não-ordenadas
//-------------------------------
.bs-list {
  margin-bottom: $grid-gutter;

  li {
    list-style-position: inside;
    margin-bottom: 10px;

    ul {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    li {
      margin-left: 25px;
    }
  }

  @at-root {
    ul#{&} li {
      list-style-type: disc;
    }

    ol#{&} li {
      list-style-type: decimal;
    }
  }

  &--icons {

    li {
      align-items: flex-start;
      display: flex;
      list-style: none;
      margin-bottom: $grid-gutter;
    }

    i,
    .icon {
      color: $c-primary;
      font-size: 25px;
      margin-right: 10px;
    }

    img.icon {
      height: 20px;
      margin-right: $grid-gutter;
      width: 20px;
    }
  }
}
