//-------------------------------
// Conteúdo dinâmico do portal WCM
// Sobrescreve todas as tags de conteúdo de texto rico do portal
// WCM para padronizar o visual e evitar que esse visual possa
// ser alterado.
//
// sass-lint:disable no-important
//-------------------------------
.bs-portal-content {
  color: $c-text !important;
  font-family: $font-text !important;

  p {
    line-height: 1.5;
    margin-bottom: 2 * $grid-gutter;

    a:not(.bs-button) {
      color: $c-primary !important;
      font-weight: fw("bold");
    }
  }

  ul,
  ol {
    margin-bottom: $grid-gutter;
    margin-left: (2 * $grid-gutter);

    li {
      margin-bottom: 10px;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-title !important;
    font-weight: fw("bold");
    margin-bottom: 2 * $grid-gutter;
  }

  table {
    border-color: $c-gray-light !important;
    table-layout: auto !important;
    width: 100% !important;

    td,
    th {
      border-color: $c-gray-light !important;
      width: auto !important;
    }
  }

  &.bs-is-collapsed {
    overflow: hidden;
    position: relative;
    transition: height .3s;
  }

  &__expand-button {
    background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 50%,rgba(255,255,255,0) 100%);
    color: $c-primary;
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    font-weight: fw('bold');
    width: 100%;
    line-height: 20px;
    height: 80px;
    text-align: center;
    padding: 40px 0 20px;
    transition: padding .3s;

    &:hover {
      padding-top: 50px;
      padding-bottom: 10px;
      transition: padding .3s;
    }

    @at-root {
      .bs-section & {
        background: linear-gradient(to top, rgba($c-gray-lighter, 1) 0%,rgba($c-gray-lighter, 0.7) 50%,rgba($c-gray-lighter, 0) 100%);
      }
    }
  }
}
