.bs-telecordion {

  &__list {
    margin: 0 (-$grid-gutter);
  }

  &__item {
    border-bottom: 1px solid $c-gray;
    padding: ($grid-gutter * 3) 0;

    &.is-expanded,
    &--expanded {

      .bs-telecordion__box {
        transition: height .3s;
      }

      .bs-telecordion__body,
      .bs-telecordion__contact-list {
        opacity: 1;
        transition: opacity .3s;
        display: block;
      }

      .bs-telecordion__icon::before {
        transform: rotate(-180deg);
        transition: transform .3s;
      }
    }
  }

  &__box {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 60px;
    overflow: hidden;
    position: relative;
    transition: height .3s;

    @include media(">=tablet") {
      flex-wrap: nowrap;
      position: static;
    }
  }

  &__icon {
    flex: 1 0 auto;
    left: 0;
    padding: 0 $grid-gutter;
    padding-left: ($grid-gutter * 2);
    position: absolute;
    top: 0;

    @include media(">=tablet") {
      left: auto;
      position: relative;
      top: auto;
      flex: initial;
    }

    &::before {
      content: "\e91c";
      display: block;
      font-family: "bradesco_v2";
      left: 15px;
      position: absolute;
      top: 20px;
      transform: rotate(0);
      transition: transform .3s;
    }

    .icon {
      color: $c-primary;
      display: block;
      font-size: 50px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      width: 60px;
    }
  }

  &__text {
    flex: 1 1 auto;
    padding: 0 $grid-gutter;

    @include media(">=tablet") {
      padding: 0;
    }
  }

  &__header {
    margin-bottom: ($grid-gutter * 2);
    min-height: 60px;
    padding-left: 85px;

    @include media(">=tablet") {
      padding-left: 0;
    }
  }

  &__title {
    display: block;
    font-size: 22px;
    margin-bottom: 5px;
  }

  &__subtitle {
    font-size: 16px;
  }

  &__body {
    opacity: 0;
    transition: opacity .3s;
  }

  &__contact {
    flex: 1 0 300px;
    padding: 0 $grid-gutter;

    @include media(">=tablet") {
      padding-left: 60px;
    }

    &-main {}

    &-list {
      display: none;
      opacity: 0;
      transition: opacity .3s;
    }

    &-label {
      display: block;
      margin-bottom: $grid-gutter;
    }
  }

  &__phone {
    margin-bottom: $grid-gutter;

    &-list {}

    &-number {
      display: block;
      font-size: 22px;
      font-weight: fw("medium");
    }

    &-description {
      display: block;
    }
  }
}
