.bs-time-line {
  // width: 870px;
  // margin: 50px auto;
  overflow: hidden;
  // position: relative;
  // use to inside child $i get [bs-time-line origin]
   $i : &;
  // #[$i]__

// use component item
  &__select {
    position: relative;
    margin-bottom: $grid-gutter;
  }

  &__buttons {
    width: 100%;

    &::before {
      background-color: $c-gray;
      content: '';
      height: 1px;
      position: absolute;
      margin: 0 7%;
      top: 32px;
      width: 100%;

      @include media(">=phone") {
        margin: 0px 7%;
        width: 86%;
     }
    }

    #{$i}--prev,
    #{$i}--next {
      display: none;
      top: 14%;
      position: absolute;

      @include media(">=phone") {
         display: block;
      }

      &[disabled='disabled'] {
        opacity: 0.3;
      }

      i {
        color: $c-gray;
        font-size: 40px;
      }
    }

  }

  &__nav {
    display: flex;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;

    &.flickity-enabled {
      display: block;
    }

    @include media(">=phone") {
      width: 80%;
   }
  }

  &__nav-item {
    background-color: $c-white;
    border: {
      color: $c-gray;
      style: solid;
      radius: 4px;
      width: 1px;
    }
    color: $c-gray;
    line-height: 1.2;
    text-align: center;
    padding: 5px 12px;
    transition: 0.4s;
    margin: 11px 0;
    margin-left: 32px;
    cursor: pointer;
    width: 110px;

    #{$i}--month,
    #{$i}--year {
      display: block;
      width: 100%;
      transition: 0.3s font-size;
    }

    &:first-child {
      left: 0.1% !important;
    } 

    &.is-nav-selected {
      background-color: $c-primary;
      border-color: $c-primary;
      color: $c-white;
      line-height: 1.1;
      padding: 9px 12px;
      margin-bottom: 0;
      margin-top: 0;

      #{$i}--month {
        font-size: 1rem;
      }

      #{$i}--year {
        font-size: 1.75rem;
      }
    }
  }

  &__content {
    position: relative;

    &:not(.flickity-enabled) {
      display: none;
    }
  }

  &__content-item {
    margin: 0 5%;
    width: 100%;
  }

  &__figure {
    margin-bottom: $grid-gutter;
  }

  &__info {

  }

  //Allow bullets inside content for listing
  .bs-content__text-allow-bullets ul {
    padding-left: 32px;
  }
  .bs-content__text-allow-bullets ul,
  .bs-content__text-allow-bullets li {
    display: list-item;
    list-style: initial
  }
  .bs-content__text-allow-bullets ul ul li {
    display: list-item;
    list-style: circle
  }
  .bs-content__text-allow-bullets ol {
    display: list-item;
    list-style: decimal
  }

// use variations type

  &--horizontal {
    // unused
  }

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }

  &--month {
    font-size: 12px;
  }

  &--year {
    font-weight: 900;
    font-size: 18px;
  }

  &--title {
    // real use
  }

  &--description {
    // real use
  }
}
