//-------------------------------
// Loading Box [deprecated]
// Box de container para um componente de loading
//
// Depreciado porque não está sendo usado atualmente e não há previsão de uso
//-------------------------------
.bs-loading-box {
  margin: 0 auto;
  text-align: center;
}
