//-------------------------------
// Classes helpers
//-------------------------------
.bs-overflow {
  &--hidden {
    overflow: hidden;
  }
}

.bs-is-visible {
  opacity: 1;
  visibility: visible;
}

.bs-is-invisible {
  opacity: 0;
  visibility: hidden;
}

.bs-is-hidden {
  display: none;
}

.bs-nowrap {
  white-space: nowrap;
}

.bs-pb-0 {
  padding-bottom: 0 !important;
}

.bs-pt-0 {
  padding-top: 0 !important;
}

.bs-mb-0 {
  margin-bottom: 0 !important;
}

.bs-mt-0 {
  margin-top: 0 !important;
}

.bs-mb-15 {
  margin-bottom: $grid-gutter !important;;
}

.bs-mt-15 {
  margin-bottom: $grid-gutter !important;;
}
