//-------------------------------
// Logotipo Bradesco Seguros
// Estiliza as cores do logo SVG do Bradesco Seguros
//-------------------------------
.bs-logo {

  &__icon {
    fill: $c-white;
  }

  &__icon-bg {
    fill: $c-red;
  }

  &__icon-border {
    fill: $c-white;
  }

  &__text {
    fill: $c-gray-darker;
  }

  &--vertical {
    padding-bottom: $grid-gutter;
    padding-top: $grid-gutter;
  }
}
