//-------------------------------
// Menu de Navegação
//-------------------------------
.bs-navigation, // deprecated: usar a classe de baixo
.bs-nav-filter {
  margin-bottom: 30px;

  &__wrapper {
    align-items: center;
    display: flex;
    // min-height: 80px;
    position: relative;
  }

  &__toggle {
    align-items: center;
    background-color: $c-white;
    border: {
      bottom: 1px solid $c-gray-light;
      left: 1px solid $c-gray-light;
      right: 1px solid $c-gray-light;
      top: 5px solid $c-primary;
    };
    color: $c-gray-darker;
    display: flex;
    font-family: $font-title;
    font-size: rem(16);
    font-weight: fw("medium");
    justify-content: space-between;
    line-height: 20px;
    padding: ($grid-gutter * 2);
    text-align: left;
    width: 100%;

    @include media(">=tablet") {
      display: none;
    }

    &-label {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    &::after {
      // icon-seta-baixo-b
      content: "\e91b";
      display: inline-block;
      font-family: "Bradesco_v2";
      font-size: rem(15);
      margin-left: 5px;
      transform: rotate(0deg);
      // transition: transform $transition-time;
    }

    .icon {
      color: $c-primary;
      display: block;
      font-size: 34px;
      margin-right: $grid-gutter;

      @include media(">=tablet") {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    &.bs-is-active,
    &--active {

      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__list {
    background-color: $c-white;
    box-shadow: b-shadow("2");
    display: none;
    flex-flow: column wrap;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: z("absolute");

    @include media(">=tablet") {
      border: 1px solid $c-gray;
      box-shadow: none;
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      position: static;
    }

    &.bs-is-open,
    &--open {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    border-right: 1px solid $c-gray-light;
    height: auto;

    &:last-child {
      border-right: 0;
    }

    @include media(">=tablet") {
      flex: 1 0 0;
    }
  }

  &__link {
    align-items: center;
    background-color: $c-white;
    border-top: 5px solid transparent;
    display: flex;
    flex-flow: row wrap;
    font-family: $font-title;
    font-size: rem(14);
    font-weight: fw("medium");
    line-height: 20px;
    padding: $grid-gutter ($grid-gutter * 2);
    text-align: left;
    height: 100%;
    transition: $transition-time;
    width: 100%;

    &:focus {
      outline-offset: -5px;
      outline-style: dotted;
      outline-width: 1px;
    }

    @include media(">=tablet") {
      align-items: center;
      flex-flow: column nowrap;
      justify-content: flex-start;
      padding: $grid-gutter ($grid-gutter / 2);
      text-align: center;
    }

    &:hover {
      background-color: $c-gray-lighter;
    }

    &.bs-filter-link--active {
      background-color: $c-gray-lighter;
      position: relative;

      @include media(">=tablet") {
        border-top-color: $c-primary;
      }

      &::before,
      &::after {
        border: 12px solid transparent;
        border-top-color: $c-gray-lighter;
        content: "";
        left: 50%;
        margin-left: -12px;
        position: absolute;
        top: 100%;
        z-index: 2;
      }

      &::after {
        border-top-color: $c-gray-dark;
        border-width: 13px;
        margin-left: -13px;
        z-index: 1;
      }
    }

    .icon {
      color: $c-primary;
      display: block;
      font-size: 34px;
      margin-right: $grid-gutter;

      @include media(">=tablet") {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    span {
      @include media(">=tablet") {
        display: block;
        width: 100%;
      }
    }

    &--icon {
      justify-content: center;
    }
  }
}
