.bs-aba-nav {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  position: relative;
  transition: .3s;

  // Content navbar ----
  &__navigation {
    background-color: $c-gray-lighter;
    position: relative;
    // width: 75px;
    width: 100%;
    z-index: 1;

    @include media(">=phone") {
      width: 200px;
    }

    @include media(">=desktop") {
      width: 275px;
    }

    li {
      border-bottom: 1px solid $c-gray-light;
    }

    &--icon {
      margin-right: 10px;

      .icon {
        color: $c-red;
        font-size: 30px;
      }
    }

    &--product {
      color: $c-red;
      font-size: 16px;
      font-weight: bold;
      // display: none;

      @include media(">=tablet") {
        display: block;
      }
    }

    &--active {
      display: none;
    }
  }

  &__navigation-item {
    align-items: center;
    background-color: $c-gray-lighter;
    cursor: pointer;
    display: flex;
    height: 80px;
    // justify-content: center;
    padding: 0 20px;
    transition: 0.6s;
    position: relative;
    outline-color: $c-gray-lighter;

    width: 100%;

    @include media(">=tablet") {
      // justify-content: inherit;
    }

    @include media(">=desktop") {
      border-left: 5px solid $c-gray-lighter;

      &:hover {
        background-color: $c-white;
        border-left: 5px solid $c-gray;
      }

      &:focus {
        outline-offset: -8px;
        outline-color: $c-gray-dark;
      }
    }


    &:last-child {
      border-bottom: 0;
    }

  }

  &__navigation-item--active {
    position: relative;
    overflow: hidden;

    @include media(">=phone") {
      background-color: $c-white;
    }

    &:focus {
      // outline: 0;
    }

  }

  &__fake-selection {
    background-color: $c-red;
    content: '';
    height: 81px;
    left: 0;
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.8, 0.1, 0, 1);
    top: 0;
    position: absolute;
    pointer-events: none;
    width: 0px;
    z-index: 1;

    @include media(">=phone") {
      width: 5px;
    }
  }

  // Content reference ----
  &__content {
    overflow: hidden;
    flex: 1;

    @include media(">=phone") {
      margin: 0 30px;
    }
  }

  &__content-item {
    display: none;

    &--active {
      display: block;


      background-color: $c-white;
      height: 100%;
      left: 0;
      // position: absolute;
      z-index: 9;

    }
  }

  &__content-close {
    font-size: 30px;
    text-align: right;
    padding: 15px 15px 0;

    @include media(">=phone") {
      display: none;
    }

    .icon {
      color: $c-gray;
    }
  }

  &__content-title {
    border-bottom: 1px solid $c-gray-light;
    display: flex;
    // margin-bottom: 32px;
    padding-bottom: 15px;

    width: 100%;

    @include media(">=phone") {
      align-items: center;
      display: flex;
      // height: 80px;
      padding: 18px 0;
    }

    .icon {
      color: $c-red;
      font-size: 60px;
      padding: 0 15px;

      @include media(">=phone") {
        display: none;
      }
    }
  }

  &__content-title-top {
    color: $c-red;
    font-size: 20px;
    font-weight: bold;
  }

  &__content-title-bot {
    font-size: 14px;
  }

  &__content-res {

    .bs-media__item {
      flex-basis: 100%;
      max-width: 100%;

      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }

      @include media(">=desktop") {
        flex-basis: 50%;
        max-width: 50%;

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          display: flex;
        }
      }

      .bs-media__figure {
        // display: none;
        display: block;

        @include media(">=phone") {
          display: none;
        }

        @include media(">=desktop") {
          display: block;
        }
      }

      .bs-media__text {
        padding-left: 15px;

        @include media(">=tablet") {
          padding-left: 0;
        }
      }
    }
  }

  &__content-list {
    background-color: $c-gray-lighter;
    overflow: auto;
    height: 100%;
    padding: 30px 15px;

    @include media(">=phone") {
      background-color: $c-white;
    }
  }

  &__content-button {
    display: none;
    margin: 30px 0;
    justify-content: flex-end;
    width: 100%;

    @include media(">=phone") {
      display: flex;
    }
  }

  &__content-title-list {
    padding-left: 15px;
    padding-right: 15px;
  }
}
