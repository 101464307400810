//-------------------------------
// Datepicker
// Componente de calendário baseado no plugin pikaday
// @link: https://github.com/dbushell/Pikaday
//-------------------------------
.pika {

  // Container
  &-single {
    color: $c-gray-darker;

    // for testing
    // position: absolute !important;
    // left: 390px !important;
    // top: 480px !important;
    // display: block !important;
  }

  // Tabela do calendário
  &-table {
    th {
      color: $c-gray-dark;
    }
  }

  // Dias "ativos" no calendário
  &-button {
    background-color: $c-gray-lighter;
    color: $c-gray-darker;
    text-align: center;

    // Data atual
    .is-today & {
      color: $c-secondary-light;
    }

    &:hover {
      background-color: $c-primary-light;
      color: $c-white;
    }
  }
}
