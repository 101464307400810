@mixin loader () {
  &::before,
  &::after {
    animation: bs-loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: 2px solid $c-primary;
    border-radius: 50%;
    content: "";
    display: block;
    opacity: 1;
    position: absolute;
  }

  &::after {
    animation-delay: -0.5s;
  }

  @keyframes bs-loading {
    0%,
    100% {
      bottom: 0;
      height: 1px;
      left: 0;
      margin: auto;
      opacity: 1;
      right: 0;
      top: 0;
      width: 1px;
    }

    100% {
      height: 100%;
      opacity: 0;
      width: 100%;
    }
  }
}
