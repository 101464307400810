.bs-tabs {
  position: relative;

  &:hover {
    .bs-tabs__arrows {
      opacity: 1;
      transition: 0.3s;
    }
  }

  &__list {
    display: flex;
    list-style: none;
    position: relative;
    white-space: nowrap;

    .flickity-viewport {
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &::after {
      background-color: $c-gray-darker;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &__link {
    border: {
      color: $c-gray;
      style: solid;
      top-left-radius: 5px;
      top-right-radius: 5px;
      width: 1px;
      bottom-color: $c-gray-darker;
    }
    color: $c-gray-dark;
    cursor: pointer;
    display: block;
    font: {
      family: $font-title;
      size: rem(16);
      weight: fw("medium");
    }
    margin-right: $grid-gutter;
    padding: $grid-gutter;
    position: relative;
    transition: 0.2s ease-in-out;
    max-width: auto;
    z-index: z("default");

    @include media(">=tablet") {
      font-size: rem(18);
      padding: $grid-gutter (3 * $grid-gutter);
    }

    @include media(">=desktop") {
      padding: $grid-gutter (4 * $grid-gutter);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .bs-is-active,
  .is-nav-selected {
    background-color: $c-white;
    border: 1px solid $c-gray-darker;
    border-bottom-color: $c-white;
    color: $c-gray-darker;
  }

  &__content {
    display: none;
    padding: $grid-gutter 0;
    width: 100%;

    &--active {
      display: block;
    }

    &--no-padding {
      padding: 0;
    }

    @at-root .bs-tabs__panel--slider #{&} {
      display: block;
    }
  }

  &__link-modal {
    color: #4d4e53;
    display: block;
    font-size: 1rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;

    &::before,
    &::after  {
      border-top: 1px solid #d2d4da;
      content: "";
      display: block;
      position: absolute;
      top: 18px;
      width: 1000px;
    }

    &::after {
      left: 60%;
    }

    &:before {
      right: 60%;
    }
  }

  &__panel-items {
    .bs-media__list {
      margin: 0;
    }

    .bs-media__item {
      padding: 15px;
    }

    .flickity-viewport {
      overflow: hidden;
      position: relative;
    }
  }

  &__arrows {
    display: none;
    opacity: 0;
  }

  &__arrow-prev,
  &__arrow-next {
    cursor: pointer;
    display: none;
    font-size: 25px;
    top: 0;
    padding: 8px;
    position: absolute;
    margin: 2px;
    opacity: 0.7;
    z-index: 3;

    @include media(">=tablet") {
      display: block;
    }

    &:hover {
      opacity: 1;
    }

    &[disabled^="disabled"],
    &[disabled^="disabled"]:hover {
      cursor: default;
      opacity: 0.2;
    }
  }

  &__arrow-prev {

    left: -46px;

    @include media(">=tablet") {
      left: -8px;
    }

    @include media(">=1300px") {
      left: -46px;
    }
  }

  &__arrow-next {

    right: -46px;

    @include media(">=tablet") {
      right: -8px;
    }

    @include media(">=1300px") {
      right: -46px;
    }
  }

  // Variações
  // add here your options

  &--remove-tab {

    .bs-tabs__list,
    .bs-tabs__arrows {
      display: none;
    }
  }

  &--over-prev,
  &--over-next {

    &::before,
    &::after {
      content: '';
      height: 55px;
      transition: 0.5s;
      top: 0;
      pointer-events: none;
      position: absolute;
      width: 75px;
      z-index: 2;
    }

    &:hover::before,
    &:hover::after {
      width: 30px;
    }
  }

  &--over-prev {
    &::before {
      background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 50%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */    content: '';
      left: -1px;
    }
  }

  &--over-next {
    &::after {
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,0.7) 50%,rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      right: -1px;
    }
  }
}
