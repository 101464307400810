.bs-card-audio {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 5px 15px 0 rgba($c-black, 0.1);
  padding: 1.5 * $grid-gutter;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 5px 15px 0 rgba($c-black, 0.2);
  }

  .bs-overflow-menu__options {
    position: absolute;
    right: 0;
    bottom: 48px;
    
    @include media(">=tablet") {
      bottom: inherit;
      position: inherit;
    }
  }

  &__content {
    font-size: 14px;
    text-align: center;
    margin-bottom: $grid-gutter;
    
    @include media(">=tablet") {
      display: flex;
      text-align: left;
    }

    &--title {
      font-size: 16px; 
      font-weight: fw("bold");
      margin-bottom: ($grid-gutter / 2);
    }
    
    &--date {
      display: block;
      margin-bottom: $grid-gutter;
      
      @include media(">=tablet") {
        display: flex;
      }
    }

    &--date-record {
      display: block;

      + .bs-card-audio__content--date-post:not(:empty) {
        &::before { 

          @include media(">=tablet") {
            content: '-';
            padding: 0 5px;
          }

        }
      }
    }
    
    &--pre-description {
      font-weight: fw("bold");
    }
    
    &--interviewer {
      display: block;
      
      @include media(">=tablet") {
        display: initial;
        margin-right: ($grid-gutter / 2);
      }
    }
  }

  &__content-picture {
    border-radius: 5px;
    height: 170px;
    overflow: hidden;
    margin: {
      bottom: $grid-gutter;
      left: auto;
      right: auto;
    };
    width: 170px;

    @include media(">=tablet") {
      margin: 0;
    }
  }

  &__content-description {
    text-align: left;
    flex: 1;
    
    @include media(">=tablet") {
      padding-left: 1.5 * $grid-gutter;
    }
  }

  &__player {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__player-options {
    cursor: pointer;
    font-size: 35px;
  }

  &__player-options-list {
    background: $c-white;
    border: 1px solid $c-gray-light;
    bottom: 45px;
    box-shadow: 0 5px 15px 0 rgba($c-black, 0.1);
    font-size: 14px;
    right: 0;
    transition: 0.5s;
    position: absolute;
    width: 180px;
  }

  &__player-options-item {
    border-bottom: 1px solid $c-gray-light;
    
    &:last-child {
      border-bottom: 0;
    }

    a {
      align-items: center;
      display: flex;
      transition: 0.3s;
      padding: ($grid-gutter / 1.2) ($grid-gutter * 1.2);
      width: 100%;
  
      .icon {
        font-size: 24px;
        margin-right: 5px;
      }
  
      &:hover {
        background: $c-gray-light;
      }
    }
  }
} 