//-------------------------------
// Snackbar
// Fornece mensagens breves sobre processos de aplicativos na parte inferior da tela.
//
// @link: https://github.com/material-components/material-components-web/tree/master/packages/mdc-snackbar
//-------------------------------
.bs-snackbar {
  align-items: center;
  background-color: $c-gray-darker;
  bottom: 0;
  box-shadow: 0 3px 5px -1px rgba($c-black, 0.2),
              0 6px 10px 0 rgba($c-black, 0.14),
              0 1px 18px 0 rgba($c-black, 0.12);
  color: $c-white;
  display: flex;
  justify-content: flex-start;
  left: 50%;
  padding-left: 24px;
  padding-right: 24px;
  pointer-events: none;
  position: fixed;
  transform: translate(-50%, 100%);
  transition: transform $transition-time, visibility $transition-time $transition-time;
  visibility: hidden;
  will-change: transform;
  z-index: z("modal");

  @include media("<tablet") {
    left: 0;
    transform: translate(0, 100%);
    width: 100%;
  }

  @include media(">=tablet") {
    border-radius: 2px;
    max-width: 568px;
    min-width: 288px;
  }

  &__text {
    align-items: center;
    display: flex;
    font-size: rem(14);
    height: 48px;
    opacity: 0;
    transition: opacity $transition-time;
  }

  &--align-left {
    @include media(">=tablet") {
      bottom: 24px;
      left: 24px;
      right: auto;
      transform: translate(0, 200%);
    }

    @include media("<tablet") {
      bottom: 0;
      left: 0;
      transform: translate(0, 100%);
      width: 100%;
    }
  }

  &--multiline {
    .bs-snackbar__text {
      height: 80px;
    }
  }

  &--visible {
    pointer-events: auto;
    transform: translate(0);
    transition: transform $transition-time;
    visibility: visible;

    &:not(.bs-snackbar--align-left) {
      transform: translate(-50%, 0);

      @include media("<tablet") {
        bottom: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
      }
    }

    .bs-snackbar__text,
    .bs-snackbar__action-button:not([aria-hidden]) {
      opacity: 1;
      transition: opacity $transition-long-time;
    }
  }

  &--success {
    background-color: $c-green-dark;
  }

  &--warning {
    color: $c-black;
    background-color: $c-yellow;
  }

  &--danger {
    background-color: $c-red;
  }

  &--progress {
    padding: 0;
  }
}
