//
// Colors variables
//
// Lint options:
// sass-lint:disable no-color-hex
//

// Default
$c-white:         #fff !default;
$c-black:         #000 !default;

// Grays
$c-gray-lightest: #f9f9f9 !default;
$c-gray-lighter:  #f4f4f6 !default;
$c-gray-light:    #e8e9ec !default;
$c-gray:          #d2d4da !default;
$c-gray-dark:     #a5aab6 !default;
$c-gray-darker:   #4d4e53 !default;
// $c-gray-darkest:  #000 !default;

// Reds
$c-red-light:     #e60935 !default;
$c-red:           #cc092f !default;
$c-red-dark:      #99000f !default;
$c-red-darker:    #440310 !default;

// Blues
$c-blue-light:    #0e81ed !default;
$c-blue:          #0b67bd !default;
$c-blue-dark:     #2365a7 !default;

// Greens
$c-green:         #0f0    !default;
$c-green-dark:    #28a745 !default;

// Yellows
$c-yellow-light:  #ff0    !default;
$c-yellow:        #ffc107 !default;
$c-yellow-dark:   #ffa901 !default;

// Accessiblity Contrast
$c-contrast-bg: $c-black;
$c-contrast-bg-secondary: $c-yellow-light;
$c-contrast-text: $c-yellow-light;
$c-contrast-border: $c-yellow-light;
$c-contrast-link: $c-yellow-light;
$c-contrast-icon: $c-yellow-light;
