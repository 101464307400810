//-------------------------------
// User Profile [deprecated]
// Campo para exibir/alterar uma foto de perfil
//
// Depreciado porque faz parte de padrão de formulários
// que ainda não foi revisado por UX/UI.
//-------------------------------
.bs-user-profile {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include media(">=tablet") {
    align-items: flex-start;
  }

  @include media(">=wide") {
    flex-direction: row;
  }

  &__photo {
    border: {
      color: $c-white;
      radius: 50%;
      style: solid;
      width:  10px;
    };
    display: inline-block;
    height: 180px;
    width: 180px;
    overflow: hidden;
    position: relative;

    @include media(">=desktop") {
      margin-right: 2 * $grid-gutter;
    }

    img {
      @include object-fit(cover);
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__info {
    padding-top: $grid-gutter;
    text-align: center;

    @include media(">=tablet") {
      text-align: left;
    }
  }

  &__title {
    color: $c-primary;
    font-family: $font-text;
    font-size: rem(22);
    line-height: 1.3;
    margin-bottom: $grid-gutter;
  }

  &__text {
    color: $c-gray-darker;
    font-size: rem(18);
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: $grid-gutter;
    }

    strong {
      font-weight: fw("semibold");
    }
  }
}
