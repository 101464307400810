//-------------------------------
// Button
// Componente pra botões e suas variações
//-------------------------------

/////////////////////
/// Button default rules
///
@mixin buttonDefaults() {
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-family: $font-button;
  font-size: rem(16);
  font-weight: fw("medium");
  line-height: 20px;
  min-height: 40px;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  transition: $ease-in-out;
  vertical-align: middle;
  z-index: 10;

  &:focus {
    outline-offset: -5px;
    outline-style: dotted;
    outline-width: 1px;
    transition: none;
  }
}

/////////////////////
/// Button Colors
/// @param  {[type]} $type  [default|ghost|link]       type of button
/// @param  {[type]} $level [primary|secondary|light]  color level of the button
/// @param  {[type]} $state [active|disabled|loading]  state of the button
/// @param  {[type]} $size  [normal|small]             [deprecated] the size of button
/// @return {[type]}                                   the button colors scheme
///
@mixin button($type: default, $level: primary, $state: active, $size: normal) {
  // Button default style
  $btn-background: $c-gray-light !default;
  $btn-background-hover: darken($btn-background, 8%) !default;
  $btn-background-image: none !default;
  $btn-border-color: transparent !default;
  $btn-border-width: 1px !default;
  $btn-color: $c-black !default;
  $btn-color-hover: $btn-color !default;
  $btn-cursor: pointer;
  $btn-before: block;

  // Primary level button
  @if $level == primary {
    $btn-background: linear-gradient(
      to bottom,
      $c-primary-light 70%,
      $c-primary-dark 140%
    );
    $btn-background-hover: $c-primary-dark;
    $btn-color: $c-white;
    $btn-color-hover: $c-white;
  }

  // Secondary level button
  @if $level == secondary {
    $btn-background: linear-gradient(
      to bottom,
      $c-secondary-light 70%,
      $c-secondary-dark 140%
    );
    $btn-background-hover: $c-secondary-dark;
    $btn-color: $c-white;
    $btn-color-hover: $c-white;
  }

  // Button type ghost
  @if $type == ghost or $type == link {
    $btn-background: transparent;
    $btn-background-image: none;
    $btn-before: none;
  }
  @if $type == ghost {
    $btn-border-color: $c-white;
    $btn-color: $c-white;
    $btn-background-hover: $c-white;
    $btn-color-hover: $c-primary;
  }
  @if $type == ghost and $level == primary {
    $btn-border-color:      $c-primary;
    $btn-color:             $c-primary;
    $btn-background-hover:  $c-primary;
    $btn-color-hover:       $c-white;
  }
  @if $type == ghost and $level == secondary {
    $btn-border-color:      $c-secondary;
    $btn-color:             $c-secondary;
    $btn-background-hover:  $c-secondary;
    $btn-color-hover:       $c-white;
  }

  // Button type link
  @if $type == link {
    $btn-color: $c-primary;
    $btn-color-hover: $c-primary-dark;
  }
  @if $type == link and $level == secondary {
    $btn-color: $c-secondary;
    $btn-color-hover: $c-secondary-dark;
  }

  // Button disabled
  @if $state == disabled {
    $btn-background: $c-gray-lighter;
    $btn-background-hover: $c-gray-lighter;
    $btn-border-color: $c-gray;
    $btn-color: $c-gray-dark;
    $btn-color-hover: $c-gray-dark;
    $btn-cursor: default;
    $btn-before: none;
  }
  @if $state == disabled and $type == ghost {
    $btn-background: transparent;
    $btn-background-hover: transparent;
    $btn-border-color: $c-gray-dark;
  }
  @if $state == disabled and $type == link {
    $btn-background: transparent;
    $btn-background-hover: transparent;
    $btn-border-color: transparent;
  }

  // Button loading
  @if $state == loading {
    $btn-color: transparent;
    $btn-color-hover: transparent;
    $btn-cursor: default;
  }
  @if $state == loading and $type == link {

  }
  @if $state == loading and $type == ghost {
    $btn-background-hover: transparent;
  }

  // Button small (deprecated)
  @if $size == small {}

  // Set style
  background: $btn-background;
  border-color: $btn-border-color;
  color: $btn-color;
  cursor: $btn-cursor;

  // hover effect with transition
  // https://stackoverflow.com/a/6542623
  &::before {
    background: $btn-background-hover;
    border-radius: 5px;
    content: "";
    display: $btn-before;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: $transition-time;
    width: 100%;
    z-index: -10;
  }

  &:hover,
  &:focus {
    color: $btn-color-hover;

    @if $type == ghost {
      background-color: $btn-background-hover;
    }

    &::before {
      opacity: 1;
    }
  }
}

// -----------------
// teste para versão com mixins
// -----------------
.bs-button {
  @include buttonDefaults();
  @include button();

  &--secondary {
    @include button("", "secondary");
  }

  &--ghost {
    @include button("ghost", "light");
  }

  &--ghost#{&}--primary {
    @include button("ghost");
  }

  &--ghost#{&}--secondary {
    @include button("ghost", "secondary");
  }

  &--link {
    @include button("link");
  }

  &--link#{&}--primary {
    @include button("link", "primary");
  }

  &--link#{&}--secondary {
    @include button("link", "secondary");
  }

  &--disable, // Deprecated
  &--is-disabled, // Deprecated
  &:not(#{&}--loading):disabled,
  &--disabled:not(#{&}--loading) {
    @include button($state: "disabled");
  }

  &--ghost:not(#{&}--loading):disabled,
  &--disabled#{&}--ghost:not(#{&}--loading) {
    @include button($type: "ghost", $state: "disabled");
  }

  &--link:not(#{&}--loading):disabled,
  &--disabled#{&}--link:not(#{&}--loading) {
    @include button($type: "link", $state: "disabled");
  }

  &--loading {
    @include button($state: "loading");

    &::after {
      background: transparent url("../img/icons/loading-white.svg") center / 40px no-repeat;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }

    &.bs-button--link::after,
    &.bs-button--ghost.bs-button--primary::after,
    &.bs-button--link.bs-button--primary::after {
      background-image: url("../img/icons/loading-red.svg");
    }

    &.bs-button--ghost.bs-button--secondary::after,
    &.bs-button--link.bs-button--secondary::after {
      background-image: url("../img/icons/loading-blue.svg");
    }
  }

  &--loading#{&}--primary {
    @include button($level: "primary", $state: "loading");
  }

  &--loading#{&}--secondary {
    @include button($level: "secondary", $state: "loading");
  }

  &--loading#{&}--link {
    @include button($type: "link", $state: "loading");
  }

  &--loading#{&}--ghost {
    @include button($type: "ghost", $state: "loading");
  }

  &--loading#{&}--ghost#{&}--primary {
    @include button($type: "ghost", $level: "primary", $state: "loading");
  }

  &--loading#{&}--ghost#{&}--secondary {
    @include button($type: "ghost", $level: "secondary", $state: "loading");
  }

  span,
  i {
    display: inline-block;
    vertical-align: middle;
  }

  i {
    font-size: rem(20);
    font-weight: fw("bold");
  }

  i + span,
  span + i {
    margin-left: 10px;
  }

  // Button Size
  &--small {} // Deprecated

  &--icon-left { // deprecated
    i {
      margin: 0 10px 0 0;
    }
  }

  &--icon-right { // deprecated
    i {
      margin: 0 0 0 10px;
    }
  }
}
// -----------------
// fim do teste para versão com mixins
// -----------------

.bs-old-button {
  background: linear-gradient( to bottom, lighten($c-primary-light, 0%) 70%, $c-primary-dark 140%);
  border: 1px solid transparent;
  border-radius: 3px;
  color: $c-white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-button;
  font-size: rem(16);
  font-weight: fw("medium");
  line-height: 20px;
  padding: 9px 15px;
  position: relative;
  transition: $ease-in-out;
  vertical-align: middle;
  z-index: 10;

  &:hover::before {
    opacity: 1;
  }

  &:focus {
    // outline-color: $c-white;
    outline-offset: -5px;
    outline-style: dotted;
    outline-width: 1px;
    transition: none;
  }

  // hover effect with transition
  // https://stackoverflow.com/a/6542623
  &::before {
    background-color: darken($c-primary, 8%);
    border-radius: 5px;
    color: $c-white;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: $transition-time;
    width: 100%;
    z-index: -10;
  }

  &--secondary {
    background: linear-gradient( to bottom, lighten($c-secondary-light, 0%) 70%, $c-secondary-dark 140%);
    color: $c-white;
    position: relative;
    z-index: 10;

    &::before {
      background: linear-gradient(
        to bottom,
        darken($c-secondary, 5%) 0%,
        darken($c-secondary, 5%) 100%);
      border-radius: 5px;
      color: $c-white;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: $transition-time;
      width: 100%;
      z-index: -10;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  &.bs-is-disabled, // deprecated
  &:not(#{&}--loading):disabled,
  &--disabled {
    background: $c-gray-lighter;
    border: 1px solid $c-gray;
    color: $c-gray-dark;
    cursor: default;

    &::before {
      display: none;
    }

    &:hover {
      background: $c-gray-lighter;
      color: $c-gray-dark;
    }
  }

  &--ghost {
    background-color: transparent;
    background-image: none;
    border: $c-white 1px solid;
    color: $c-white;

    &:hover {
      background-color: $c-white;
      color: $c-primary;
    }

    &.bs-old-button--primary {
      background-color: transparent;
      border-color: $c-primary;
      color: $c-primary;

      &:hover {
        background-color: $c-primary;
        border-color: $c-primary;
        color: $c-white;
      }
    }

    &.bs-old-button--secondary {
      background-color: transparent;
      border-color: $c-secondary;
      color: $c-secondary;

      &:hover {
        background-color: $c-secondary-dark;
        border-color: $c-secondary-dark;
        color: $c-white;
      }
    }

    &:not(#{&}--loading):disabled,
    &:not(#{&}--loading):disabled:hover {
      background: transparent;
      border-color: $c-gray-dark;
      color: $c-gray-dark;
    }

    &.bs-old-button--disable {
      background: $c-gray-lighter;
      border: $c-gray 1px solid;
      color: $c-gray-dark;
      cursor: default;

      &:hover {
        background-color: $c-gray-lighter;
      }
    }

    &::before {
      display: none;
    }
  }

  &--link {
    &,
    &.bs-old-button--primary,
    &.bs-old-button--secondary {
      background: transparent;
      border-color: transparent;
      color: $c-primary;

      &::before {
        display: none;
      }

      &:hover {
        color: $c-primary-dark;
      }
    }

    &.bs-old-button--secondary {
      color: $c-secondary;

      &:hover {
        color: $c-secondary-dark;
      }
    }

    &:not(#{&}--loading):disabled,
    &:not(#{&}--loading):disabled:hover {
      background: transparent;
      border-color: transparent;
      color: $c-gray-dark;
    }
  }

  &--loading {
    color: transparent;
    cursor: default;
    position: relative;

    &:hover {
      color: transparent;
    }

    &::after {
      background: transparent url("../img/icons/loading-white.svg") center / 40px no-repeat;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }

    &.bs-old-button--primary:hover {
      background-color: $c-primary;
    }

    &.bs-old-button--secondary:hover {
      background-color: $c-secondary;
    }

    &.bs-old-button--link,
    &.bs-old-button--ghost {
      color: transparent;
    }

    &.bs-old-button--link:hover,
    &.bs-old-button--ghost:hover {
      background-color: transparent;
      color: transparent;
    }

    &.bs-old-button--link::after,
    &.bs-old-button--ghost.bs-old-button--primary::after,
    &.bs-old-button--link.bs-old-button--primary::after {
      background-image: url("../img/icons/loading-red.svg");
    }

    &.bs-old-button--ghost.bs-old-button--secondary::after,
    &.bs-old-button--link.bs-old-button--secondary::after {
      background-image: url("../img/icons/loading-blue.svg");
    }
  }

  // Deprecated
  &--small {
    font: {
      size: rem(13);
      weight: fw("regular");
    }
    padding: 10px;
  }

  // Para usar com ícones SVG (symbol/use)
  .bs-icon {
    fill: none;
    height: 14px;
    stroke: $c-white;
    stroke-width: 10px;
    vertical-align: middle;
  }

  span,
  strong, // não usar strong dentro do botão
  i {
    display: inline-block;
    vertical-align: middle;
  }

  i {
    font-size: rem(20);
    font-weight: fw("bold");
    // margin-right: 10px;
  }

  i + span,
  span + i {
    margin-left: 10px;
  }

  &--icon-left { // deprecated
    i {
      margin: 0 10px 0 0;
    }
  }

  &--icon-right { // deprecated
    i {
      margin: 0 0 0 10px;
    }
  }
}

// Botão estilo link
.bs-button-link {
  background-color: transparent;
  border: 0;
  color: $c-primary;
  font: {
    family: $font-title;
    size: 1rem;
    weight: fw("bold");
  }
  white-space: nowrap;

  &:focus,
  &:hover {
    background-color: transparent;

    i {
      margin-left: 10px;
    }
  }

  &:focus {
    text-decoration: underline;
  }

  i {
    display: inline-block;
    font-size: rem(13);
    margin-left: 5px;
    transition: $transition-time;
    vertical-align: middle;

    &:first-child:not(:only-child) {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .bs-icon {
    fill: none;
    height: 14px;
    stroke: $c-white;
    stroke-width: 10px;
    vertical-align: middle;
  }

  &--primary {
    color: $c-primary;
  }

  &--secondary {
    color: $c-secondary;
  }
}

.bs-button-group {
  display: flex;
  flex-wrap: wrap;

  .bs-media__button,
  .bs-old-button,
  .bs-button,
  .bs-button-link {
    margin-bottom: $grid-gutter;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--side {
    .bs-media__button,
    .bs-old-button,
    .bs-button,
    .bs-button-link {
      margin-bottom: 0;
    }
  }
}

.bs-switch {
  $i: &;

  &__label {
    align-items: center;
    display:flex;
    height: 100%;

    label {

      span {
        border: 1px solid $c-gray;
        border-radius: 3px;
        color: $c-gray-dark;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        padding: 8px 12px;
        position: relative;
        transition: .6s;
        z-index: 0;

        &::before {
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transition: .3s;
          visibility: hidden;
          width: 100%;
          z-index: -1;
        }
      }


      input {
        display: none;

        &:checked + span {
          color: $c-white;

          &::before {
            background-color: $c-gray-dark;
            border-color: $c-gray-dark;
            transform: translateX(0);
            visibility: visible;
          }
        }
      }

      &:first-child {

        span {
          border-radius: 3px 0 0 3px;
          border-right: 0;

          &::before {
            transform: translateX(100%);
          }
        }
      }

      &:last-child {

        span {
          border-left: 0;
          border-radius: 0 3px 3px 0;

          &::before {
            transform: translateX(-100%);
          }
        }
      }
    }

    #{$i}__box {
      background-color: $c-gray-lighter;
      display: flex;
      overflow: hidden;
    }

    &#{$i}--primary {
      background: transparent;

      label {
        span {
          &:hover {
            border-color: $c-primary-light;
          }
        }

        input {
          &:checked + span {
            border-color: $c-primary-light;

            &::before {
              background-color: $c-primary-light;
            }
          }
        }
      }

      &::before {
        display: none;
      }
    }

    &#{$i}--secondary {
      background: transparent;

      label {
        span {
          &:hover {
            border-color: $c-secondary-light;
          }
        }

        input {
          &:checked + span {
            border-color: $c-secondary-light;

            &::before {
              background-color: $c-secondary-light;
            }
          }
        }
      }

      &::before {
        display: none;
      }
    }
  }

  &__lever {

    label {
      input[type="checkbox"] {
        height: 0;
        opacity: 0;
        width: 0;

        &:checked+span {

          &::before,
          &::after {
            left: 18px;
          }
        }
      }

      span {
        background-color: $c-gray;
        border-radius: 15px;
        cursor: pointer;
        display: inline-block;
        height: 14px;
        margin: 0 16px;
        margin-right: 10px;
        position: relative;
        transition: background 0.3s ease;
        vertical-align: middle;
        width: 36px;

        &::before,
        &::after {
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 20px;
          left: 0;
          position: absolute;
          top: -3px;
          transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, transform .1s ease;
          width: 20px;
        }

        &::after {
          background-color: $c-white;
          box-shadow: $box-shadow-fixed;
        }
      }
    }

    &#{$i}--primary {
      label {
        input[type="checkbox"] {
          &:checked+span {
            background-color: $c-primary;
          }
        }
      }
    }

    &#{$i}--secondary {
      label {
        input[type="checkbox"] {
          &:checked+span {
            background-color: $c-secondary;
          }
        }
      }
    }
  }
}
